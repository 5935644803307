import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import "./tos.css";
import Utils from "../infrastructure/Utils";
import ReactMarkdown from "react-markdown";
import UserContext from "../infrastructure/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import rehypeRaw from "rehype-raw";

let disagree: boolean | undefined = undefined;
export default function TermsofService() {
  let userId = UserContext.userId;
  const [showTos, setShowTos] = useState<boolean>(false);
  const [enableAgree, setEnableAgree] = useState<boolean>(false);
  const [tosAgreement, setTosAgreement] = useState("");
  const [acceptedTos, setAcceptedTos] = useState<boolean>(true);
  const [tosId, setTosId] = useState("");

  useEffect(() => {
    getTOSAgreed();
  }, []);

  const getTOSAgreed = async (): Promise<any> => {
    const url = Utils.getServiceUrlByEnvironment();
    let authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    return axios
      .get(
        `${url}/api/termsofservice/v1/termsofserviceagreement/accepted?userId=` +
          userId,
        {
          headers: { HOSTNAME: host, authorization: authKey },
        }
      )
      .then((response) => {
        setTosId(response.data.tosId); 
        if (response.data.isAccepted === true) {
          setShowTos(false);
        } else { 
          setShowTos(true);
          getTOS();
        }
      })
      .catch(console.error);
  };

  const getTOS = async (): Promise<any> => {
    const url = Utils.getServiceUrlByEnvironment();
    let authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    return axios
      .get(`${url}/api/termsofservice/v1/termsofserviceagreement`, {
        headers: {
          HOSTNAME: host,
          authorization: authKey,
        },
      })
      .then((response) => {
        setTosAgreement(response.data.termsofServiceAgreement);
        setTosId(response.data.tosId);
      })
      .catch(console.error);
  };

  const handleOnAgree = () => {
    setAcceptedTos(true);
    disagree = false;
    sendTOS(true);
    onCloseDialog();
  };

  const { logout: logoutUser } = useAuth0();
  const DisAgree = () => {
    disagree = true;
    setAcceptedTos(false);
    sendTOS(false);
    setTimeout(() => {
      onCloseDialog();
    }, 1000);
  };

  const onCloseDialog = () => {
    setShowTos(false);
    if (disagree === true || disagree === undefined) {
      localStorage.clear();
      logoutUser({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  const get_browser = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bEdg\/(\d+)/);
      if (tem != null) {
        return { name: "Edge(Chromium)", version: tem[1] };
      }
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  };

  const sendTOS = async (isAccepted: boolean): Promise<any> => {
    const url = Utils.getServiceUrlByEnvironment();
    let authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    var browser = get_browser();
    const res = await axios.get("https://geolocation-db.com/json/");
    const data = {
      isAccepted: isAccepted,
      tosId: tosId,
      userId: userId,
      browserName: browser.name,
      browserVersion: browser.version,
      iPAddress: res && res.data ? res.data.IPv4 : "",
    };
    return axios
      .post(
        ` ${url}/api/termsofservice/v1/termsofserviceagreement/createtermsofserviceevent`,
        data,
        {
          headers: {
            HOSTNAME: host,
            authorization: authKey,
          },
        }
      )
      .catch(console.error);
  };
  const handleScroll = (e: any) => {
    const distance = e.target.scrollHeight - e.target.scrollTop;
    const bottom =
      distance >= e.target.clientHeight &&
      distance <= e.target.clientHeight + 100;
    if (bottom) {
      console.log("bottom is reached");
      setEnableAgree(true);
    }
  };

  const tosCheck = () => {
    let given = null;
    const getDate = localStorage.getItem("test");

    if (!moment(getDate).isValid() || !getDate) {
      getTOSAgreed();
    } else {
      given = moment(new Date(getDate), "YYYY-MM-DD").set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      console.log(given);
      const current = moment().startOf("day");
      const result = moment.duration(current.diff(given)).asDays();
      console.log(result);
      if (result >= 1) {
        getTOSAgreed();
      }
    }
  };

  return (
    <div className="tos_container">
      <div>
        <Dialog
          header="Integrity Connect Terms of Use"
          visible={showTos}
          style={{ width: "644px",height:"400px", fontWeight: "400" }}
          onHide={onCloseDialog}
          className="tos_title"
          blockScroll={true}
          closeOnEscape={false}
          contentStyle={{ padding: "18px" }}
        >
          <div className="tos_content" onScroll={handleScroll}>
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className="tos_agreement_text"
            >
              {tosAgreement}
            </ReactMarkdown>
          </div>
          <div className="tos_footer">
            <div className="buttons_div">
              <div>
                <Button
                  label="I DISAGREE"
                  //className=" tos_close p-button-secondary"
                  className=" tos_close secondary-button"
                  onClick={DisAgree}
                />
              </div>
              <div>
                <Button
                  className="tos_agree primary-button"
                  label=" I AGREE"
                  severity="info"
                  disabled={!enableAgree}
                  onClick={handleOnAgree}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
