import { action, computed, observable, toJS } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import Collection from "../../../../infrastructure/CollectionHelper";
import UserContext from "../../../../infrastructure/UserContext";
import IMASLog from "../../../../infrastructure/IMASLog";
import PageContext from "../../../../infrastructure/PageContext";
import DocumentStore from "../../../../store/DocumentStore";
import { ReportFile } from "../../../../services/DocumentService";

export class PagedList<T> {
    public recordCount: number = 0;
    public data: T[] = [];
}
export class CarrierCMDocumentPacketViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    Route = async (currentRoute: RouteList): Promise<void> => { };
    @observable cmSearchInputValue: string = "";
    @observable rowId: number = 0;
    @observable response: any = "";
    @observable isMessgeVisible: boolean = false;

    @observable selectedRowData: any;
    @observable firstItem: number = 0;
    @observable currentPage: number = 0;
    @observable pageSize: number = 50;
    @observable pageIndex: number = 0;

    @observable sortColumn: string = "id";
    @observable isSortAscending: boolean = true;
    @observable defaultSortColumn: string | undefined;
    @observable defaultIsSortAscending: boolean = true;
    @observable startIndex: number = 0;
    @observable totalRecords: number = 0;
    @observable first: number = 0;
    @observable rows: number = 50;
    @observable sort: boolean = false;

    @observable isLoading: boolean = false;
    @observable isException: boolean = false;
    @observable disableSave: boolean = true;
    @observable isPacketDialogVisible: boolean = false;
    @observable selectedRowId: number = 0;

    @observable title: string = "";
    @observable carrierID: number = 0;
    @observable isRefreshEnable: boolean = false;
    @observable packetName: string = "";
    @observable selectedPacketId: number = 0;
    @action setSelectedDocuments(value: any) {
        this.selectedDocuments = value;
        if (!this.selectedDocuments || this.selectedDocuments.length <= 0) {
            this.disableSave = true;
        }
        else {
            this.disableSave = false;
        }
    }
    @action downloadPacketDocument = async (
        value: number,
    ) => {
        this.selectedPacketId = value;
        await this.downloadDocuments(false);
    };
    @observable selectedDocuments: any;
    @observable selectedCategory: number = 0;
    @observable categoryList = new Collection<any>();
    @observable packetsList = new Collection<any>();
    @observable packetDocumentsList = new Collection<any>();

    @action cancel = () => {
        this.setAddDocumentDialogVisibility(false);
    };

    @action setAddDocumentDialogVisibility = async (
        value: boolean,
    ) => {
        this.setPacketDialogVisible(value);
        this.isPacketDialogVisible = value;
    };   

    @action loadCategoryLookupList = async () => {
        this.isLoading = true;
        this.isMessgeVisible = false;
        this.response = {};
        var defaultItem = { label: "All Categories", value: "0" };
        try {
            var result = await DocumentStore.getAllPacketLookup();
            if (result != null) {
                this.categoryList.values = [];
                // category
                if (result.documentTypeGroups != null) {
                    result.documentTypeGroups.forEach((obj: any) => {
                        var documentData = { label: obj.text ? obj.text : "", value: obj.value ? obj.value : "" };
                        this.categoryList.values.push(documentData);
                    });
                }
            }
        } catch (e) {
            this.categoryList.values.splice(0, 0, defaultItem);
            this.isMessgeVisible = true;
            this.response = e.response;
        }
        this.categoryList.values.splice(0, 0, defaultItem);
        this.isLoading = false;
    }

    @action redirectDocumnets = () => {
        routes.adminDocuments.push();
    };
    @action refresh = () => {
        this.cmSearchInputValue = "";
        this.selectedCategory = 0;
        this.isRefreshEnable = false;
        this.loadPackets();
    };

    @action Load = async (id: number) => {
        this.firstItem = 0;
        this.currentPage = 0;
        this.pageSize = 50;
        this.pageIndex = 0;
        this.carrierID = id;
        this.cmSearchInputValue = "";
        this.selectedCategory = 0;
        this.isRefreshEnable = false;
        this.defaultSortColumn = undefined;
        this.sortColumn = "Id";
        this.isSortAscending = true;
        this.defaultIsSortAscending = true;
        this.selectedPacketId = 0;
        this.selectedDocuments = [];
        this.loadPackets();
        this.loadCategoryLookupList();
    };

    @action loadPage(pageIndex: number, pageSize: number) {
        this.currentPage = pageIndex;
        this.pageSize = pageSize;
        this.firstItem = pageIndex * pageSize;
        this.loadPackets();
    }
    @action loadPackets = async () => {
        try {
            this.isLoading = true;
            const packetResponse = await DocumentStore.getAllPacketSearchByCompany(this.selectedCategory, this.carrierID, this.pageIndex, this.pageSize, this.sortColumn, this.sort, this.cmSearchInputValue);
            if (packetResponse && packetResponse.data && packetResponse.data.length > 0) {
                this.packetsList.values = [];
                this.totalRecords = packetResponse.recordCount
                    ? packetResponse.recordCount
                    : 0;
                this.packetsList.values = packetResponse.data;
            }
            else {
                this.totalRecords = 0;
                this.packetsList.values = [];
                this.selectedRowData = [];
            }
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
        }
    }
    @action setSelectedCategory(categoryId: number) {
        this.selectedCategory = categoryId;
        this.isRefreshEnable = true;
        if (this.cmSearchInputValue.length === 0 && this.selectedCategory == 0) this.isRefreshEnable = false;
        this.loadPackets();
    }

    @action selectedRow = async (data: any) => {
        this.selectedRowId = data.id;
    };

    @action setSortOrder() {
        this.sort = !this.sort;
        this.startIndex = 0;
        this.first = 0;
    }

    @action setSortColumn(column: string) {
        this.sortColumn = column;
    }
    onPage = (pageInfo: any) => {
        this.firstItem = pageInfo.first;
        this.pageSize = pageInfo.rows;
        this.pageIndex = pageInfo.first / pageInfo.rows;
        this.loadPage(pageInfo.page, pageInfo.rows);
    };

    @action viewPacketDocument = async (packetId: number, packetName: any) => {        
        this.selectedDocuments = [];
        this.selectedPacketId = packetId;
        this.packetName = packetName + " (Files)";
        try {
            this.isLoading = true;
            var packetDocumentResponse = await DocumentStore.getDocumentByCompanyPacketIds(packetId);
            this.packetDocumentsList.values = [];
            if (packetDocumentResponse && packetDocumentResponse.data) {
                this.packetDocumentsList.values = packetDocumentResponse.data;
            }
            this.isLoading = false;
            this.setPacketDialogVisible(true);
        } catch (e) {
            let response = {
                status: 500,
                title: e.message,
                errors: { "": [] },
            };
            this.isLoading = false;
        }
    }
    @action setPacketDialogVisible = async (value: boolean) => {
        this.isPacketDialogVisible = value;
        if (!this.isPacketDialogVisible) {
            this.selectedPacketId = 0;
            this.selectedDocuments = [];
        }
        this.disableSave = true;
    };
    @action viewDocument = async (docId: number) => {
        this.isLoading = true;
        this.response = {};
        try {
            this.isMessgeVisible = false;
            window.open(window.location.origin + "/documentviewer/" + docId + "/" + 0, '_new');
        } catch (e) {
            var responseModels = {
                status: 400,
                title: "",
                errors: { "ValidationError": ["Document with Id " + docId + " not found"] },
            };
        }
    }
    @action setFirstPage = () => {
        this.firstItem = 0;
        this.currentPage = 0;
    };

    @action resetPaging = () => {
        this.firstItem = 0;
        this.currentPage = 0;
        this.isSortAscending = this.defaultIsSortAscending;
    };
    @action downloadDocuments = async (IsDocument : boolean) => {
        if (this.selectedPacketId > 0) {
            var items = new Array<number>();
            this.selectedDocuments.forEach((element: any) => {
                items.push(element.id);
            });
            var packetDocumentResponse = await DocumentStore.downloadDocumentByPacketAsZip(this.selectedPacketId, IsDocument ? items : null);           
            await this.downloadReport(packetDocumentResponse, "PacketDocument");            
        }
    };

    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };
    @action onFieldChange = (e: any) => {
        e.persist();
        this.cmSearchInputValue = e.currentTarget.value;

        this.isRefreshEnable = true;
        if (this.cmSearchInputValue.length === 0 && this.selectedCategory == 0) this.isRefreshEnable = false;

        if (
            this.cmSearchInputValue.length >= 3 ||
            this.cmSearchInputValue.length === 0
        ) {
            this.loadPackets();
        }
    };

    readonly currentPageReportTemplate: string =
        "Showing {first} - {last} of {totalRecords} records";
    paginatorTemplate(): string {
        return this.packetsList.values.length > 0
            ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            : "";
    }

    rowsPerPageOptions(): number[] {
        return this.packetsList.values.length > 0 ? [50, 100, 150, 200] : [];
    }
}

