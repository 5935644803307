export const Constants = {
  Empty: " ",
  Required: "This field is required",
  Success: "Record Saved Successfully",
  UpdateSuccess: "Record Updated Successfully",
  UpdateRecordsSuccess: "Records Updated Successfully",
  NoUpdatedRecords: "Please modify the records to update",
  NotificationSuccess: "Notification Sent Successfully",
  NotificationError: "Connection Id is not found in the list for current user",
  ContactAdded: "Contact Added",
  EmailAdded: "Email Added",
  Added: "Added Successfully.",
  Error: "An exception occurred while processing the request.",
  AgentNoteWarning:
    "The Agency users have been enabled to access Connect hence, the note is recommended to be private",
  AgentNoteUplineError:
    "Please select the IMMEDIATE UPLINE agent for this contract's Hierarchy",
  NoRecordFounds: "No records available to display",
  NoTripPoints: "Agent currently has no points.",
  DoesNotQualify: "Agent cannot qualify for points!",
  NoCreditCardCharge: "No Credit Card charges for this agent.",
  StartDateEndDateValidation: "Start date should not be greater than end date.",
  NoDebts: "No Debts for this agent or Unauthorized to see debts.",
  NoWorkflowDocsForAgent:
    "Agent does not have any documents currently going through Workflow.",
  SearchWorkflowDocs: "There are NO documents in this queue.",
  SearchDocsInWorkflow: "No Records Found",
  EditDebt: "You do not have permission to edit",
  genericView: "You do not have permission",
  NoHistoryAvailable: "No history available",
  DeleteDocument: "Are you sure you want to delete this document?",
  DocumentWorkFlowTitle:
    "Notes from when this document was being processed in Workflow -",
  NoMethodImplementation: "Method not implemented.",
  NoFile: "Please select a file.",
  LongFileName: "File name must be less than 200 characters.",
  AlreadyExistInWorkFLow:
    "You can't submit a file into Workflow if it's already in Workflow.",
  NoFaxEmailSelection: "Please select an option from fax/emails",
  LineAlreadyReferred: "Product line is already referred",
  LineAlreadyActive:
    " You have started a lead for a product line which is already active for this agent.The lead will remain active; however, this will not count as an activation within your incentive goals.",
  EmptyRecord: "No Record Found",
  PolicyMoved: "Policy moved successfully",
  EnterPolicyId: "Please Enter Policy ID",
  SomethingWentWrong: "Something went wrong",
  NoHistory: "No history available",
  PDFException: "PDF File for this document not found!",
  PleaseSelectProduct: "Please Select Product.",
  UploadFileSuccess: "File uploaded successfully",
  UploadTo: "If UPLOAD is chosen, a company must be selected.",
  FaxTo: " If FAX is chosen, a fax number must be selected.",
  LicensingErr:
    "Contract Communications have not been set for this agent's licensing",
  MergeSuccess: "Records Merged Successfully",
  MergeFailed: "Records Merge Failed",
  CssPath: "/assets/layout/css/",
  WorkflowDocumentDelete: "Document Deleted!",
  AddWorkflowDocumentComment: "Note added successfully!",
  UserDownlineAgencyRole: "Downline Agencies",
  DeleteSuccess: "Record Deleted Successfully",
  DeletePolicy: "Are you sure you want to delete this policy?",
  NoLogsAvailable: "No notes available",
  EnterPacketName: "Please Enter Packet Name",
  AgentPortalEnable: "Agent portal login enabled.",
  AgentPortalDisable: "Agent portal login disabled.",
  ReceivePaperStatementEnable: "Receive paper statement enabled.",
  ReceivePaperStatementDisable: "Receive paper statement disabled.",
  EmailSuccess: "Mail sent successfully.",
  dobValidation: "Please select valid DOB",
  workflowDeleteDocument: 307,
  ReportsTimeoutIssue: "Please filter results. Data is too large to generate.",
  CustomAttributeValidationMessage:
    "Please fill all required fields for custom attributes",
  ReportsDataTooLarge: "Please choose filters, data is too large.",
  BadGatewayError: "Bad Gateway - Timeout exception",
  DefaultProfilePic: "assets/layout/images/profile-default.png",
  CustomerSearchInfo:
    "Your search returned more than 200 insured. Only the first 200 are displayed.",
  AgentSearchInfo:
    "Your search returned more than 200 agents. Only the first 200 are displayed.",
  SelectDocument: "Please select one or more documents",
  NoTasksForToday: "No tasks for today!",
  DeleteRoles:
    "There are users associated with this role. Are you sure you want to delete this role?",
  DeleteRole: "Are you sure you want to delete this role?",
  DeleteTeam: "Are you sure you want to delete this Team?",
  DeleteBackOut: "Are you sure you want to backout?",
  renewalBackout: "Please select revenue backout",
  backoutConfirm: "Are you sure you want to Backout selected and Commission?",
  autoDialerCreateLead: 213,
  ScheduledSuccess:
    "Build All Scheduled. If you want to schedule another one, please refresh the page",
  copyCommissionPlan: "Copy Commission Plan",
  copyCommissionPlanText:
    "Are you sure you want to copy this Commission Plan and related info?",
  currentAgentId: "currentAgentId",
  docWorkflow: "/docWorkflow",
  futureDateValid:
    "Date sold cannot be a future date, Please enter a valid Date.",
  effectiveDateInvalid:
    "Invalid Effective Date: Date can’t be older than 25 years and can’t be over a year in the future.",
  add: "add",
  edit: "edit",
  deleteSenderSubmissionType:
    "Are you sure you want to delete this record? THIS WILL DELETE RELATED SUBMISSION METHODS CONNECTORS",
  deleteSubmissionType: "Are you sure you want to delete this submission type?",
  deleteRequirementType:
    "Are you sure you want to delete this requirement submission method?",
  deleteAutoUnchecks:
    "Are you sure you want to delete this AutoUncheck submission method?",
  PrincipalAgents: "Principal Agents",
  Agencies: "Agencies",
  NoPendingNotifications: "There are no pending notifications.",
  appsettingSuccessMessage: "Changes made successfully",
  OptionalField: "Additional fields",
  CommissionReceivedEmptyMsg:
    "There are no commission received for this policy.",
  ReportMessage:
    "Report generation is in progress, You will receive an email shortly.",
  policyEffectiveDateValidationMessage:
    "Invalid Effective Date: Date can’t be older than 25 years and can’t be over a year in the future",
  policyDateSoldValidationMessage:
    "Date sold cannot be a future date, Please enter a valid Date",
  DefautExpirationDate: "12/31/9999",
};

export const PageTitleConstants = {
  AgentSearch: "Agent Search",
  AddAgent: "Add Agent",
  bookOfBusiness: "Book of Business",
  universalUpload: "Universal Upload",
  rtsReview: "RTS Review",
  contractManagement: "Contract Management",
  EditAgent: "Edit Agent",
  AgentDetails: "Agent Details",
  Insured: "Insured",
  MyTasks: "My Tasks",
  DocumentManagement: "Document Management",
  Reports: "Report Viewer",
  Workflow: "Document Workflow",
  Goals: "Goals",
  SupplyOrders: "Supply Orders",
  Leads: "Marketer Leads",
  Administration: "Site Administration",
  AutoDialer: "Dialer",
  AgentMapSearch: "Agent Map Search",
  Emails: "Emails",
  Tasks: "Tasks",
  AgentGroupDetails: "Agent Group Details",
  carrierManagement: "Carrier Management",
  NeedHelp: "Need Help",
  TermsofUse: "Terms of Use",
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const salesTypes = {
  fieldAgent: "1",
  teleSales: "2",
};
export const AutoDialerConstants = {
  Prospective: "Create New List from Prospective Agents",
  Active: "Create New List from Active Agents",
  Existing: "Call from Existing List",
  CreateCustom: "Create Custom Call List",
};

export const AutoDialerActionTypes = {
  PriviewResult: "Priview Result",
  GenerateCallList: "Generate Call List",
  GenerateIContactList: "Generate IContact List",
  AddZipCode: "Add Zip Code",
};

export const ResidentStateTypeContants = {
  State: "State",
  County: "County",
  ZipCode: "Zip Code",
};

export const homeTopKpiTitles = {
  ContractsInWorkflow: "Contracts in Workflow",
  AppsInWorkflow: "Apps in Workflow",
  LivesImpacted: "Lives Impacted",
  ContractedAgents: "Contracted Agents",
  topKpiOne: "topKpiOne",
  topKpiTwo: "topKpiTwo",
  topKpiThree: "topKpiThree",
  topKpiFour: "topKpiFour",
};

export const homeMidKpiTitles = {
  AppCountsByMonthYear: "App Counts by Month Year Over Year",
  MyTasks: "Tasks for the day",
  MarketingEvents: "Marketing Events",
  Announcements: "Announcements",
  midKpiOne: "midKpiOne",
  midKpiTwo: "midKpiTwo",
  midKpiThree: "midKpiThree",
  midKpiFour: "midKpiFour",
};

export const homeBottomKpTitles = {
  Carriers: "Top 5 Carriers",
  Agents: "Top 5 Agents",
  ProductTypes: "Top 5 Product Types",
  bottomKpiOne: "bottomKpiOne",
  bottomKpiTwo: "bottomKpiTwo",
  bottomKpiThree: "bottomKpiThree",
};

export const homeKpis = {
  homeTopKpis: "homeTopKpis",
  homeMidKpis: "homeMidKpis",
  homeBottomKpis: "homeBottomKpis",
};

export const reportNamesConstants = {
  AgentAgencyDetails: "Agent / Agency Details",
  CommissionBreakDown: "Commission Break-Down",
  ContractedAgentList: "Contracted Agent List",
  ContractDetails: "Contract Details",
  ContractsByMarketer: "Contracts By Marketer",
  AgentAgencyContactandDemographics: "Agent / Agency Contact and Demographics",
  MarketerProduction: "Marketer Production",
  PolicyInfo: "Policy Info",
  PolicySummary: "Policy Summary",
  Production: "Production",
  ProductionSummary: "Production Summary",
  Report: "Report",
};
export const ViewFiles: string[] = ["pdf", "png", "jpg", "jpeg"];

export const dataTableConstants = {
  paginatorTemplate:
    "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
  currentPageReportTemplate:
    "Showing {first} - {last} of {totalRecords} records",
  paginatorRight: "Records per Page",
};

export const agGridLicense =
  "Using_this_AG_Grid_Enterprise_key_( AG-051258 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Integrity )_is_granted_a_( Single Application )_Developer_License_for_the_application_( IntegrityAgency )_only_for_( 6 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( IntegrityAgency )_need_to_be_licensed___( IntegrityAgency )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23_December_2024 )____[v2]_MTczNDkxMjAwMDAwMA==64ab4a5f0100d95e983dce0d2c8abeab";
