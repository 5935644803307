import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import { NoteModel } from "../../../../services/ContractService";
import Collection from "../../../../infrastructure/CollectionHelper";
import ProductStore from "../../../../store/ProductStore";
import { routes } from "../../../../router";
import { ProductModel } from "../../../../services/ProductService";
import { AnyAaaaRecord, AnyCnameRecord } from "dns";
import history from "../../../../infrastructure/History";

export class PagedList<T> {
  public recordCount: number = 0;
  public data: T[] = [];
}
export class CarrierCMProductsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable itemList = new Collection<NoteModel>();
  @observable cmSearchInputValue: string = "";
  @observable rowId: number = 0;

  @observable selectedRowData: any;
  @observable firstItem: number = 0;
  @observable currentPage: number = 0;
  @observable pageSize: number = 100;
  @observable pageIndex: number = 0;
  @observable selectedStatus: string = "0";
  @observable statusTypeList = [
    { label: "All", value: "0" },
    { label: "Hidden", value: "1" },
    { label: "Not Hidden", value: "2" },
  ];

  @observable sortColumn: string = "id";
  @observable isSortAscending: boolean = true;
  @observable defaultSortColumn: string | undefined;
  @observable defaultIsSortAscending: boolean = true;
  @observable startIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable rows: number = 100;
  @observable carrierId: number = 0;

  @observable isLoading: boolean = false;
  @observable isException: boolean = false;

  @observable sort: boolean = false;

  @observable isCmWebsitesAdd: boolean = true;
  @observable isCmWebsitesSave: boolean = false;
  @observable expandedProductDetails: any = [];
  @observable selectedProduct: any;

  @action Load = async (id: number) => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.pageSize = 100;
    this.pageIndex = 0;
    this.sortColumn = "id";
    this.isSortAscending = true;
    this.defaultSortColumn = undefined;
    this.defaultIsSortAscending = true;
    this.carrierId = id;
    this.cmSearchInputValue = "";
    this.selectedStatus = "0";
    this.expandedProductDetails = [];
    this.selectedProduct = undefined;
    this.loadProducts();
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;
    this.loadProducts();
  }
  @action refresh = async () => {
    this.cmSearchInputValue = "";
    this.selectedStatus = "0";
    this.loadProducts();
  };

  @action loadProducts = async () => {
    try {
      this.isLoading = true;
      this.itemList.values = [];      

      const response = await ProductStore.getAllProducts(
        this.carrierId + "",
        this.selectedStatus=="0"?null:this.selectedStatus,
        null,        
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sort,
        "" + this.cmSearchInputValue
      );
      if (response && response.data && response.data.length > 0) {
        this.totalRecords = response.recordCount ? response.recordCount : 0;
        this.itemList.values = response.data;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action getProductById = async (products: any) => {
    try {
      this.isLoading = true;
      this.expandedProductDetails = [];
      const productId =
        products && products.length > 0 ? products[products.length - 1].id : 0;
      if (productId && productId > 0) {
        const response = await ProductStore.getProductById(productId);

        if (response) {
          this.selectedProduct = response;
          this.expandedProductDetails.push(
            products && products.length > 0 ? products[products.length - 1] : []
          );
          this.expandedProductDetails = [products[products.length - 1]];
        }
      } else {
        this.expandedProductDetails = [];
        this.selectedProduct = undefined;
      }
      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
    }
  };

  @action setStatusDropdown = (value: string) => {   
    this.selectedStatus = value;
    this.loadProducts();
  };

  @action redirectProduct = () => {
    routes.listProduct.push(); 
    // history.push({
    //   pathname : '/administration/general/products/list',
    //   state :{
    //   role_id : "responseJson.userFormat",
    //   userName : "this.userName",
    //   userid: "this.refs.usrname.value"
    //   }
    //   } 
    // );
  };

  @action redirectSubcategories = () => {
    routes.listProductSubCategory.push();
  };

  @action editProduct = (id: number) => {
    localStorage.setItem(
      "ProductRedirectionCarrierId",
      this.carrierId.toString()
    );
    routes.editProduct.push({ Id: id });
  };
  @action editSubCategory = (id: number) => {
    localStorage.setItem(
      "ProductRedirectionCarrierId",
      this.carrierId.toString()
    );
    routes.editProductSubCategory.push({ Id: id });
  };

  @action setSortOrder() {
    this.sort = !this.sort;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.isSortAscending = this.defaultIsSortAscending;
  };

  @action onFieldChange = (e: any) => {
    e.persist();
    this.cmSearchInputValue = e.currentTarget.value;
    if (
      this.cmSearchInputValue.length >= 3 ||
      this.cmSearchInputValue.length === 0
    ) {
      this.loadProducts();
    }
  };

  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.itemList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }

  rowsPerPageOptions(): number[] {
    return this.itemList.values.length > 0 ? [100, 150, 200, 250] : [];
  }
}
