import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import UserContext from "../infrastructure/UserContext";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  DeductibleModel,
  ProductLineModel2,
  ProductModel,
  ProductSubCategoryModel,
  Client as ProductServiceClient,
  GetSubCategoryDetailsResponse,
} from "../services/ProductService";

class ProductStore {
  getApiVersion = () => {
    return "1";
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): ProductServiceClient => {
    return ServiceClient.getClient(ServiceType.Product);
  };
  @action getProductInfo = async (
    agentId: number,
    companyId: number,
    status: string | undefined,
    licenseNumberId: number,
    licenseNumber: string,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    var result = this.getClient().getProductInfoByAgent(
      this.getApiVersion(),
      agentId,
      companyId,
      status,
      licenseNumberId,
      licenseNumber,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getReportProductList = async (companyId: number) => {
    var result = await this.getClient().getReportProductByCompany(
      companyId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action
  public getProductLookup = async (companyId: number) => {
    var result = await this.getClient().getProductLookupByCompany(
      this.getApiVersion(),
      companyId,
      0,
      100,
      undefined,
      undefined,
      undefined,
      UserContext.userId
    );
    return result;
  };

  @action getAgentProductLines = async (agentId: number) => {
    var result = await this.getClient().getAgentProductLine(
      agentId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getAgentProductLineByProduct = async (
    agentId: number,
    productLineId: number
  ) => {
    var result = await this.getClient()._GetAgentProductLineByProduct(
      agentId,
      productLineId,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getProductCategoryLookup = async () => {
    var result = await this.getClient().getProductCategoryLookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action getPrdtCatList = async () => {
    var result = await this.getClient().getProductCategoryLookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  
  @action getMarginFactorBySubcategoryId = async (id:number) => {
    var result = await this.getClient().getMarginFactorBySubCategoryId(
      id,
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getProductSubCategoriesLookup = async () => {
    var result = await this.getClient().getAllProductLookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };
  @action getPrdtSubCatList = async (catIds: number[]) => {
    var result = await this.getClient().getProductSubCategoriesLookup(
      this.getApiVersion(),
      catIds,
      UserContext.userId
    );
    return result;
  };
  @action getPrdtBySubCatList = async (subCatIds: number[]) => {
    var result = await this.getClient().getProductBySubCategories(
      this.getApiVersion(),
      subCatIds,
      UserContext.userId
    );
    return result;
  };

  @action getProductListBySubCat = async (subCatIds: number[]) => {
    var result = await this.getClient().getProductsLookupBySubCategory(
      this.getApiVersion(),
      subCatIds,
      UserContext.userId
    );
    return result;
  };

  /*
    @action getProductSubCategoryById = async (id: number) => {
        var result :  ProductSubCategoryModel = await this.getClient().getProductSubCategoryById(id, this.getApiVersion(), UserContext.userId);
        return result;
    };
    */

  @action
  getProductSubCategoryList = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "ProductSubCategoryName",
    isSortAscending: boolean = true
  ) => {
    var result = await this.getClient().getAllProductSubCategories(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      null,
      UserContext.getUserId()
    );
    return result;
  };

  @action
  getProductSubCategoryById = async (
    id: number
  ): Promise<ProductSubCategoryModel> => {
    var result = await this.getClient().getProductSubCategoryById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  updateProductSubCategory = async (item: ProductSubCategoryModel) => {
    await this.getClient().updateProductSubCategory(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addProductSubCategory = async (item: ProductSubCategoryModel) => {
    await this.getClient().addProductSubCategory(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deleteProductSubCategory = async (id: number) => {
    var result = await this.getClient().deleteProductSubCategory(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  // Product Line CRUD
  @action
  getProductLineList = async (
    startIndex: number,
    pageSize: number,
    sortColumn: string = "ProductLineName",
    isSortAscending: boolean = true
  ) => {
    var result = await this.getClient().getAllProductLines(
      this.getApiVersion(),
      startIndex,
      pageSize,
      sortColumn,
      isSortAscending,
      null,
      UserContext.getUserId()
    );
    return result;
  };

  @action
  getProductLineById = async (id: number): Promise<ProductLineModel2> => {
    var result = await this.getClient().getProductLineById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action
  updateProductLine = async (item: ProductLineModel2) => {
    await this.getClient().updateProductLine(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  addProductLine = async (item: ProductLineModel2) => {
    await this.getClient().addProductLine(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      item
    );
  };

  @action
  deleteProductLine = async (id: number) => {
    await this.getClient().deleteProductLine(
      this.getApiVersion(),
      "" + id,
      UserContext.getUserId(),
      undefined,
      id
    );
  };

  @action
  public getAllProductLinesLookup = async () => {
    var result = await this.getClient().getAllProductLinesLookup(
      this.getApiVersion(),
      UserContext.userId
    );
    return result;
  };

  @action
  getAllProducts = async (
    companyId: string,
    recordType: any,
    subCategoryId: number[], 
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    filterText: string
  ) => {
    var result = await this.getClient().getAllProduct(
      this.getApiVersion(),
      +companyId,
      recordType == null ? null : (recordType=='1'? true:false),
      subCategoryId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      filterText,
      UserContext.getUserId()
    );

    return result;
  };

  @action
  getProductById = async (productId: string) => {
    var result: ProductModel = await this.getClient().getProductById(
      +productId,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };

  @action
  deleteProductById = async (productId: string) => {
    var result = await this.getClient().deleteProduct(
      +productId,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };

  @action
  addProduct = async (productModel: ProductModel) => {
    var result = await this.getClient().createProduct(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      productModel
    );

    return result;
  };

  @action updateProduct = async (productModel: ProductModel) => {
    var result = await this.getClient().updateProduct(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      productModel
    );

    return result;
  };

  @action
  deductibles = async (
    productId: string,
    pageIndex: number,
    pageSize: number,
    sortcolumn: string,
    sortAscending: boolean
  ) => {
    var result = await this.getClient().getAllDeductibles(
      this.getApiVersion(),
      +productId,
      pageIndex,
      pageSize,
      sortcolumn,
      sortAscending,
      "",
      UserContext.getUserId()
    );
    return result;
  };

  @action addDeductible = async (deductibleModel: DeductibleModel) => {
    var result = await this.getClient().createDeductible(
      this.getApiVersion(),
      UserContext.getUserId,
      undefined,
      deductibleModel
    );
    return result;
  };
  @action updateDeductable = async (deductibleModel: DeductibleModel) => {
    var result = await this.getClient().updateDeductible(
      this.getApiVersion(),
      UserContext.getUserId,
      undefined,
      deductibleModel
    );
    return result;
  };
  @action deleteDeductable = async (deductibleId: number) => {
    var result = await this.getClient().deleteDeductible(
      deductibleId,
      this.getApiVersion(),
      UserContext.getUserId
    );
    return result;
  };

  @action getSubCategoryDetailsById = async (
    id: number,
    sortColumn: string,
    sortOrder: boolean
  ): Promise<GetSubCategoryDetailsResponse> => {
    var result = await this.getClient().getSubCategoryDetailsById(
      this.getApiVersion(),
      id,
      0,
      10000,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action DownloadAllProductSubCategoryReport = async () => {
    var result = await this.getClient().downloadAllProductSubCategoryReport(
      this.getApiVersion(),
      0,
      1000,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action downloadProductReport = async (
    companyId: number,
    recordType: number,
    subCategoryId: number[],      
    sortColumn: string,
    sortAscending: boolean,
    filterText: string
  ) => {
    return this.getClient().downloadProductReport(      
      companyId,
      recordType,     
      sortColumn,
      sortAscending,
      subCategoryId,    
      filterText,
      this.getApiVersion(),
      UserContext.getUserId(),
      null
    );
  };

  // Product Sub Categories

  @action getProductSubCategoryDetailsList = async () => {
    var result = await this.getClient().getAllProductSubCategoryDetails(
      this.getApiVersion(),
      UserContext.getUserId(),
      null
    );
    return result;
  };

  @action getProductLines = async (): Promise<ProductLineModel> => {
    var result = await this.getClient().getProductLineLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getProductAssociationDetails = async (productId: number) => {
    var result = await this.getClient().getProductAssociationDetails(
      productId,
      this.getApiVersion(),
      UserContext.getUserId(),
      null
    );
    return result;
  };
}
export default new ProductStore();
