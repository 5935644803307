import { BaseListViewModel } from "../../../components/BaseListViewModel";
import { action, observable } from "mobx";
import {
  PayoutLevelDetailModel,
  PayoutLevelDetailsListModel,
  PayoutLevelModel,
  PayoutLevelsListModel,
} from "../../../../services/AccountingService";
import AccountingStore from "../../../../store/AccountingStore";
import Collection from "../../../../infrastructure/CollectionHelper";
import Utils from "../../../../infrastructure/Utils";
import DocumentStore from "../../../../store/DocumentStore";
import PageContext from "../../../../infrastructure/PageContext";
import UserContext from "../../../../infrastructure/UserContext";
import { PayoutLevel } from "../../../../infrastructure/enum/Settings";
import { PayoutLevelType } from "../../../../infrastructure/enum/Common";

export class PayoutLevelListViewModel extends BaseListViewModel<PayoutLevelsListModel> {
  constructor() {
    super("Payout Levels", "Payout Level", null, null, "id", "id", false);
    this.pageSize = 50;
  }

  @observable searchFilterType = new Collection<{
    label: string;
    value: string;
  }>();
  @observable searchFilterSelected: any = "0";

  @observable refreshVisible: boolean = false;
  @observable searchValue: string = "";

  //
  @observable companyId: string = "";
  @observable statusId: string = "";
  @observable typeIdAddEdit: string = "0";
  @observable typeListAddEdit = new Collection<{
    label: string;
    value: string;
  }>();
  @observable statusList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
                
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable formCompanyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable PayoutProductList = new Collection<PayoutLevelDetailsListModel>();
  @observable addEditPayoutLevelDialogVisible: boolean = false;
  @observable actionTitle: string = "";
  @observable description: string = "";
  @observable selectedCompanyId: string = "";
  @observable compensationLevel: string = "";
  @observable hidden: string = "";
  @observable productId: string = "";
  @observable producPayouttList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable documentList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable docId: string = "";
  @observable title: string = "";
  @observable addEditProductPayoutDialogVisible: boolean = false;
  @observable descriptionValid: boolean = false;
  @observable duplicateValid: boolean = false;
  @observable validDescError: string = "";
  @observable validDuplicateError: string = "";
  @observable validTypeError: string = "";
  @observable validCarrierError: string = "";
  @observable commission: string = "0";
  @observable pdfData: string = "";
  @observable productValid: boolean = false;
  @observable commissionValid:boolean=false;
  @observable validProductError: string = "";
  @observable validcommissionError: string="";
  @observable selectedProductId: number = 0;
  @observable selectedProductCompanyId: number = 0;
  @observable isProductDeleteConfirmationVisible: boolean = false;
  @observable productSortColumn: string = "product";
  @observable productSortorder: boolean = true;
  @observable productRows: number = 0;
  @observable productPageSize: number = 10;
  @observable firstProductIndex: number = 0;
  @observable productStartIndex: number = 0;
  @observable totalRowsProductCount: number = 0;
  @observable isSaveDisable: boolean = true;
  @observable isSaveDisableBtn: boolean = false;
  @observable isDuplicateLevelName: boolean = false;
  @observable isPayoutException: boolean = false;
  @observable payoutLevelResponse: any;

  @action setProductDeleteConfirmation = (value: boolean) => {
    this.isProductDeleteConfirmationVisible = value;
  };

  @action onProductPage(firstIndex: number, rows: number) {
    this.firstProductIndex = firstIndex;
    this.productPageSize = rows;
    this.productStartIndex = firstIndex / this.productPageSize;
    this.loadPayoutProducts();
  }

  protected async loadLookups(): Promise<void> {
    if (!this.companyId && !this.statusId) {
      await this.loadPayoutLevelDropDownValues();
    }
  }

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList.values = []; 
    this.showInactiveCarriers ? (this.companyList.values = this.carriersInitial.values) :
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values); 
  };
     
  @action loadPayoutLevelDropDownValues = async () => {
    var result = await AccountingStore.payoutLevelDropDownValues();
    if (result) {
      this.companyList.values = [];
      this.formCompanyList.values = [];
      this.companyList.values = this.mapListItemAndSort(result.company);
      this.companyList.values.splice(0, 0, { label: "All", value: "0" });
      this.companyId = this.companyList.values[0].value;

      this.formCompanyList.values = this.mapListItemAndSort(result.company);
      this.selectedCompanyId = this.formCompanyList.values[0].value;

      this.searchFilterType.values = [];
      this.searchFilterType.values = this.mapListItemAndSort(
        result.payoutLevelType
      );
      this.searchFilterType.values.splice(0, 0, { label: "All", value: "0" });
      this.carriersInitial.values = this.companyList.values;
      this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
      
    
      this.searchFilterSelected = this.companyList.values[0].value;

      this.statusList.values = [];
      this.statusList.values = this.mapListItemAndSort(result.status);
      this.statusId = this.statusList.values[0].value;
      this.search();
    }
  };

  @action addEditPayoutLevelDialog = (value: boolean) => {
    this.isPayoutException = false;
    this.resetpayoutLevel();
    this.addEditPayoutLevelDialogVisible = value;
    if (!value) {
      this.isDuplicateLevelName = false;
    }
  };

  @action addEditProductPayoutDialog = (value: boolean) => {
    this.addEditProductPayoutDialogVisible = value;
    this.commissionValid = false;
    this.validcommissionError = "";
  };

  @action search = async () => {
    this.selectedId = 0;
    this.PayoutProductList.values = [];
    this.resetPaging();
    this.loadItems();
  };

  @action resetpayoutLevel = async () => {
    this.validCarrierError = "";
    this.validTypeError = "";
    this.description = "";
    this.compensationLevel = "";
    this.descriptionValid = false;
    this.selectedProductId = 0;
  };

  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    var result = await AccountingStore.getAllPayoutLevels(
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      +this.companyId,
      this.searchFilterSelected == "0" ? null : this.searchFilterSelected,
      this.statusId && this.statusId === "true" ? true : false,
      this.searchValue
    );
    setTimeout(() => {
      // if(result && result.data){
      if (result && result.data && result.data.length > 0) {
        this.selectedId = result.data[0].id || 0;
        this.selectedProductCompanyId = result.data[0].companyId || 0;
        this.loadPayoutProducts();
      }
    }, 500);
    return result;
  }

  protected async deleteItem(id: number) {
    await AccountingStore.deletePayoutLevel(id);
    this.loadPayoutProducts();
  }

  protected resetFiltersToDefault = () => {
    this.selectedId = 0;
    this.PayoutProductList.values = [];
    this.pageSize = 50;
  };

  @action loadPayoutProducts = async () => {
    var result = await AccountingStore.getAllPayoutLevelDetails(
      this.selectedId,
      this.productStartIndex,
      this.productSortColumn,
      this.productSortorder,
      this.productPageSize
    );
    if (result) {
      this.productRows = result.recordCount || 0;
      this.PayoutProductList.values = result.data ? result.data : [];
    }
  };

  @action setProductSortColumn(column: string) {
    this.productSortColumn = column;
  }

  @action setSortOrder() {
    this.productSortorder = !this.productSortorder;
    this.productStartIndex = 0;
    this.firstProductIndex = 0;
  }

  @action loadpayoutLevelDetails = async (
    id: number,
    companyId: string,
    fetchlevel: boolean
  ) => {
    var result = await AccountingStore.getPayoutLevelById(id, +companyId);
    if (result) {
      if (result.description && fetchlevel) {
        this.description = result.description;
      }
      if (result.compensationLevel && fetchlevel) {
        this.compensationLevel = result.compensationLevel;
      }
      this.selectedCompanyId = result.companyId
        ? "" + result.companyId
        : this.companyId;
      this.hidden = result.hidden && result.hidden === true ? "true" : "false";
      this.documentList.values = [];
      this.documentList.values = this.mapListItemAndSort(result.documents);
      this.docId = result.docId ? "" + result.docId : "";

      this.typeListAddEdit.values = [];
      this.typeListAddEdit.values = this.mapListItemAndSort(
        result.payoutLevelType
      );
      // this.typeListAddEdit.values.splice(0, 0, { label: "All", value: "0" });

      this.typeIdAddEdit = result.typeId ? "" + result.typeId : "0";
      // this.isDuplicateLevelName = false;
      // this.handleSaveBtn();
    }
  };

  // @action handleSaveBtn = () =>{
  //   this.isSaveDisableBtn =  this.isDuplicateLevelName ||  this.typeIdAddEdit =='0' || this.description =='' || this.selectedCompanyId == '0';
  // }

  @action savePayoutLevel = async () => {
    try {
      if (this.typeIdAddEdit == "0") {
        this.validCarrierError = "Carrier is required";
      }
      if (!this.description) {
        this.descriptionValid = true;
        this.validDescError = "Level is required";
      }
      if (this.typeIdAddEdit == "0") {
        this.validTypeError = "Type is required";
      }
      if (
        this.description.trim() == "" ||
        this.description.trim() == undefined
      ) {
        this.descriptionValid = true;
        this.validDescError = "Level is required";
        this.description = "";
        return 0;
      }

      if (!this.description || this.typeIdAddEdit == "0") {
        return 0;
      }

      if (this.validDescError) {
        return 0;
      }
      this.isPayoutException = false;
      var dto: PayoutLevelModel = {
        id: this.actionTitle === PayoutLevel.Add ? 0 : +this.selectedId,
        description: this.description,
        companyId: +this.selectedCompanyId,
        docId: +this.docId,
        hidden: this.hidden === "true" ? true : false,
        compensationLevel: this.compensationLevel,
        createUserId: UserContext.getUserId(),
        typeId: this.typeIdAddEdit ? Number(this.typeIdAddEdit) : 0,
      };
      this.actionTitle === PayoutLevel.Add
        ? await AccountingStore.addPayoutLevel(dto)
        : await AccountingStore.updatePayoutLevel(dto);
      this.addEditPayoutLevelDialog(false);
      this.search();
    } catch (error: any) {
      error.text().then((_responseText: any) => {
        this.payoutLevelResponse = _responseText;
        this.isPayoutException = true;
      });
    }
  };

  @action loadproductPayoutDetails = async (id: number) => {
    var result = await AccountingStore.getPayoutLevelDetailById(
      id,
      +this.selectedId,
      +this.selectedProductCompanyId
    );
    if (result) {
      this.producPayouttList.values = [];
      this.producPayouttList.values = this.mapListItemAndSort(
        result.productList ? result.productList : []
      );
      this.commission = result.commission ? "" + result.commission : "0";
      this.productId = "" + result.productId || "";
    }
  };

  @action resetProductPayouts = () => {
    this.productId = "";
    this.commission = "0";
    this.isPayoutException = false;
    this.selectedProductId = 0;
  };
  @action commissionValidation = async () => {    
    var comm=this.commission;
    if (comm!="0") {          
      var commlen= Utils.formatNum(this.commission);
      var t= Math.floor(commlen);
      if (t.toString().length >7) {
        this.commissionValid = true;
      this.validcommissionError = "Invalid commission value. Max 7 digits before and 2 after the decimal.";        
        return 0;
      } 
      else
      {
        this.commissionValid = false;
        this.validcommissionError = "";
      }    
    }  

  };
  @action saveproductPayout = async () => {
    try {
      if (!this.productId) {
        this.productValid = true;
        this.validProductError = "Product is required";
        return 0;
      }
      // var comm=this.commission;
      // if (comm!="0") {
      //   // const regex = /^\d{0,7}(\.\d{0,4})?$/;
      //   // if (!regex.test(comm)) {
      //   // this.commissionValid = true;
      //   // //"Invalid commission format. Max 7 digits before and 4 after the decimal."
      //   // this.validcommissionError = "Invalid commission value.";
      //   //   return 0;
      //   // }
      //   var commlen= Utils.formatNum(this.commission);
      //   var t= Math.floor(commlen);
      //   if (t.toString().length >7) {
      //     this.commissionValid = true;
      //   this.validcommissionError = "Invalid commission value. Max 7 digits before and 2 after the decimal.";        
      //     return 0;
      //   }     
      // }  
      var dto: PayoutLevelDetailModel = {
        id: this.selectedProductId || 0,
        payoutLevelId: this.selectedId,
        productId: +this.productId,
        commission: Utils.formatNum(this.commission) || 0,
        createUserId: UserContext.getUserId(),
      };
      this.selectedProductId === 0
        ? await AccountingStore.addPayoutLevelDetail(dto)
        : await AccountingStore.updatePayoutLevelDetail(dto);
      this.addEditProductPayoutDialog(false);
      this.resetProductPayouts();
      this.loadPayoutProducts();
    } catch (error: any) {
      error.text().then((_responseText: any) => {
        this.payoutLevelResponse = _responseText;
        this.isPayoutException = true;
      });
    }
  };

  @action openDocument = async (docId: number) => {
    window.open(
      window.location.origin + "/documentviewer/" + docId + "/" + 0,
      "_new"
    );
  };

  @action deleteProductPayout = async () => {
    await AccountingStore.deleteProductPayout(this.selectedProductId);
    this.setProductDeleteConfirmation(false);
    this.loadPayoutProducts();
  };

  //

  @action setSearchFilterDropdown = (value: string) => {
    this.searchFilterSelected = value;
    this.resetRefesh();
    this.loadItems();
  };

  @action resetRefesh() {
    if (
      this.searchFilterSelected == "0" &&
      this.companyId == "0" &&
      this.statusId == "false" &&
      (this.searchValue.length == 0 || this.searchValue.length < 3)
    )
      this.refreshVisible = false;
    else this.refreshVisible = true;
  }

  @action onFieldChange = (value: any) => {
    if (value.length == 1 && value == " ") {
      this.searchValue = "";
      this.resetRefesh();
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        this.searchValue = "";
        this.resetRefesh();
        return false;
      } else {
        this.searchValue = value;
        if (this.searchValue.length == 0 || this.searchValue.length > 2) {
          this.loadItems();
        }
        this.resetRefesh();
        return true;
      }
    }
  };

  @action reset = async () => {
    this.showInactiveCarriers = false;
    this.companyList.values = Utils.carriersFilter(this.carriersInitial.values);
    this.searchFilterSelected = "0";
    this.statusId = "" + false;
    this.companyId = "0";
    this.searchValue = "";
    this.resetRefesh();
    this.loadItems();
  };

  @action duplicateNameValidation = async () => {
    if (this.selectedCompanyId != "0" && this.description != "") {
      let res = await AccountingStore.getValidatePayoutLevelByCarrierId(
        this.description,
        Number(this.selectedCompanyId),
        this.actionTitle === PayoutLevel.Add ? 0 : this.selectedId
      );
      this.isSaveDisable = res || this.typeIdAddEdit == "0";
      this.descriptionValid = res;
      this.isDuplicateLevelName = res;
      this.validDescError = res ? "Level name already exists." : "";
    }
  };
}
