import { observable, action } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import RouteList from "../../../infrastructure/RouteList";
import IdentityStore from "../../../store/IdentityStore";
import Utils from "../../../infrastructure/Utils";
import { AuditReportChangeSetDetailResponse, AuditTrailReportModel, ChangedValueSet, DownloadAuditTrailModel } from "../../../services/IdentityService";
import PageContext from "../../../infrastructure/PageContext";
import { routes } from "../../../router";
import { AgentListModel } from "../../../services/ProducerSearchService";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import { Console } from "console";
// import { number } from "prop-types";

export class UserAuditReportViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable pageIndex: number = 0;

  constructor() { }

  @action Load = async () => {
    this.resetForm();
    this.fromDate = this.DefaultDate(0);
    
    this.loadUsers('');
    this.loadModules();
    this.loadSubModules(0);
    this.LoadUserAuditReport(0);
  };

  @action DefaultDate = (value: number) => {
    var d: Date = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    return new Date(year, month, day - value);
  };

  Route = async (currentRoute: RouteList): Promise<void> => { };
  static Symbol: symbol = Symbol("UserAuditTrailViewModel");

  @observable expanded: string | boolean = false;

  @observable rows: number = 50;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable name: string = "";
  @observable isLoading: boolean = true;
  @observable id: string = "";
  @observable startIndex: number = 0;
  @observable startSearchIndex: number = 0;
  @observable serachInputValue: string = "";
  @observable agentsSearchList: number = 0;
  @observable hasAgentInvalidValue: boolean = false;
  @observable userAuditList = new Collection<AuditTrailReportModel>();
  private onClose?: () => void;
  @observable actionLogs = new Collection<any>();
  @observable sortColumn: string = "CreatedDate";
  @observable sortOrder: boolean = false;
  @observable userList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable actionList = [
    { label: "All", value: "" },
    { label: "Create", value: "create" },
    { label: "Update", value: "update" },
    { label: "Delete", value: "delete" },
  ];
  @observable fromDate: Date | undefined;
  @observable toDate: Date | undefined = new Date();
  @observable selectedAction: string = "";
  @observable selectedUser: string = "";
  @observable selectedAgent: string = "";
  @observable agentId: number | undefined;
  @observable agentIdCheck: number = 0;
  @observable selectedUserId: string = "";
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @observable searchValue: string = "";
  @observable noRecordFound: string = "";
  @observable currentPage: number = 0;
  @observable pageSize: number = 25;
  @observable valueDate: number = 0;
  @observable firstItem: number = 0;
  @observable isSortAscending: boolean = false;
  @observable defaultIsSortAscending: boolean = false;
  @observable defaultSortColumn: string = "CreatedDate";
  @observable totalRowsCount: number = 10;
  @observable expandableRows: any = [];
  @observable rowChangedValues = new Collection<ChangedValueSet>();
  @observable auditId: number = 0;;
  @observable entityName: string = "";
   @observable FilteredAgentsList = new Collection<AgentListModel>();
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  readonly currentPageReportTemplate: string =
    "Showing {first} - {last} of {totalRecords} records";
  paginatorTemplate(): string {
    return this.userAuditList.values.length > 0
      ? "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      : "";
  }
  rowsPerPageOptions(): number[] {
    return this.userAuditList.values.length > 0 ? [5, 10, 25, 50] : [];
  }

  onSort = (sortColumn: any) => {
    if (sortColumn.sortField === this.sortColumn)
      this.isSortAscending = !this.defaultIsSortAscending;
    else this.sortColumn = sortColumn.sortField;

    this.sortItems();
    this.defaultIsSortAscending = this.isSortAscending;
  };

  @action async sortItems() {
    await this.LoadUserAuditReport(this.valueDate);
  }

  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  onPage = (pageInfo: any) => {
    this.firstItem = pageInfo.first;
    this.pageSize = pageInfo.rows;
    this.pageIndex = pageInfo.first / pageInfo.rows;
    this.loadPage(pageInfo.page, pageInfo.rows);
  };

  @action loadPage(pageIndex: number, pageSize: number) {
    this.currentPage = pageIndex;
    this.pageSize = pageSize;
    this.firstItem = pageIndex * pageSize;

    this.LoadUserAuditReport(this.valueDate);
  }

  @action resetPaging = () => {
    this.firstItem = 0;
    this.currentPage = 0;
    this.sortColumn = this.defaultSortColumn;
    this.isSortAscending = this.defaultIsSortAscending;
  };
  @action setFirstPage = () => {
    this.firstItem = 0;
    this.currentPage = 0;
  };

  @action goToAdmin = () => {
    routes.administration.push();
  };


  @observable selectedEntity: string = "";

  @observable selectedModule: string = "0";
  @observable selectedSubModule: string = "0";

  @observable moduleList = [];
  @observable subModuleList = [];

  @action mapListItemAndSort(listObjects: any, actionItem: string) {
    if (listObjects) {
      listObjects.splice(0, 0, {
        text: "All " + actionItem,
        value: "0",
        selected: false,
      });
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  // @action loadUsers = async (value: string) => {
  //   if (value && value.length >= 3) {
  //     var result = await IdentityStore.getAuditTrailUserLookup(value);
  //     var values = null;
  //     if (result !== null) {
  //       values = result;
  //     }
  //     if (values !== undefined && values !== null) {
  //       this.userList.values = this.mapListItemAndSort(values, "Users");
  //     } else {
  //       this.userList.values = [];
  //     }
  //   }
  //   this.isLoading = false;
  // };
  @action loadUsers = async (value: string) => {
    
      var result = await IdentityStore.getAuditTrailUserLookup(value);
      var values = null;
      if (result !== null) {
        values = result;
      }
      if (values !== undefined && values !== null) {
        this.userList.values = this.mapListItemAndSort(values, "Users");
      } else {
        this.userList.values = [];
      }
    
    this.isLoading = false;
  };

  @action loadModules = async () => {
    var result = await IdentityStore.getAuditModules();
    var values = null;
    if (result !== null) {
      values = result;
    }
    if (values !== undefined && values !== null) {
      this.moduleList = this.mapListItemAndSort(values, "");
    } else {
      this.moduleList = [];
    }
    this.isLoading = false;
  };

  @action loadSubModules = async (moduleId: number) => {
    var result = await IdentityStore.getAuditSubModules(moduleId);
    var values = null;
    if (result !== null) {
      values = result;
    }
    if (values !== undefined && values !== null) {
      this.subModuleList = this.mapListItemAndSort(values, "");
    } else {
      this.subModuleList = [];
    }
    this.isLoading = false;
    this.selectedSubModule = "0";
  };

  @action setExpandableRows = async (data: any) => {
    var rowData = data.filter(
      (item: any) => item.id === this.auditId
    );
    if (rowData.length > 0) {
      await this.loadChangedValuesByRow(this.auditId, this.entityName);
    }
    this.expandableRows = data;
  }

  @action onRowExpand = async (data: any) => {
    this.auditId = data.id;
    this.entityName = data.tableName;
  }

  @action loadChangedValuesByRow = async (auditId: number, entityName: string) => {
    this.isLoading = true;
    var result = await IdentityStore.loadChangedValuesByRow(auditId, entityName);
    this.rowChangedValues.values = [];
    
    if (result !== null) {

      this.rowChangedValues.values = result.changedValues;
    }
    else {
      this.rowChangedValues.values = [];
    }
    this.userAuditList.values.forEach((item: any) => {
      if (item.id === auditId) {
        item.changedValues = [];
        item.changedValues = this.rowChangedValues.values;
      }

    });
    this.isLoading = false;
    
  };

  @action loadAuditReport = (valueDate: number) => {
    this.expandableRows =[];
    this.firstItem = 0;
    this.currentPage = 0;
    this.pageIndex = 0;
    this.LoadUserAuditReport(valueDate);
  };

  @action LoadUserAuditReport = async (valueDate: number) => {
    this.totalRecords = 0;
    var action = this.selectedAction;
    var entity = this.selectedEntity;
    var fromDate1 = this.fromDate;
    var toDate1 = this.toDate;
    valueDate = valueDate == -1  ? (this.fromDate == undefined && this.toDate == undefined ? (this.valueDate === -1 ? 0 : this.valueDate) : valueDate) : valueDate;
    this.valueDate = valueDate;
    if (valueDate > -1) {
      // action = "";
      // entity = "";
      fromDate1 = this.DefaultDate(valueDate);
      toDate1 = this.DefaultDate(valueDate == 1 ? valueDate : 0);
      this.fromDate = undefined;
      this.toDate = undefined;
    }
    let result: any = await IdentityStore.userAuditReport(
      this.selectedUserId != null && this.selectedUserId != ""
        ? Number.parseInt(this.selectedUserId)
        : 0,
      "",
      action,
      this.selectedModule == "0" ? undefined : +this.selectedModule,
      this.selectedSubModule == "0" ? "" : this.selectedSubModule,
      fromDate1,
      toDate1,
      this.agentId,
      this.currentPage,
      this.pageSize,
      this.sortColumn,
      this.isSortAscending,
      ""
    );
    this.userAuditList.values = [];
    if (result.data && result.data.length > 0) {
      this.totalRecords = result.recordCount || 0;
      this.totalRowsCount = result.recordCount || 0;
      if (result.data) {
        this.userAuditList.values = result.data;
        this.noRecordFound = "";
      }
    } else {
      this.noRecordFound = "No Records Found";
      this.noRecordsFound();
    }
  };
  @action resetForm = () => {
    this.noRecordsFound();
    this.selectedUser = "";
    this.selectedUserId = "";
    this.searchValue = "";
    this.selectedEntity = "";
    this.fromDate = this.DefaultDate(0);
    this.toDate = new Date();
    this.selectedAction = "";
    this.selectedAgent ="";
    this.selectedSubModule="0"
    this.selectedModule ="0"
    this.agentId = 0;
    this.expandableRows =[];
    PageContext.setIsMessageVisible(false);
   
  };

  @action reset = () => {
    this.noRecordsFound();
    this.selectedUser = "";
    this.selectedUserId = "";
    this.searchValue = "";
    this.selectedEntity = "";
    this.fromDate = this.DefaultDate(0);
    this.toDate = new Date();
    this.selectedAction = "";
    PageContext.setIsMessageVisible(false);
    this.LoadUserAuditReport(0);
  };

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.userAuditList.values = [];
  }
  @action searchAgents = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        this.selectedAgent = "";
      }
    } else {
      this.agentsSearchList = 0;
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
    }
    this.isLoading = false;
    setTimeout(() => {}, 500);
  };

  @action setSelectedAgentId(id: any) {
    this.agentId = id === "" ? 0 : id;
  }

  @action setSelectedAgent(value: string) {
    this.selectedAgent = value;
    if (value === "") {
      this.agentId = 0;
    }
  }

  @action downloadUserAuditReport = async () => {
    if(this.totalRecords > 50000){
       Utils.showWarningToaster("Download can't be processed due to file size. Downloads cannot exceed 50,000 records." , 90000);
       return;
    }
    var model: DownloadAuditTrailModel = {
      userId: this.selectedUserId != null && this.selectedUserId != "" 
        ? Number.parseInt(this.selectedUserId) : 0,
      userGuid: undefined,      
      actionName: this.selectedAction,
      fromDate: (this.fromDate === undefined && this.valueDate > -1)  ? this.DefaultDate(this.valueDate) : this.fromDate,
      toDate: (this.toDate === undefined && this.valueDate > -1) ? this.DefaultDate(this.valueDate == 1 ? this.valueDate : 0) : this.toDate,
      agentId: this.agentId,
      moduleId: this.selectedModule == "0" ? undefined : +this.selectedModule,
      subModuleEntityName: this.selectedSubModule == "0" ? "" : this.selectedSubModule,
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: this.sortColumn,
      sortAscending: this.isSortAscending,
      fullTextFilter: "",
    };

    let result = await IdentityStore.downloadAuditTrail(model);

    await this.downloadReport(
      result,
      result.fileName ? result.fileName : "User_Audit_Trail_Report.xlsx"
    );
  }

  @action downloadReport = async (result: ReportFile, name: string) => {
    if (result) {
      await fetch(`data:${result.contentType};base64,${result.data}`)
        .then((response) => response.blob())
        .then(function (myBlob) {
          var link = document.createElement("a");
          var url = window.URL.createObjectURL(myBlob);
          let n = result.fileName ? result.fileName.split(".")[0] : "";
          link.href = url;
          link.download = n !== "" ? n : name;
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  };
}
