import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import PageContext from "../../../../infrastructure/PageContext";
import Utils from "../../../../infrastructure/Utils";
import { routes } from "../../../../router";
import { ProductListModel } from "../../../../services/ProductService";
import ContractsStore from "../../../../store/ContractsStore";
import ProductStore from "../../../../store/ProductStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";

export class ProductListViewModel extends BaseListViewModel<ProductListModel> {
  @observable companies = new Collection<{ label: string; value: string }>();       
                  
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();

  @observable searchInputValue: string = "";
  @observable isRefreshEnable: boolean = false;
  @observable selectedCompany: string = "0";
  @observable selectedsubCategoryIds: any;
  @action setSelectedCompany = async (value: string) => {
    this.selectedCompany = value;
  };

  @observable recordTypes = new Collection<{ label: string; value: string }>();
  @observable selectedRecordType: string = "2";
  @observable marginFactor: string = "";
  @observable subCategoryId: number = 0;
  @observable subCategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable dbcategoryList = new Collection<any>();
  @observable categoryId: number = 0;
  @observable categoryName: string = "";
  @observable searchValue: string | undefined = undefined;
  @observable startIndex: number = 0;
  @action setSelectedRecordType = async (value: string) => {
    this.selectedRecordType = value;
  };

  @observable selectedItem: ProductListModel = {};
  @action setSelectedItem(value: ProductListModel) {
    this.selectedItem = value;
  }

  constructor() {
    super(
      "Products",
      "Product",
      routes.addProduct,
      routes.editProduct,
      "id",
      "id",
      false
    );
    this.pageSize = 50;
  }

  protected async loadLookups() {
    await this.loadRecordTypes();
    await this.loadCompanies();
    await this.loadSubCategories();
  }

  protected async loadSubCategories() {
    var result = await ProductStore.getProductSubCategoriesLookup();
    this.dbcategoryList.values = result.subCategories
      ? result.subCategories
      : [];
    // this.subCategoryList.values.push({ label: "All Subcategory", value: "0" });
    result.subCategories &&
      result.subCategories.forEach((element: any) => {
        this.subCategoryList.values.push({
          label: element.subCategoryText,
          value: element.subCategoryValue,
        });
      });
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    // companyId and recordTypeId should be number at the API end
    this.isMessgeVisible = false;
    this.response = {};
    var companyId = this.selectedCompany;
   
    return await ProductStore.getAllProducts(
      companyId,
      this.selectedRecordType == "0" ? null : this.selectedRecordType,
      this.selectedsubCategoryIds,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      this.searchInputValue
    );
  }
  @observable isMessgeVisible: boolean = false;
  @observable response: any;
  protected async deleteItem(id: number) {
    this.onDeleteClick(id);
  }

  @observable hideOk: boolean = false;

  @action onDeleteClick = async (id: number) => {
    this.isMessgeVisible = false;
    let result = await ProductStore.deleteProductById(id.toString());
    if (result && result.message) {
      var responseModel = {
        status: 400,
        title: result.message,
        errors: { "": [] },
      };
      setTimeout(() => {
        PageContext.setResponseMessage(JSON.stringify(responseModel));
        PageContext.setIsMessageVisible(true);
      }, 1000);
    }
  };

  @action downloadProductListAsExcel = async () => {
    
    let res = await ProductStore.downloadProductReport(
      +this.selectedCompany,
      +this.selectedRecordType,
      this.selectedsubCategoryIds,      
      this.sortColumn,    
     this.isSortAscending,
      this.searchInputValue
    );
    Utils.saveDownloadedReportFile(res, "Products_Report");
  };

  /* Lookups */
  private async loadRecordTypes() {
    this.recordTypes.values = [
      { label: "All", value: "0" },
      { label: "Hidden", value: "1" },
      { label: "Not Hidden", value: "2" },
    ];
  }
  
@action handleInactiveCarriers = async (e: any) => {
  this.showInactiveCarriers = e.checked;
  this.companies.values = []; 
  this.showInactiveCarriers ? (this.companies.values = this.carriersInitial.values) :
  this.companies.values = Utils.carriersFilter(this.carriersInitial.values); 
};

  private async loadCompanies() {
    var items = await ContractsStore.GetActiveInactiveAllCarrierLookup();
    Utils.mapListItemsToDropdown(items, this.companies);
    this.carriersInitial.values = this.companies.values;
    this.companies.values = Utils.carriersFilter(this.carriersInitial.values);

  }

  @action setSubCategoryId(value: number) {
    this.subCategoryId = value;
    let category = this.dbcategoryList.values.find(
      (i) => i.subCategoryValue === value.toString()
    );
    this.categoryId = category ? category.categoryId : 0;
    this.categoryName = category ? category.categoryName : "";
  }

  @action subSetCategoryChange = (value: string) => {
    this.selectedsubCategoryIds = value;
    this.loadItems();
    this.refreshEnable();
  };

  resetFiltersToDefault() {
    // this.selectedCompany = "0";
    // this.selectedRecordType = "2";
    // this.pageSize = 50;
    // this.selectedsubCategoryIds = "";
    // this.searchInputValue = "";
    // this.refreshEnable();
  }

  @observable timer: any;
  // events for search
  @action searchIntervals = async (text: string) => {
    this.searchInputValue = text;
    if (text) {
      this.searchValue = text;
    } else {
      this.searchValue = undefined;
      this.startIndex = 0;
    }
    await this.refreshEnable();

    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.loadItems();
    }, 800);
  };

  @action refreshEnable = async () => {
    if (
      this.selectedRecordType != "2" ||
      this.selectedCompany != "0" ||
      this.selectedsubCategoryIds != "" ||
      (this.selectedsubCategoryIds &&
        this.selectedsubCategoryIds.length != 0) ||
      this.searchInputValue != ""
    ) {
      this.isRefreshEnable = true;
    } else {
      this.isRefreshEnable = false;
    }
    // if (
    //   (this.selectedRecordType == "0" || this.selectedRecordType == "") &&
    //   (this.selectedCompany == "0" || this.selectedCompany == "") &&
    //   (this.subCategoryId == 0 || this.subCategoryId == 0) &&
    //   (this.selectedsubCategoryIds == "") &&
    //   (this.searchInputValue == "" || this.searchInputValue.length <= 2)
    // ) {
    //   this.isRefreshEnable = false;
    // } else {
    //   this.isRefreshEnable = true;
    // }
  };

  @action resetFilters = async () => {
    this.showInactiveCarriers = false;
    this.selectedRecordType = "2";
    this.selectedCompany = "0";
    this.searchInputValue = "";
    this.subCategoryId = 0;
    this.selectedsubCategoryIds = "";
    this.companies.values = Utils.carriersFilter(this.carriersInitial.values);
    this.refreshEnable();
    await this.loadItems();
  };

  @observable displayMessage: string = "";

  @action showDeleteProductConfirmation = async () => {
    var result = await ProductStore.getProductAssociationDetails(
      this.selectedId
    );
    this.hideOk = result.displayMessage || false;

    if (result && result.displayMessage) {
      this.displayMessage = result.message || "";
    } else {
      this.displayMessage = "Are you sure you want to Delete this Product?";
    }
    this.showDeleteConfirmation();
  };
}
