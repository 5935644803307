import { action, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import ContractsStore from "../../../store/ContractsStore";
import Collection from "../../../infrastructure/CollectionHelper";
import {
  LatestLicensePersistencyModel,
  LicensePersistencyModel,
} from "../../../services/ContractService";
import Utils from "../../../infrastructure/Utils";

export class ContractPersistencyViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (contractId: number, agentId: number) => {
    this.contractId = contractId;
    this.agentId = agentId;
    await this.loadPersistency();
    this.setViewHistory(false);
    await this.loadLicensePersistencyTypes();
  };
  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable contractId: number = 0;
  @observable agentId: number = 0;
  @observable isLoading: boolean = true;
  @observable isException: boolean = true;
  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @observable PersistencyList = new Collection<LatestLicensePersistencyModel>();
  @observable PersistencyHistoryList =
    new Collection<LatestLicensePersistencyModel>();
  @observable PersistencyTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable viewHistory: boolean = false;
  @observable selectedPersistencyId: number = 0;
  @observable actionTitle: string = "";
  @observable addEditPersistencyDialogView: boolean = false;
  @observable percentage: string = "0.00";
  @observable selectedPersistencyType: string = "0";
  //Persistency
  @observable sortColumnPersistency: string = "createdDateTime";
  @observable sortOrderPersistency: boolean = false;
  @observable pageIndexPersistency: number = 0;
  @observable totalRecordsPersistency: number = 0;
  @observable rowsPersistency: number = 10;
  @observable firstPersistency: number = 0;

  @action loadPersistency = async () => {
    var result = await ContractsStore.getAllLicensePersistency(
      this.contractId,
      this.pageIndexPersistency,
      this.rowsPersistency,
      this.sortColumnPersistency,
      this.sortOrderPersistency
    );
    if (result && result.data) {
      var res = result.data;
      this.totalRecordsPersistency = result.recordCount || 0;
      this.PersistencyList.values = res;
    }
  };

  @action setViewHistory = (value: boolean) => {
    this.viewHistory = value;
    if (!value) {
      this.PersistencyHistoryList.values = [];
    }
  };

  @action AddEditPersistency = (id: number) => {
    if (id === 0) {
      this.actionTitle = "Add Persistency";
      this.selectedPersistencyId = 0;
      this.percentage = "0";
    } else {
      this.selectedPersistencyId = id;
      this.loadPersistencyById();
      this.actionTitle = "Edit Persistency";
    }
    this.addEditPersistencyDialog(true);
  };

  @action loadPersistencyById = async () => {
    var result = await ContractsStore.getLicensePersistencyById(
      this.selectedPersistencyId
    );
    if (result) {
      this.percentage = "" + result.persistencyPercentage;
      this.selectedPersistencyType = "" + result.licensePersistencyTypeId;
    }
  };

  @action deletePersistency = (id: number) => {
    ContractsStore.deleteLicensePersistency(id);
    setTimeout(() => {
      this.loadPersistencyHistory();
      this.loadPersistency();
    }, 500);
  };

  @action addEditPersistencyDialog = (value: boolean) => {
    this.addEditPersistencyDialogView = value;
  };

  @action loadLicensePersistencyTypes = async () => {
    var result = await ContractsStore.getAllLicensePersistencyLookup(
      this.contractId
    );
    if (result) {
      Utils.mapListItemsToDropdown(result, this.PersistencyTypeList);
      this.PersistencyTypeList.values.shift();
      this.selectedPersistencyType =
        this.PersistencyTypeList.values.length > 0
          ? this.PersistencyTypeList.values[0].value
          : "";
    }
  };

  @action setPercentage = (value: string) => {
    this.percentage = value;
  };

  @action loadPersistencyHistory = async () => {
    var result = await ContractsStore.getLicensePersistencyHistory(
      this.contractId
    );
    if (result && result.data) {
      var res = result.data;
      this.PersistencyHistoryList.values = res;
    }
  };

  @action onPersistencySave = () => {    
    var dto: LicensePersistencyModel = {
      licensePersistencyId: this.selectedPersistencyId,
      persistencyPercentage: Utils.formatNum(this.percentage),
      licensePersistencyTypeId: +this.selectedPersistencyType,
      licenseId: this.contractId,
    };
    if(dto.licensePersistencyTypeId!=0)
    {
    this.selectedPersistencyId === 0
      ? ContractsStore.createLicensePersistency(dto)
      : ContractsStore.updateLicensePersistency(dto);
    }
    this.addEditPersistencyDialog(false);
    setTimeout(() => {
      this.loadPersistencyHistory();
      this.loadPersistency();
    }, 500);
  };
  @action setSortOrderPersistency() {
    this.sortOrderPersistency = !this.sortOrderPersistency;
    this.pageIndexPersistency = 0;
    this.firstPersistency = 0;
  }
  @action onPagePersistency(firstIndex: number, rows: number) {
    this.rowsPersistency = rows;
    this.firstPersistency = firstIndex;
    this.pageIndexPersistency = firstIndex / rows;
    this.loadPersistency();
  }
  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }
  @action async setSortColumnPersistency(sortField: any) {
    this.sortColumnPersistency = sortField;
    await this.loadPersistency();
  }
}
