import { action, computed, observable, toJS } from "mobx";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import ViewModel from "../../infrastructure/ViewModel";
import ProducerStore from "../../store/ProducerStore";
import ProductStore from "../../store/ProductStore";
import { AgentSummary } from "../../infrastructure/enum/AgentDetails";
import {
  AlternativePointOfContactTypeValue,
  EmailTypeValue,
  PhoneType,
  PhoneTypeValue,
} from "../../infrastructure/enum/Common";
import { Permission } from "../../infrastructure/enum/Permission";
import IMASLog from "../../infrastructure/IMASLog";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import UserContext from "../../infrastructure/UserContext";
import Utils from "../../infrastructure/Utils";
import { routes } from "../../router";
import { ActionLogModel } from "../../services/IdentityService";
import { AgentListModel } from "../../services/ProducerSearchService";
import {
  AddressListModel,
  AgentSummaryGroup,
  AgentSummaryHierarchy,
  AgentSummaryMarketer,
  AgentSummaryModel,
  AgentSummaryNote,
  ContactModel,
  CreatePrincipalModel,
  CreateUpdateAddressModel,
  CreateUpdateContactModel,
  ListItem,
  NoteModel,
  PrincipalModelList,
} from "../../services/ProducerService";
import { AgentProductLines } from "../../services/ProductService";
import IdentityStore from "../../store/IdentityStore";
import {
  AddEditAddressViewModel,
  AddressListener,
} from "../components/AddEditAddressViewModel";
import {
  AddEditContactViewModel,
  ContactListener,
} from "./contacts/AddEditContactViewModel";
import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { ManageSubscriptionViewModel } from "./contacts/ManageSubscriptionViewModel";
import ContractsStore from "../../store/ContractsStore";

export class AgentSummaryViewModel
  implements ViewModel, ContactListener, AddressListener
{
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  listener: UpdateAgentListener | undefined;
  setListener(listener: UpdateAgentListener) {
    this.listener = listener;
  }
  @action Load = async () => {};
  @computed
  get isPrincipalAgentAvailable(): boolean {
    if (this.principalAgent.trim().length > 0 && this.principalAgentId > 0)
      return true;
    return false;
  }
  public ErrorModel = new ErrorModel(new SummaryValidator());
  @action public ErrorValidate = async () => {
    return this.ErrorModel.Validate(this);
  };
  @observable isLoadRecentProfiles: boolean = false;
  @observable sortColumn: string | undefined = "Id";
  @observable pageIndex: number = 0;
  @observable pageSize: number = 100;
  @observable sortOrder: boolean = false;
  @observable principalAgent: string = "";
  @observable principalAgentId: number = 0;
  Route = async (currentRoute: RouteList): Promise<void> => {};
  static Symbol: symbol = Symbol("AgentSummaryViewModel");
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable PrincipalAgentsList = new Collection<PrincipalModelList>();
  @observable expanded: string | boolean = false;
  @observable isAgency: boolean | undefined = false;
  @observable sureLcAgent: boolean | undefined = false;
  @observable isDnx: boolean = false;
  @observable showPhoneManageSubscriptions: boolean = false;
  @observable showEmailManageSubscriptions: boolean = false;
  @observable isInitialLoad: boolean = true;
  @observable isContactsEnabled: boolean = false;
  @observable isCollapsed: boolean = true;
  @observable selectedPanel: string = AgentSummary.Agent;
  @observable firstName: string = "";
  @observable lastName: string = "";
  @observable dob: string = "";
  @observable eoExpirationDate: string = "";
  @observable AgentContacts = new Collection<ContactModel>();
  @observable Marketers = new Collection<AgentSummaryMarketer>();
  @observable NotesList = new Collection<AgentSummaryNote>();
  @observable agentReferences = new Collection<ListItem>();
  @observable agentReferences_label: string = "";
  @observable ProductLineMatrices = new Collection<AgentProductLines>();
  @observable PrincipalAgentsData = new Collection<any>();
  @observable AgentHierarchy = new Collection<AgentSummaryHierarchy>();
  @observable AgentAddressList = new Collection<AddressListModel>();
  @observable GroupList = new Collection<AgentSummaryGroup>();
  @observable selectContactId: number = 0;
  @observable
  AddEditContactViewModel: AddEditContactViewModel =
    new AddEditContactViewModel();
  @observable
  AddEditAddressViewModle: AddEditAddressViewModel =
    new AddEditAddressViewModel();
  @observable isMessgeVisible: boolean = false;
  @observable response: any = "";
  @observable selectedTreeNode: any = "";
  @observable isUplineUpdate: boolean = false;
  @computed
  get name(): string {
    if (this.agentSummary) {
      return (
        "" +
        this.agentSummary.name +
        (this.agentSummary.nickName
          ? " ('" + this.agentSummary.nickName + "')"
          : "")
      );
    }
    return "";
  }
  @observable isLoading: boolean = true;

  @observable isDefaultDialogOpen: boolean = false;
  @observable ManageSubscriptionViewModel: ManageSubscriptionViewModel =
    new ManageSubscriptionViewModel();
  @observable manageSubscriptionDialog: boolean = false;
  @observable defaultDialogContent: string = "";
  @observable contactLinksEnabled: boolean = false;
  @observable isContatsEnableDilaogoOpen: boolean = false;
  @observable isSpanClicked: boolean = false;
  @observable profilePic: string = "";
  @observable agentId: number = 0;
  @observable selectedTabIndex?: number = 0;
  @observable agentSummary: AgentSummaryModel | null = null;
  @observable selectedRow: any | undefined = undefined;
  @observable contactDefault: any | undefined = undefined;
  @observable contactCommission: any | undefined = undefined;
  @observable enableSMS: any | undefined = undefined;
  @observable isMobile: any | undefined = undefined;
  @observable selectedAgentMarketer: number = 0;
  @observable AgentMarketerList = new Collection<ListItem>();
  @observable agentMarketersEnabled: boolean = false;
  @observable agentMarketersVisible: boolean = false;
  @observable editAgentReportingHierarchy: boolean = false;
  @observable agentReportingHierarchy: boolean = true;
  @observable selectedImmediateReportingUpline: string = "";
  @observable selectedImmediateReportingUplineId: number = 0;
  @observable immediateReportingUplineList = new Collection<any>();
  @observable selectedReportingHierarchyLevel: string = "";
  @observable selectedReportingHierarchyLevelId: number = 0;
  @observable selfAsUpline: boolean = true;
  //@observable setAgentImmediateUplineDisable: boolean = false;
  @observable reportingHierarchyLevelList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable expandedNodes: any;
  @observable hierarchyData: any;
  @observable hierarchyFilter: string = "";
  @observable dataChild: any;
  @observable searchFind: boolean = false;
  @observable agentHierarchyData: any = [];
  @observable contractId: number = 0;
  @observable dataSource: any;
  @observable gridParams: any;
  @observable isScrollVisible: boolean = false;
  @observable isCancelEditReportingConfirmation: boolean = false;
  @observable isUplineUpdateDisabled: boolean = true;
  @observable agentName: string = "";
  @observable gridApiNoRows: string = "";
  @observable callDataLoadAPI: boolean = true;
  @observable searchSucess: boolean = true;

  @action setEditReportingVisibility(value: boolean) {
    this.isCancelEditReportingConfirmation = value;
  }

  @action setEditImmedateUplineVisibility(value: boolean) {
    this.editAgentReportingHierarchy = value;
  }

  @action setReportingHierarchyLevel(value: string) {
    this.selectedReportingHierarchyLevel = value;
    this.isUplineUpdateDisabled = false;
  }

  @action setSelectedImmediateReportingUpline = (value: string) => {
    this.selectedImmediateReportingUpline = value;
    this.selfAsUpline = false;
    this.ErrorValidate();
  };

  @action editReportingHirarchy = async () => {
    this.selectedImmediateReportingUpline = " ";
    this.isUplineUpdateDisabled = true;
    this.ErrorValidate();
    this.selectedReportingHierarchyLevel = "";
    this.editAgentReportingHierarchy = true;
    // this.agentReportingHierarchy = false;
    await this.getReportingHierarchyLevelList();
    await this.getAgentHierarchyDetailsById();
  };

  @action getAgentHierarchyDetailsById = async () => {
    var results = await ProducerStore.agentHierarchyDetailsById(this.agentId);
    if (results && results != null) {
      if (results.selfUpline != undefined) {
        this.selfAsUpline = results.selfUpline;
        //this.setAgentImmediateUplineDisable = results.selfUpline;
      }
      if (results.parentId != undefined)
        this.selectedImmediateReportingUplineId = results.parentId;
      if (results.hierarchyLevelId != undefined) {
        this.selectedReportingHierarchyLevel =
          results.hierarchyLevelId.toString();
        this.selectedReportingHierarchyLevelId = results.hierarchyLevelId;
      } else {
        this.selectedReportingHierarchyLevel = "0";
        this.selectedReportingHierarchyLevelId = 0;
      }
      if (results.parentId != undefined && results.agentName != undefined) {
        let obj = Utils.getObject(results);
        this.setAgentImmediateUpline("" + obj.agentName, obj.parentId);
      }
    }
  };

  @action getAgentHierarchyDetailsBySelfAsUpline = async () => {
    let results = await ProducerStore.getAgentImmediateUpline(
      0,
      0,
      0,
      "Id",
      true,
      this.agentId.toString()
    );
    if (
      results &&
      results.data &&
      results.data[0].agentId &&
      results.data[0].term
    ) {
      this.setAgentImmediateUpline(
        "" + results.data[0].term,
        results.data[0].agentId
      );
    }
  };

  @action CancelReportingHierarchy = async () => {
    this.selectedImmediateReportingUpline = "";
    this.selectedReportingHierarchyLevel = "";
    this.editAgentReportingHierarchy = false;
    this.agentReportingHierarchy = true;
    this.Load();
  };

  @action SubmitReportingHierarchy = async () => {
    if (this.selectedImmediateReportingUplineId == 0)
      this.selectedImmediateReportingUpline = "";

    if (!(await this.ErrorValidate())) {
      var results = await ProducerStore.updateAgentImmediateUpline(
        this.agentId,
        this.selectedImmediateReportingUplineId,
        this.selfAsUpline,
        parseInt(this.selectedReportingHierarchyLevel) == 0
          ? null
          : parseInt(this.selectedReportingHierarchyLevel)
      );
      this.editAgentReportingHierarchy = false;
      this.agentReportingHierarchy = true;
      //this.loadReportHierarchy();
    }
  };

  @action getReportingHierarchyLevelList = async () => {
    this.reportingHierarchyLevelList.values = [];
    try {
      let response = await ProducerStore.agentHierarchyLevelLookup();
      if (response) {
        Utils.mapListItemsToDropdown(
          response,
          this.reportingHierarchyLevelList,
          "Not Applicable",
          "0"
        );
        this.selectedReportingHierarchyLevel = "Not Applicable";
        this.selectedReportingHierarchyLevelId = 0;
      }
    } catch (e) {
      this.reportingHierarchyLevelList.values = [];
    }
  };

  @action setAgentImmediateUpline(name: any, id: number) {
    if (id) {
      this.selectedImmediateReportingUpline = name;
      this.selectedImmediateReportingUplineId = id;
    } else {
      this.selectedImmediateReportingUpline = "";
      this.selectedImmediateReportingUplineId = 0;
    }
  }

  @action fillterAgentImmediateline = async (value: string) => {
    let results = await ProducerStore.getAgentImmediateUpline(
      Utils.getAgentSearchType(value),
      0,
      0,
      "Id",
      true,
      value
    );
    if (results !== null) {
      if (results.data) {
        if (results.data && results.data.length > 0) {
          results.data.splice(0, 0, { agentId: -1 });
          this.immediateReportingUplineList.values = results.data;
        } else {
          this.immediateReportingUplineList.values = [{ agentId: 0 }];
        }
      } else {
        this.immediateReportingUplineList.values = [{ agentId: 0 }];
      }
    } else {
      this.immediateReportingUplineList.values = [{ agentId: 0 }];
    }
  };

  @action contractsSort = async (column: string) => {
    this.sortColumn = column;
    this.sortOrder = !this.sortOrder;
    this.addEditContactListener();
  };

  @action setAgentMarketer = async (agentId: number, value: string) => {
    this.selectedAgentMarketer = parseInt(value);
    !this.isInitialLoad &&
      (await ProducerStore.saveAgentMarketer(
        agentId,
        this.selectedAgentMarketer
      ));
    this.isInitialLoad = false;
  };

  @action setPrincipalAgent(value: string) {
    this.principalAgent = value;
  }
  @action setPrincipalAgentId(value: number) {
    this.principalAgentId = value;
  }
  @computed
  get id(): string {
    if (this.agentSummary) return "" + this.agentSummary.id;
    return "";
  }
  @computed
  get npn(): string {
    if (this.agentSummary && this.agentSummary !== null)
      return "" + (this.agentSummary.npn ? this.agentSummary.npn : "");
    return "";
  }

  @computed
  get ssn(): string {
    if (this.agentSummary && this.agentSummary !== null) {
      return "" + (this.agentSummary.ssn ? this.agentSummary.ssn : "");
    }
    return "";
  }
  @computed
  get tin(): string {
    if (this.agentSummary && this.agentSummary !== null) {
      return "" + (this.agentSummary.tin ? this.agentSummary.tin : "");
    }
    return "";
  }
  @computed
  get gender(): string {
    if (this.agentSummary && this.agentSummary !== null)
      return (
        "" + (this.agentSummary.gender ? this.agentSummary.gender : "Not Set")
      );
    return "";
  }
  @computed
  get website(): string {
    if (this.agentSummary)
      return "" + (this.agentSummary.website ? this.agentSummary.website : "");
    return "";
  }
  @computed
  get websiteRef(): string {
    let site = "";
    if (this.agentSummary) {
      site = "" + (this.agentSummary.website ? this.agentSummary.website : "");
    }
    if (site.length > 0) {
      if (site.includes("http://") || site.includes("https://")) {
        return site;
      } else {
        return "https://" + site;
      }
    }
    return "";
  }
  @computed
  get source(): string {
    if (this.agentSummary)
      return "" + (this.agentSummary.source ? this.agentSummary.source : "");
    return "";
  }
  @computed
  get lhr(): string {
    if (this.agentSummary)
      return "" + (this.agentSummary.lhr ? this.agentSummary.lhr : "N/A");
    return "";
  }
  @computed
  get dba(): string {
    if (this.agentSummary)
      return "" + (this.agentSummary.dba ? this.agentSummary.dba : "");
    return "";
  }
  @computed
  get smr(): string {
    if (this.agentSummary)
      return "" + (this.agentSummary.smr ? this.agentSummary.smr : "N/A");
    return "";
  }
  @computed
  get timeZone(): string {
    if (
      this.agentSummary &&
      this.agentSummary.currentTime &&
      this.agentSummary.currentTime !== null
    ) {
      return "" + this.agentSummary.currentTime;
    }
    return "";
  }
  @observable quickNotes: string = "";
  getSymbol(): symbol {
    return AgentSummaryViewModel.Symbol;
  }

  @action setQuickNotes = (value: string) => {
    this.quickNotes = value;
  };

  @action actionLog = async () => {
    var dto: ActionLogModel = {
      actionId: 1,
      entityId: this.agentId
        ? +this.agentId
        : RouteParamDetails && RouteParamDetails.id
        ? RouteParamDetails.id
        : undefined,
      userId: UserContext.getUserId(),
    };

    await IdentityStore.createActionLog(dto);
    sessionStorage.removeItem("createlog");
    this.isLoadRecentProfiles = true;
  };

  @action setLoadRecentProfiles = () => {
    this.isLoadRecentProfiles = false;
  };

  @action load = async (id: number) => {
    this.agentId = id;
    this.selectedTabIndex = 0;
    this.isInitialLoad = true;
    this.hierarchyFilter = "";
    this.hierarchyData = [];
    this.callDataLoadAPI = true;
    this.selectedPanel = AgentSummary.Agent;
    this.AddEditContactViewModel.setContactListener(this);
    this.AddEditAddressViewModle.setAddressListener(this);
    this.AddEditContactViewModel.setAgentId(id);
    this.selectedTreeNode = undefined;
    this.resetSummary();
    if (
      this.agentId > 0 &&
      sessionStorage.getItem("createlog") &&
      sessionStorage.getItem("createlog") == "true"
    ) {
      this.actionLog();
    } else {
      sessionStorage.removeItem("createlog");
    }
    if (Utils.hasUserPermission(Permission.ViewAgent)) {
      await this.loadAgentSummary();
    }

    this.editAgentReportingHierarchy = false;
    this.agentReportingHierarchy = true;
  };
  @action resetSummary() {
    this.Marketers.values = [];
    this.AgentHierarchy.values = [];
    this.NotesList.values = [];
    this.GroupList.values = [];
    this.selectedRow = undefined;
    this.contactDefault = undefined;
    this.contactCommission = undefined;
    this.agentReferences.values = [];
    this.enableSMS = undefined;
    this.isMobile = undefined;
    this.editAgentReportingHierarchy = false;
    this.agentReportingHierarchy = true;
    this.manageSubscriptionDialog = false;
  }

  @action contactEnableSMS = async () => {
    try {
      await ProducerStore.setEnableSMS(this.selectContactId, !this.enableSMS);
      setTimeout(async () => {
        await this.loadContacts();
        this.isLoading = false;
      }, 700);
      this.selectedRow = undefined;
    } catch (error) {
      this.isLoading = false;
    }
  };

  @action loadAgentProductLines = async () => {
    try {
      this.isMessgeVisible = true;
      var result = await ProductStore.getAgentProductLines(this.agentId);
      if (result && result.agentProductLines) {
        this.ProductLineMatrices.values = result.agentProductLines;
      } else {
        this.ProductLineMatrices.values = [];
      }
      this.isLoading = false;
    } catch (e) {
      this.ProductLineMatrices.values = [];
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  @action setAgentSummaryResponse(result: AgentSummaryModel | null) {
    this.agentSummary = result;
    if (!result) {
      this.resetSummary();
      this.AgentAddressList.values = [];
      this.AgentContacts.values = [];
    }
  }
  @action setIsContactsEnabled = async (value: boolean) => {
    setTimeout(async () => {
      this.isContactsEnabled = value;
      await ProducerStore.dnxNote(this.agentId, value);
    }, 100);
  };

  @action getAgencyReferrence = async () => {
    this.agentReferences.values = [];
    var result = await ProducerStore.getAgencyReference(this.agentId);
    if (result) {
      this.agentReferences.values = result;
    }
  };

  @observable w9: Date | undefined;

  @action loadAgentSummary = async () => {
    try {
      this.isMessgeVisible = false;
      if (this.agentSummary) {
        if (
          this.agentSummary.leadSourceId === 79 &&
          !UserContext.hasPermmission(Permission.ViewPreNeedAgents)
        ) {
          setTimeout(function () {
            routes.agentSearch.replace();
          });
          return;
        }
        if (
          this.agentSummary.isHiddenAgentProfile &&
          !UserContext.hasPermmission(Permission.ViewHiddenAgentProfile)
        ) {
          setTimeout(function () {
            routes.agentSearch.replace();
          });
          return;
        }
        if (!this.agentSummary.isUserCanSeeThisAgent) {
          setTimeout(function () {
            routes.agentSearch.replace();
          });
          return;
        }
        this.isAgency = this.agentSummary.isAgency;
        this.sureLcAgent = this.agentSummary.sureLC;
        this.NotesList.values = this.agentSummary.notes
          ? this.agentSummary.notes
          : [];
        this.w9 = this.agentSummary.w9 || undefined;
        this.agentReferences_label = this.agentSummary.isAgency
          ? "Principal Agents"
          : "Agencies";
        this.Marketers.values = this.agentSummary.marketers
          ? this.agentSummary.marketers
          : [];
        this.GroupList.values = this.agentSummary.agentGroups
          ? this.agentSummary.agentGroups
          : [];
        var agentMarketers = await ProducerStore.getAgentMarketers(
          +this.agentId
        );
        if (agentMarketers) {
          this.AgentMarketerList.values = agentMarketers.agentMarketers
            ? Utils.mapListItem(agentMarketers.agentMarketers)
            : [];
          this.agentMarketersVisible = agentMarketers.agentMarketersVisible
            ? agentMarketers.agentMarketersVisible
            : false;
          this.agentMarketersEnabled = agentMarketers.agentMarketersEnabled
            ? agentMarketers.agentMarketersEnabled
            : false;
          if (
            agentMarketers.agentMarketers &&
            agentMarketers.agentMarketers.length > 0
          ) {
            var selectedAgentMarketer = agentMarketers.agentMarketers.filter(
              (item: any) => {
                return item.selected === true;
              }
            );
            if (selectedAgentMarketer && selectedAgentMarketer.length > 0) {
              await this.setAgentMarketer(
                this.agentSummary.id || 0,
                selectedAgentMarketer[0].value || "0"
              );
            }
          }
        }

        await this.loadAddressbySort();
        this.setDob(this.agentSummary);
        this.setEOExpirationDate(this.agentSummary);
        if (this.agentSummary.isAgency) {
          this.getPrincipalAgents();
        } else {
          this.getAgencies();
        }
        this.setAgentInfo(this.agentSummary);
        this.loadContacts();
        if (Utils.hasUserPermission(Permission.ViewReportingHierarchy)) {
          var result = await ProducerStore.getAgentHierarchy(+this.agentId);
          this.agentSummary.hierarchies = result.agentSummaryHierarchies;
          this.removeHierarchyDuplicates();
        }
      }
    } catch (e) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
      IMASLog.log("exception from store: " + e.value);
    }
  };
  @action setAgentInfo(result: AgentSummaryModel) {
    let displayCity = result.city ? result.city : "";
    let displayState = result.state ? result.state : "";
    let location = "";
    let name = "";
    if (displayCity && displayState)
      location = displayCity + "," + displayState;
    let nickName = result.nickName ? " ('" + result.nickName + "')" : "";
    RouteParamDetails.firstName = result.name ? result.name : "";
    name = "" + result.name + nickName;
    var agentId = result.id || this.agentId;
    name = name + " (" + agentId + ")";
    let status = result.statusText ? result.statusText : "";
    if (result.name) {
      let nameArray = result.name
        .replace("  ", " ")
        .replace("  ", " ")
        .split(" ");
      if (nameArray.length > 1) {
        RouteParamDetails.agentName = nameArray[1] + ", " + nameArray[0];
        this.agentName = nameArray[1] + ", " + nameArray[0];
      } else {
        RouteParamDetails.agentName = name;
        this.agentName = name;
      }
    }
    let enableLogin = result.enableLogin ? result.enableLogin : false;
    let receivePaperStatement = result.receivePaperStatement
      ? result.receivePaperStatement
      : false;

    if (this.listener) {
      this.listener.updateAgentInfo(
        name,
        location,
        status,
        enableLogin,
        receivePaperStatement,
        result.name,
        result.npn
      );
      this.listener.updateRating(
        result.badRatingVisible ? result.badRatingVisible : false,
        result.badRatingText ? result.badRatingText : ""
      );
    }
  }
  @action setReceivePaperStatement(value: boolean) {
    if (this.agentSummary) {
      this.agentSummary.receivePaperStatement = value;
    }
  }
  @action setDob(agentSummary: AgentSummaryModel) {
    let dateOfB = Utils.getDateInFormat(agentSummary.dob);
    if (dateOfB && dateOfB.length > 0) {
      this.dob =
        "" +
        (Utils.getDateInFormat(agentSummary.dob) + this.getAge(agentSummary));
    } else {
      this.dob = "";
    }
  }
  @action setEOExpirationDate(agentSummary: AgentSummaryModel) {
    let dateOfEO = Utils.getDateInFormat(agentSummary.eoExpirationDate);
    if (dateOfEO && dateOfEO.length > 0) {
      this.eoExpirationDate =
        "" + Utils.getDateInFormat(agentSummary.eoExpirationDate);
    } else {
      this.eoExpirationDate = "";
    }
  }
  @action loadAddressbySort = async () => {
    try {
      this.isMessgeVisible = false;
      this.AgentAddressList.values = [];
      var result = await ProducerStore.getAddresses(this.agentId);
      if (result && result.data) {
        this.sortAddress(result.data);
      } else {
        this.AgentAddressList.values = [];
      }
      this.isLoading = false;
    } catch (e) {
      this.AgentAddressList.values = [];
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  @action sortAddress(data: AddressListModel[]) {
    var sortData = [...data].sort(this.compareAddress);
    this.AgentAddressList.values = sortData;
  }
  @action sortContacts(data: ContactModel[]) {
    var sortData = [...data].sort(this.compareContact);
    this.AgentContacts.values = sortData;
  }
  compareAddress = (a: AddressListModel, b: AddressListModel) => {
    const nameA = a.isCommission || a.isDefault || a.isDefault ? "a" : "b";
    const nameB = b.isCommission || b.isDefault || b.isDefault ? "a" : "b";

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action getAgencies = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      this.noPrincipalAgentsFound();
      var result = await ProducerStore.getAgencies(this.agentId, 0, 100);
      if (result && result !== null) {
        if (result.recordCount) {
          if (result.data) this.PrincipalAgentsData.values = result.data;
        } else {
          this.noPrincipalAgentsFound();
        }
      } else {
        this.noPrincipalAgentsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noPrincipalAgentsFound();
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = error.response;
    }
  };
  @action getPrincipalAgents = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      this.noPrincipalAgentsFound();
      var result = await ProducerStore.getPrincipalAgents(this.agentId, 0, 100);
      if (result && result !== null) {
        if (result.recordCount) {
          if (result.data) this.PrincipalAgentsData.values = result.data;
        } else {
          this.noPrincipalAgentsFound();
        }
      } else {
        this.noPrincipalAgentsFound();
      }
      this.isLoading = false;
    } catch (error) {
      this.noPrincipalAgentsFound();
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = error.response;
    }
  };
  noPrincipalAgentsFound() {
    this.PrincipalAgentsData.values = [];
  }
  @action searchPrincipalAgents = async (value: string) => {
    IMASLog.log("search agents ..");
    try {
      var element = document.querySelector(".p-autocomplete-panel");

      let result = await ProducerStore.getPriniciplaAgentsLookup(value);
      if (result !== null) {
        if (result) {
          if (result.principalModelLists) {
            this.PrincipalAgentsList.values = result.principalModelLists;
            setTimeout(() => {
              if (element) {
                element.scrollTo(0, 0);
              }
            }, 10);
          } else this.noPrincipalAgents();
        } else {
          this.noPrincipalAgents();
        }
      } else {
        this.noPrincipalAgents();
      }
    } catch (e) {
      this.noPrincipalAgents();
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  @action noPrincipalAgents() {
    var noAgent: AgentListModel = { id: 0 };
    this.PrincipalAgentsList.values = [noAgent];
  }
  @action addPrincipalAgent = async () => {
    this.isLoading = true;
    try {
      this.isMessgeVisible = false;
      var dto: CreatePrincipalModel = {
        agentId: this.agentId,
        principalAgentId: this.principalAgentId,
      };
      var result = await ProducerStore.addPrincipalAgent(this.agentId, dto);
      setTimeout(async () => {
        this.setAddPrincipalAgentVisibility(false);
        this.setPrincipalAgent("");
        this.setPrincipalAgentId(0);
        await this.getPrincipalAgents();
        this.isLoading = false;
        return result;
      }, Utils.timeDelay_Success());
    } catch (error: any) {
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = error.response;
      return 0;
    }
  };

  @action loadReportHierarchy = async () => {
    try {
      this.isLoading = true;
      var result = await ProducerStore.getAgentReportingHierarchy(this.agentId);

      if (result && result) {
        this.hierarchyData = [];
        this.hierarchyData.push(result.hierarchy);
        window.addEventListener("scroll", this.listenScrollEvent);
        window.scrollTo(1, 1);
        //await this.expandTreeNode(this.hierarchyData);
      } else {
      }
      this.isLoading = false;
    } catch (e) {}
  };

  @action setSelectedTreeNode = async (value: any) => {
    this.selectedTreeNode = value;
    this.findNodeById(this.hierarchyData, value);
  };

  @action findNodeById = (nodes: any, id: any) => {
    let res;
    let findNode = (nodes: any, id: any) => {
      nodes.length > 0 && (nodes[0].selected = false);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].selected = false;
        if (nodes[i].key == id) {
          nodes[i].selected = true;
          res = nodes[i];
        }
        if (nodes[i].children) {
          findNode(nodes[i].children, id);
        }
      }
    };
    findNode(nodes, id);
    return res;
  };
  @action expandTreeNode = async (nodes: any) => {
    const _expandedKeys = {};
    for (const node of nodes) {
      await this.expandNode(node, _expandedKeys);
    }
    this.expandedNodes = "";
    this.expandedNodes = _expandedKeys;
  };

  @action expandNode = async (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (const child of node.children) {
        this.expandNode(child, _expandedKeys);
      }
    }
  };

  @action onExpand(node: any) {}

  @action onCollapse(node: any) {}

  @action loadContacts = async () => {
    try {
      this.isLoading = true;
      this.isMessgeVisible = false;
      // this.AgentContacts.values = [];
      var result = await ProducerStore.getContacts(
        this.agentId,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sortOrder,
        ""
      );
      if (result) {
        this.isDnx = result.isDNxOverride ? result.isDNxOverride : false;
        this.showPhoneManageSubscriptions = result.showPhoneTab || false;
        this.showEmailManageSubscriptions = result.showEmailTab || false;
        this.isContactsEnabled = !this.isDnx;
      }
      if (result && result.data) {
        this.AgentContacts.values = [];
        this.sortContacts(result.data);
      } else {
        this.AgentContacts.values = [];
      }
      this.isLoading = false;
    } catch (e) {
      this.AgentContacts.values = [];
      this.isLoading = false;
      this.isMessgeVisible = true;
      this.response = e.response;
    }
  };
  compareContact = (a: ContactModel, b: ContactModel) => {
    const nameA = a.default ? "a" : "b";
    const nameB = b.default ? "a" : "b";

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  removeHierarchyDuplicates() {
    if (this.agentSummary && this.agentSummary.hierarchies) {
      this.AgentHierarchy.values = [
        ...new Map(
          this.agentSummary.hierarchies.map((o) => [o.agentId, o])
        ).values(),
      ];
    } else {
      this.AgentHierarchy.values = [];
    }
  }

  @action seDefaultDialogOpen(value: boolean) {
    this.isDefaultDialogOpen = value;
  }

  @observable contactType: string = "";

  @action onRowClick = async (data: any) => {
    this.selectedRow = data;
    this.contactDefault = data.default;
    this.contactCommission = data.commission;
    this.selectContactId = data.id;
    this.contactType = data.contactType;
    this.enableSMS = data.enableSMS;
    this.isMobile = data.phoneType === PhoneType.Mobile;
  };
  @action onRowUnselect = async (data: any, e: any) => {
    this.selectedRow = null;
  };

  @action setContactDefault = async () => {
    if (this.contactDefault) {
      this.unSetContactAsDefault("default");
    } else {
      this.setContactAsDefault();
    }
    this.selectedRow = undefined;
  };

  @action setContactCommission = async () => {
    await this.setContactAsCommission();
    this.selectedRow = undefined;
  };

  @action getContactDetails = async () => {
    try {
      return await ProducerStore.getContactDetails(
        this.selectContactId,
        this.agentId
      );
    } catch (error) {
      this.isLoading = false;
    }
  };

  getContactTypeId = (value: string) => {
    switch (value) {
      case "Email":
        return 1;
      case "Text Message":
        return 2;
      case "Phone Number":
        return 3;
      case "Alternative Point of Contact":
        return 4;
      default:
        return 3;
    }
  };

  @action unSetContactAsDefault = async (type: string) => {
    if (type === "default") {
      try {
        await ProducerStore.undefaultContact(
          this.selectContactId,
          this.agentId,
          this.getContactTypeId(this.contactType ? this.contactType : "")
        );
        setTimeout(async () => {
          await this.loadContacts();
          this.isLoading = false;
        }, 700);
      } catch (error) {
        this.isLoading = false;
      }
    } else {
      try {
        var details = await this.getContactDetails();
        if (details) {
          var contact: CreateUpdateContactModel = {
            id: this.selectContactId,
            description: details.description,
            contactValue: details.contactValue,
            contactTypeId: this.getContactTypeId(
              details.contactType ? details.contactType : ""
            ),
            agentId: this.agentId,
          };
          await ProducerStore.editContact(this.agentId, contact);
          setTimeout(async () => {
            await this.loadContacts();
            this.isLoading = false;
          }, 700);
        }
      } catch (error) {
        this.isLoading = false;
      }
    }
  };

  @action setContactAsDefault = async () => {
    try {
      await ProducerStore.defaultContact(
        this.selectContactId,
        this.agentId,
        this.getContactTypeId(this.contactType ? this.contactType : "")
      );
      setTimeout(async () => {
        await this.loadContacts();
        this.isLoading = false;
      }, 700);
    } catch (error) {
      this.isLoading = false;
    }
  };
  @action setContactAsCommission = async () => {
    try {
      await ProducerStore.commissionContact(
        this.selectContactId,
        this.agentId,
        this.getContactTypeId(this.contactType ? this.contactType : "")
      );
      setTimeout(async () => {
        await this.loadContacts();
        this.isLoading = false;
      }, 700);
    } catch (error) {
      this.isLoading = false;
    }
  };

  @action seContactsEnableDialogOpen(value: boolean) {
    this.isContatsEnableDilaogoOpen = value;
  }

  @action setSpanClickvalue(value: boolean) {
    this.isSpanClicked = value;
  }

  @action setLinksEnbleOption(value: boolean) {
    this.contactLinksEnabled = value;
  }

  @action setDefaultDialogContent(value: boolean) {
    this.defaultDialogContent = value ? "set as Not Default" : "set as Default";
  }

  @action getContactValue = (contact: ContactModel) => {
    if (contact.contactType === "Phone Number") {
      return this.getPhoneNumberUSformat("" + contact.contactValue);
    } else return "" + contact.contactValue;
  };

  @action getContactType = (contact: ContactModel) => {
    return "" + contact.contactType;
  };

  @action getCategoryType = (contact: ContactModel) => {
    return "" + contact.contactType === "Email"
      ? contact.emailType
      : contact.contactType === "Phone Number" && contact.phoneType
      ? contact.phoneType.replace("Others", "Other")
      : contact.alternativePointOfContactType;
  };

  @action getEnableSMS = (contact: ContactModel) => {
    return contact.enableSMS;
  };

  @action getContactHref = (contact: ContactModel) => {
    if (contact.contactType === "Email") {
      return "mailto:" + contact.contactValue + "?Subject=Integrity%20Connect";
    } else return "" + contact.contactType;
  };

  @action dialerLog = async (number: any) => {
    var dto: NoteModel = {
      note: `Called ${number} from the agent contacts`,
      agentNoteTypeId: 1,
      private: false,
      dateTime: new Date(),
      companyId: 0,
      title: "",
      documentAttachmentList: undefined,
    };
    await ProducerStore.addNote(this.agentId, dto);
  };

  @action getContactNumber = (contact: ContactModel) => {
    this.dialerLog(contact.contactValue);
    window.open("rcapp://r/call?number=" + contact.contactValue, "_parent");
  };
  @action getMapHref = (address: AddressListModel) => {
    return (
      "https://maps.google.com/maps?q=" +
      address.latitude +
      "," +
      address.longitude +
      "&ie=UTF-8"
    );
  };
  @action getProductLineValue = (
    productLine: AgentProductLines,
    column: string
  ) => {
    switch (column) {
      case "Contracted":
        return productLine.contracted;
      case "Active":
        return productLine.active;
      case "Referred":
        return productLine.referred;
      default:
        return false;
    }
  };

  getAddressList() {
    return this.AgentAddressList.values;
  }
  getAge = (summary: AgentSummaryModel) => {
    return summary.age ? " (" + summary.age + ")" : "";
  };

  @action getPhoneNumberUSformat = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumber;
  };

  @action updateExpnadedValue(value: string | boolean) {
    this.expanded = value;
  }
  @action setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
  }
  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.isLoading = false;
    this.isMessgeVisible = false;
    this.setSelectedPanel(key);
    this.loadTabsData(key);
    this.isMessgeVisible = false;
  };
  @action loadTabsData = async (tab: string) => {
    switch (tab) {
      case AgentSummary.PrincipalAgents:
        await this.getPrincipalAgents();
        break;
      case AgentSummary.ProductLines:
        await this.loadAgentProductLines();
        break;
      case AgentSummary.Addresses:
        await this.loadAddressbySort();
        break;
      case AgentSummary.ContactOptions:
        await this.loadContacts();
        break;
      case AgentSummary.ReportingHierarchy:
        //await this.loadReportHierarchy();
        break;
      case AgentSummary.MedicareCenter:
        //await this.loadReportHierarchy();
        break;
    }
  };
  @observable agencyTabItems = [
    { label: AgentSummary.Agent, value: 0 },
    { label: AgentSummary.ContactOptions, value: 1 },
    { label: AgentSummary.Addresses, value: 2 },
    { label: AgentSummary.QuickNotes, value: 3 },
    { label: AgentSummary.ProductLines, value: 4 },
    { label: AgentSummary.PrincipalAgents, value: 5 },
    { label: AgentSummary.ReportingHierarchy, value: 6 },
    { label: AgentSummary.MedicareCenter, value: 7 },
  ];
  @observable nonAgencyTabItems = [
    { label: AgentSummary.Agent, value: 0 },
    { label: AgentSummary.ProductLines, value: 1 },
    { label: AgentSummary.ReportingHierarchy, value: 6 },
    { label: AgentSummary.MedicareCenter, value: 7 },
  ];

  @observable principalAgentTitle: string = "Add Principal Agent";
  @observable isAddPrincipalAgentVisible: boolean = false;
  @action setAddPrincipalAgentVisibility = async (value: boolean) => {
      this.isAddPrincipalAgentVisible = value;

      if(value==false)
      this.principalAgent="";
  };
  @observable contactDialogTitle: string = "";
  @observable isAddContacDialogtVisible: boolean = false;
  @action setAddContactDialogVisibility = async (
    value: boolean,
    isEdit: boolean | undefined
  ) => {
    if (value) {
      this.AddEditContactViewModel.showDefaultOverrideMessage = false;
      if (isEdit) {
        this.contactDialogTitle = "Edit Contact";
      } else {
        this.contactDialogTitle = "Add Contact";
        this.AddEditContactViewModel.ResetValidateContact();
        this.AddEditContactViewModel.setContactDescription("");
        this.AddEditContactViewModel.setContactType("1");
        this.AddEditContactViewModel.setContactVale("");
        this.AddEditContactViewModel.setDefaultContact(false);
        this.AddEditContactViewModel.setCommisionContact(false);
        this.AddEditContactViewModel.setSMS(false);
        this.AddEditContactViewModel.loadLookups();
        this.AddEditContactViewModel.setphoneType(
          PhoneTypeValue.Other.toString()
        );
        this.AddEditContactViewModel.isMessgeVisible = false;
        this.AddEditContactViewModel.setEmailType(
          EmailTypeValue.Work.toString()
        );
        this.AddEditContactViewModel.setAlternativePOCType(
          AlternativePointOfContactTypeValue.Primary.toString()
        );
      }
    }
    this.checkIsAnyDefault(this.AddEditContactViewModel.contactType);
    this.isAddContacDialogtVisible = value;
  };

  @action checkIsAnyDefault = (contactType: string) => {
    let defaultRow = this.AgentContacts.values.filter((i) => {
      return (
        i.default &&
        i.contactTypeId + "" == contactType &&
        i.id != this.selectContactId
      );
    });
    let anyDefault = defaultRow.length > 0;
    this.AddEditContactViewModel.showDefaultOverrideMessage = anyDefault;
  };

  addEditContactListener(): void {
    setTimeout(() => {
      this.loadContacts();
      this.isMessgeVisible = false;
    }, Utils.timeDelay_Success());
  }

  cancelAddEditContact(): void {
    this.setAddContactDialogVisibility(false, false);
  }

  @observable addrDialogTitle: string = "";

  async updateAddress () {
    await this.addEditAddress();
  }
  updateDefaultStateOnHeader(): void {
    if (this.listener) {
      this.listener.updateDefaultStateOnHeaderInfo();
    }
  }
  @computed
  get isAddressDialogVisible(): boolean {
    return this.AddEditAddressViewModle.isDialogVisible;
  }
  @action setAddressDialogVisibility = async (value: boolean) => {
    this.addrDialogTitle = "Add Address";
    this.AddEditAddressViewModle.isValidAddress = false;
    this.AddEditAddressViewModle.setAddress("");
    this.AddEditAddressViewModle.setAddressName("1", false, "");
    this.AddEditAddressViewModle.setCustomAddrName("");
    this.AddEditAddressViewModle.setBusinessName("");
    this.AddEditAddressViewModle.setAttn("");
    this.AddEditAddressViewModle.setVisibleInDownline(false);
    this.AddEditAddressViewModle.setDefault(false);
    this.AddEditAddressViewModle.setCommission(false);
    this.AddEditAddressViewModle.setResidential(false);
    this.AddEditAddressViewModle.setDialogVisibility(value);
    this.AddEditAddressViewModle.isValidAddress = false;
    this.AddEditAddressViewModle.showOverrideAddress = false;
  };
  @action addEditAddress = async () => { 
      this.isLoading = true;
      this.isMessgeVisible = false;
      var adrName =
        this.AddEditAddressViewModle.addressName === "Custom"
          ? this.AddEditAddressViewModle.customAddrName
          : this.AddEditAddressViewModle.addressName;
      var model: CreateUpdateAddressModel = {
        address: this.AddEditAddressViewModle.address,
        addressName: adrName,
        bussinessName: this.AddEditAddressViewModle.businessName,
        attn: this.AddEditAddressViewModle.attn,
        addressNote: "",
        isVisibleInDownline: this.AddEditAddressViewModle.isVisibleInDownline,
        agentId: this.agentId,
        isHome: this.AddEditAddressViewModle.isResidential,
        isDefault: this.AddEditAddressViewModle.isDefault,
        isCommission: this.AddEditAddressViewModle.isCommission,
        addressType: +this.AddEditAddressViewModle.addressType,
      };
      var validAddressModel = this.AddEditAddressViewModle.validAddressModel;
      if (
        this.AddEditAddressViewModle.showOverrideAddress &&
        Utils.hasUserPermission(Permission.OverrideAddressValidation)
      ) {
        model.stateAbbreviation = this.AddEditAddressViewModle.state;
        model.zipCode = this.AddEditAddressViewModle.zip;
        model.city = this.AddEditAddressViewModle.city;
        model.countyName = this.AddEditAddressViewModle.county;
        model.addressLine1 = this.AddEditAddressViewModle.addressLine;
        model.isValid = false;
      } else if (validAddressModel) {
        model.countyName = validAddressModel.countyName;
        model.stateAbbreviation = validAddressModel.state;
        model.addressLine1 = validAddressModel.street;
        model.zipCode = validAddressModel.zipCode;
        model.lattitude = validAddressModel.latitude;
        model.longitude = validAddressModel.longitude;
        model.city = validAddressModel.city;
        model.isValid = true;
        model.addressComments = validAddressModel.addressComments;
        model.dpvFootnotes = validAddressModel.dpvFootnotes;
        model.dpvMatchCode = validAddressModel.dpvMatchCode;
        model.suitelinkMatch = validAddressModel.suitelinkMatch;
        model.lacslinkCode = validAddressModel.lacslinkCode;
        model.lacslinkIndicator = validAddressModel.lacslinkIndicator;
        model.footnotes = validAddressModel.footnotes;
        model.ewsMatch = validAddressModel.ewsMatch;
        model.active = validAddressModel.active;
        model.dpvVacant = validAddressModel.dpvVacant;
        model.dpvCmra = validAddressModel.dpvCmra;
        model.location = validAddressModel.location;
        model.precision = validAddressModel.precision;
        model.elotSort = validAddressModel.elotSort;
        model.elotSequence = validAddressModel.elotSequence;
        model.rdi = validAddressModel.rdi;
        model.buildingDefaultIndicator =
          validAddressModel.buildingDefaultIndicator;
        model.congressionalDistrict = validAddressModel.congressionalDistrict;
        model.carrierRoute = validAddressModel.carrierRoute;
        model.countyFips = validAddressModel.countyFips;
        model.zipType = validAddressModel.zipType;
        model.recordType = validAddressModel.recordType;
        model.deliveryPointBarcode = validAddressModel.deliveryPointBarcode;
        model.deliveryPoint = validAddressModel.deliveryPoint;
        model.deliveryPointCheckDigit =
          validAddressModel.deliveryPointCheckDigit;
        model.plusFourCode = validAddressModel.plusFourCode;
        model.defaultCityName = validAddressModel.defaultCityName;
        model.cityName = validAddressModel.cityName;
        model.secondaryDesignator = validAddressModel.secondaryDesignator;
        model.secondaryNumber = validAddressModel.secondaryNumber;
        model.streetSuffix = validAddressModel.streetSuffix;
        model.streetPostdirection = validAddressModel.streetPostdirection;
        model.streetPredirection = validAddressModel.streetPredirection;
        model.streetName = validAddressModel.streetName;
        model.primaryNumber = validAddressModel.primaryNumber;
        model.firmName = validAddressModel.firmName;
      }

      if (this.addrDialogTitle === "Add Address") {
        await ProducerStore.addAddresses(this.agentId, model);
      }

      this.loadAddressbySort();
      var result = await ProducerStore.getAgentCurrentTime(this.agentId);
      if (result && this.agentSummary) {
        if (result.currentTime !== null) {
          this.agentSummary.currentTime = "" + result.currentTime;
        } else {
          this.agentSummary.currentTime = "";
        }
      }
      this.isMessgeVisible = false;
      this.isLoading = false;
    
  };

  @action showManageSubscription = (value: boolean) => {
    this.manageSubscriptionDialog = value;
    this.ManageSubscriptionViewModel.Load(
      this.agentId,
      this.showPhoneManageSubscriptions,
      this.showEmailManageSubscriptions
    );
  };

  @action hideManageSubscription = () => {
    this.manageSubscriptionDialog = false;
    this.loadContacts();
  };

  @action visibleY = (el: any) => {
    if (el) {
      var rect = (el && el.getBoundingClientRect()) || null,
        top = rect.top,
        height = rect.height,
        el = el.parentNode;
      return top <= document.documentElement.clientHeight;
    } else {
      return false;
    }
  };

  @action listenScrollEvent = () => {
    this.attachEvent(document.getElementById("root"), "scroll", this.update());
    this.attachEvent(window, "resize", this.update());
    this.update();
  };

  @action attachEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callbackFunction);
    }
  };

  @action update = () => {
    this.isScrollVisible = this.visibleY(
      document.getElementById("pleaseignoreanddonotdeleteit2")
    );
  };

  @observable timer: any;
  @action scrollLoader = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };
}

export interface UpdateAgentListener {
  updateAgentInfo(
    name: string,
    location: string,
    status: string,
    enableLogin: boolean,
    receivePaperStatement: boolean,
    nameForSMSTitle: string,
    npn: string
  ): void;
  updateAgentDebtInfo(
    isAgentOwes: boolean,
    debtLevel: string,
    debtDescription: string
  ): void;
  updateRating(badRating: boolean, message: string): void;
  updateDefaultStateOnHeaderInfo(): void;
}

class SummaryValidator extends AbstractValidator<AgentSummaryViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.selectedImmediateReportingUpline)
      .isNotEmpty()
      .withFailureMessage(
        "Select an Immediate Upline Value or enable 'Self as Upline'."
      );
  }
}
