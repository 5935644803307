import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";
import InsuredStore from "../../store/InsuredStore";
import {
  PolicyNoteModel,
  PaymentPlanModel,
  StatusModel,
  CreateUpdatePolicyNoteModel,
  PolicyByIdResponseModel,
} from "../../services/InsuredService";
import { routes } from "../../router";
import PolicyNotesValidator from "./policies/PolicyNotesValidator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { RenewalHistoryViewModel } from "./policies/RenewalHistoryViewModel";
import { PolicyTabs } from "../../infrastructure/enum/PolicyDetails";
import { Permission } from "../../infrastructure/enum/Permission";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";

export class PolicyDetailsViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @observable sortColumn: string | undefined = "Id";
  @observable sortOrder: boolean = false;
  @observable pageIndex: number = 0;
  @observable totalRecords: number = 0;
  @observable rows: number = 10;
  @observable first: number = 0;
  @observable profileId: number | undefined;
  @observable licenseId: number = 0;
  @observable agentId: number | undefined;
  @observable agentName: string = "";
  @observable locationPremierAgent: string = "";
  @observable customerName: string = "";
  @observable customerId: number | undefined;
  @observable isNoPermission: boolean = false;
  @observable pcp: string = "";
  @observable isReplaceNoteConfirm: boolean = false;
  @observable selectedNoteId: number | undefined;
  @observable selectedPolicyId: number | undefined;
  @observable selectedmarkAsRevenueNote: boolean = false;
  @observable noteConfirmationMeassage: string =
    "You can view only one note on the Revenue Dashboard. Do you wish to replace the previous enabled note?";
  @action Load = async (policyId: number, tabIndex: number) => {
    this.isNoPermission = false;
    this.policyId = policyId;
    if (tabIndex == 1) {
      this.selectedPanel = PolicyTabs.RenewalHistory;
      this.selectedTabIndex = 1;
      this.renewalHistoryViewModel.Load(this.policyId);
    } else {
      this.selectedPanel = PolicyTabs.Summary;
      this.selectedTabIndex = 0;
      await this.loadPolicyDetails();
      await this.loadPolicyNotes(this.policyId);
    }
  };

  @action reset = () => {
    this.policyDetails.customer = "";
    this.policyDetails.agent = "";
    this.policyDetails.licenseNumber = "";
    this.policyDetails.marketer = "";
    this.policyDetails.division = "";
    this.policyDetails.company = "";
    this.policyDetails.product = "";
    this.policyDetails.plan = "";
    this.policyDetails.payTo = "";
    this.policyDetails.locationPremierAgent = "";
    this.policyDetails.pcp = "";
    this.policyDetails.status = "";
    this.policyDetails.dateSold = "";
    this.policyDetails.datePosted = "";
    this.policyDetails.pendingInfo = "";
    this.policyDetails.replacedDate = "";
    this.policyDetails.dateSurrender = "";
    this.policyDetails.dateEntered = "";
    this.policyDetails.dateEffective = "";
    this.policyDetails.originalDateEffective = "";
    this.policyDetails.customerAge = "";
    this.policyDetails.issueAge = "";
    this.policyDetails.premium = "";
    this.policyDetails.mode = "";
    this.policyDetails.cashWithApp = "";
    this.policyDetails.excessAmount = "";
    this.policyDetails.annuityAmount = "";
    this.policyDetails.spLife = "";
    this.policyDetails.deathBenefit = "";
    this.policyDetails.annualPremium = "";
    this.policyDetails.appliedRating = "";
    this.policyDetails.approvedRating = "";
    this.policyDetails.targetPremium = "";
  };

  @action loadPolicyDetails = async () => {
    if (this.policyId) {
      this.isLoading = true;
      this.policyDetails=null;
      // this.reset()
      this.policyDetails = await InsuredStore.getPolicyDetails(this.policyId);
      if (this.policyDetails) {
        this.profileId = this.policyDetails.profileId;
        this.agentId = this.policyDetails.agentId;
        this.customerId = this.policyDetails.customerId;
        this.locationPremierAgent = this.policyDetails.locationPremierAgent;

        this.agentName =
          "Return " +
          (this.policyDetails.agent ? this.policyDetails.agent : "");
        this.customerName =
          "Return " +
          (this.policyDetails.customer ? this.policyDetails.customer : "");
        this.licenseId = this.policyDetails.licenseId
          ? this.policyDetails.licenseId
          : 0;
        this.policyNumberTitle =
          "Policy: " +
          (this.policyDetails.policy ? "" + this.policyDetails.policy : "");
        this.pcp = this.policyDetails.pcp || "";
      }
    }

    this.isLoading = false;
  };
  @action setReplaceNoteConfirmation(value: boolean) {
    this.isReplaceNoteConfirm = value;
  }
  @action pinUnpinNote = async () => {
    await InsuredStore.pinUnpinNote(
      this.selectedNoteId != undefined ? this.selectedNoteId : 0,
      this.selectedmarkAsRevenueNote,
      this.selectedPolicyId != undefined ? this.selectedPolicyId : 0
    );

    this.sortColumn = "noteDate";
    this.sortOrder = false;
    this.pageIndex = 0;
    this.rows = 10;
    //this.selectedType = "0";
    setTimeout(() => {
      this.loadPolicyNotes(
        this.selectedPolicyId != undefined ? this.selectedPolicyId : 0
      );
    }, 300);
  };
  @action returnToAgent() {
    if (this.agentId) {
      routes.agentDetails.push({ agentId: "" + this.agentId });
    }
  }

  @action returnToCustomer() {
    if (this.customerId) {
      routes.customerDetails.push({ customerId: "" + this.customerId });
    }
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable isLoading: boolean = true;
  @observable policyId: number | undefined = undefined;

  @observable type = [
    { label: "All", value: "0" },
    { label: "General Comment", value: "1" },
    { label: "Underwriting", value: "2" },
  ];

  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }

  @action onPagePolicyNotes = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.rows = rows;
    this.pageIndex = firstIndex / this.rows;
    if (this.policyId) await this.loadPolicyNotes(this.policyId);
  };

  @observable selectedType: string = "0";
  @action policyNotes = async (column: string) => {
    this.sortColumn = column;
    this.sortOrder = !this.sortOrder;
    if (this.policyId) this.loadPolicyNotes(this.policyId);
  };

  private async loadPolicyNotes(policyId: number) {
    try {
      this.isLoading = true;
      this.noPolicyNotes();
      this.sortColumn = this.sortColumn;
      this.sortOrder = this.sortOrder;
      var notesResult = await InsuredStore.getPoliciyNotes(
        policyId,
        +this.selectedType,
        this.pageIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (notesResult && notesResult.data) {
        this.totalRecords = notesResult.recordCount
          ? notesResult.recordCount
          : 0;
        this.NotesList.values = notesResult.data;
        this.NotesData.values = notesResult.data;
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.noPolicyNotes();
    }
  }
  @action noPolicyNotes() {
    this.totalRecords = 0;
    this.NotesList.values = [];
    this.NotesData.values = [];
  }

  @action goToLicenseTab() {
    if (this.licenseId)
      routes.contractDetails.push({
        agentId: "" + this.agentId,
        contractId: this.licenseId,
      });
  }
  @action goToPolicies() {
    let url = window.location.pathname.split("/");
    if (url && url[1] === "customers") {
      routes.customerPolicies.push({ customerId: url[2] });
    } else if (this.licenseId)
      routes.agentPolicies.push({
        agentId: "" + this.agentId,
      });
  }
  @computed
  get customer(): string {
    if (this.policyDetails && this.policyDetails.customer)
      return this.policyDetails.customer;
    return "";
  }

  @computed
  get mapHref(): string {
    return "";
  }
  @computed
  get isLocationAvailable(): boolean {
    if (this.mapHref.startsWith("https://maps.google.com/maps")) return true;
    return false;
  }
  @computed
  get agent(): string {
    if (this.policyDetails && this.policyDetails.agent)
      return this.policyDetails.agent;
    return "";
  }
  @computed
  get company(): string {
    if (this.policyDetails && this.policyDetails.company)
      return this.policyDetails.company;
    return "";
  }
  @computed
  get product(): string {
    if (this.policyDetails && this.policyDetails.product)
      return this.policyDetails.product;
    return "";
  }
  @computed
  get plan(): string {
    if (this.policyDetails && this.policyDetails.plan)
      return this.policyDetails.plan;
    return "";
  }
  @computed
  get license(): string {
    if (this.policyDetails && this.policyDetails.licenseNumber)
      return this.policyDetails.licenseNumber;
    return "";
  }
  @computed
  get manager(): string {
    if (this.policyDetails && this.policyDetails.marketer)
      return this.policyDetails.marketer;
    return "";
  }

  @computed
  get payTo(): string {
    if (this.policyDetails && this.policyDetails.payTo)
      return this.policyDetails.payTo;
    return "";
  }

  @computed
  get location(): string {
    if (this.isLocationAvailable) return "";
    return "None";
  }
  @computed
  get status(): string {
    if (this.policyDetails && this.policyDetails.status)
      return this.policyDetails.status;
    return "";
  }
  @computed
  get datePosted(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.datePosted);
    return "";
  }
  @computed
  get dateSold(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.dateSold);
    return "";
  }
  @computed
  get effective(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.dateEffective);
    return "";
  }
  @computed
  get entered(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.dateEntered);
    return "";
  }
  @computed
  get originalEffective(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.originalDateEffective);
    return "";
  }
  @computed
  get pendingInfo(): string {
    if (this.policyDetails && this.policyDetails.pendingInfo)
      return this.policyDetails.pendingInfo;
    return "";
  }
  @computed
  get endDate(): string {
    // dateSurrender
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.dateSurrender);
    return "";
  }
  @observable policyDetails: PolicyByIdResponseModel | null = null;
  // @observable policyProductByProfileResult: ProductByProfileModel | null = null;
  @observable policyPaymentPlan: PaymentPlanModel | null = null;
  @observable policyStatusModel: StatusModel | null = null;
  @observable NotesList = new Collection<PolicyNoteModel>();
  @observable NotesData = new Collection<PolicyNoteModel>();
  @observable policyNumberTitle: string = "";

  @computed
  get division(): string {
    if (this.policyDetails && this.policyDetails.division)
      return this.policyDetails.division;
    return "";
  }

  @computed
  get todaysAge(): string {
    if (this.policyDetails && this.policyDetails.customerAge)
      return this.policyDetails.customerAge;
    return "";
  }
  @computed
  get issueAge(): string {
    if (this.policyDetails && this.policyDetails.issueAge)
      return "" + this.policyDetails.issueAge;
    return "";
  }
  @computed
  get cashWithApp(): string {
    if (this.policyDetails && this.policyDetails.cashWithApp)
      return "$" + this.policyDetails.cashWithApp;
    return "";
  }

  @computed
  get premium(): string {
    if (this.policyDetails && this.policyDetails.premium)
      return "$" + this.policyDetails.premium;
    return "";
  }
  @computed
  get targetPrem(): string {
    if (this.policyDetails && this.policyDetails.targetPremium)
      return Utils.getCurrencyFormat(this.policyDetails.targetPremium);
    return "$0.00";
  }
  @computed
  get excessPrem(): string {
    if (this.policyDetails && this.policyDetails.excessAmount)
      return Utils.getCurrencyFormat(this.policyDetails.excessAmount);
    return "$0.00";
  }

  @computed
  get annuityPrem(): string {
    if (this.policyDetails && this.policyDetails.annuityAmount)
      return Utils.getCurrencyFormat(this.policyDetails.annuityAmount);
    return "$0.00";
  }
  @computed
  get slPrem(): string {
    if (this.policyDetails && this.policyDetails.spLife)
      return Utils.getCurrencyFormat(this.policyDetails.spLife);
    return "$0.00";
  }
  @computed
  get deathBenefit(): string {
    if (this.policyDetails && this.policyDetails.deathBenefit)
      return Utils.getCurrencyFormat(this.policyDetails.deathBenefit);
    return "$0.00";
  }
  @computed
  get mode(): string {
    if (this.policyDetails && this.policyDetails.mode)
      return this.policyDetails.mode;
    return "";
  }
  @computed
  get sellingAgent(): string {
    if (this.policyDetails && this.policyDetails.agentPercent)
      return this.policyDetails.agentPercent + "%";
    return "";
  }
  @computed
  get agency(): string {
    if (this.policyDetails && this.policyDetails.agencyPercent)
      return this.policyDetails.agencyPercent + "%";
    return "";
  }

  @computed
  get annualPremium(): string {
    if (this.policyDetails && this.policyDetails.annualPremium)
      return Utils.getCurrencyFormat(this.policyDetails.annualPremium);
    return "$0.00";
  }
  @computed
  get replacedDate(): string {
    if (this.policyDetails)
      return Utils.getDateInFormat(this.policyDetails.replacedDate);
    return "";
  }
  @computed
  get appliedRating(): string {
    if (this.policyDetails && this.policyDetails.approvedRating)
      return "" + this.policyDetails.appliedRating;
    return "";
  }
  @computed
  get approvedRating(): string {
    if (this.policyDetails && this.policyDetails.approvedRating)
      return "" + this.policyDetails.approvedRating;
    return "";
  }
  @action typeChange(value: string) {
    this.selectedType = value;
    if (this.policyId) this.loadPolicyNotes(this.policyId);
    //  this.fillterNotes(value)
  }
  //Add Edit Plicy Notes
  @observable
  public PolicyNotesErrorModel = new ErrorModel(PolicyNotesValidator);
  @observable notes: string = "";
  @observable noteType: string = "1";
  @observable isDefaultPolicyNote: boolean = false;
  @observable isCommission: boolean = false;
  @observable isResidential: boolean = false;
  @observable isVisibleInDownline: boolean = false;
  @observable isException: boolean = false;
  @observable isPolicyNoteDialogVisible: boolean = false;
  @observable isMoveRenewalDialogVisible: boolean = false;
  @observable exceptionMsg: string = "Something went wrong";
  @observable response: any;

  @observable noteTypeList = [
    { label: "General Comment", value: "1" },
    { label: "Underwriting", value: "2" },
  ];
  @observable isPrivate: boolean = false;
  @observable copyLicenseCommunication: boolean = false;
  @observable copyMarketer: boolean = false;
  @observable selectePolicyNoteDate: Date | undefined = new Date();

  @observable createFollowup: boolean = false;
  @observable moveRenewalPolicyID: string | undefined;
  @observable isMoveRenewalException: boolean = false;
  @observable isMoveRenewalSuccess: boolean = false;
  @observable moveRenewalSuccessMsg: string = "";
  @action setMoveRenewalDialogVisible(value: boolean) {
    this.moveRenewalPolicyID = undefined;
    this.isMoveRenewalDialogVisible = value;
    this.setIsMoveRenewalException(false);
    this.setIsMoveRenewalSuccess(false);
  }
  @action setMoveRenewalPolicyID(value: string) {
    this.moveRenewalPolicyID = value;
  }
  @action async SubmitMoveRenewal() {
    this.setIsMoveRenewalException(false);
    this.setIsMoveRenewalSuccess(false);
    this.isLoading = true;
    if (this.moveRenewalPolicyID && this.moveRenewalPolicyID.length > 0) {
      var result = await InsuredStore.moveRenewal(
        this.policyId ? this.policyId : 0,
        this.moveRenewalPolicyID ? this.moveRenewalPolicyID : ""
      );
      if (result) {
        this.moveRenewalSuccessMsg = "Policy moved successfully";
        this.setIsMoveRenewalSuccess(true);
        let self = this;
        if (this.policyId !== undefined) {
          this.renewalHistoryViewModel.Load(this.policyId);
        }
        setTimeout(() => {
          self.setMoveRenewalDialogVisible(false);
        }, 2000);
      }
    } else {
      this.exceptionMsg = "Please Enter Policy ID";
      this.setIsMoveRenewalException(true);
    }
    this.isLoading = false;
  }
  @action setIsMoveRenewalException(value: boolean) {
    this.isMoveRenewalException = value;
  }
  @action setIsMoveRenewalSuccess(value: boolean) {
    this.isMoveRenewalSuccess = value;
  }

  @action setPolicyNoteDialogVisible(value: boolean) {
    this.noteType = "1";
    this.notes = "";
    this.isPrivate = Utils.hasUserPermission(Permission.NotesPrivateByDefault);
    this.copyLicenseCommunication = false;
    this.copyMarketer = false;
    this.createFollowup = false;
    this.setSelectedPolicyDate(new Date());
    this.isPolicyNoteDialogVisible = value;
    this.setIsException(false);
  }
  @action setSelectedPolicyDate(date: Date | undefined) {
    this.selectePolicyNoteDate = date;
  }
  @action setIsPrivate(value: boolean) {
    this.isPrivate = value;
  }
  @action setIsException(value: boolean) {
    this.isException = value;
  }
  @action setCopyLicenseCommunication(value: boolean) {
    this.copyLicenseCommunication = value;
  }
  @action setCopyMarketer(value: boolean) {
    this.copyMarketer = value;
  }
  @action setCreateFollowup(value: boolean) {
    this.createFollowup = value;
  }
  @action setNotes(value: string) {
    this.notes = value;
  }
  @action setNoteType(value: string) {
    this.noteType = value;
  }

  @action
  public ValidatePolicyNote = async () => {
    var notesModel: CreateUpdatePolicyNoteModel = {};
    notesModel.note = this.notes;
    return this.PolicyNotesErrorModel.Validate(notesModel);
  };

  @action setDefaultPolicyNote(value: boolean) {
    this.isDefaultPolicyNote = value;
  }
  @action setCommission(value: boolean) {
    this.isCommission = value;
  }
  @action setResidential(value: boolean) {
    this.isResidential = value;
  }
  @action setVisibleInDownline(value: boolean) {
    this.isVisibleInDownline = value;
  }
  @action
  public Submit = async () => {
    if (!(await this.ValidatePolicyNote())) {
      try {
        this.isLoading = true;
        this.setIsException(false);
        var dto: CreateUpdatePolicyNoteModel = {
          policyId: this.policyId,
          note: this.notes,
          private: this.isPrivate,
          policyNoteTypeId: +this.noteType,
          agentId: this.agentId,
          noteDate: new Date(),
          copyDesignatedLicenseCommunication: this.copyLicenseCommunication,
          copyMarketer: this.copyMarketer,
          createFollowUpTask: this.createFollowup,
          taskDate: this.selectePolicyNoteDate,
          licenseId: this.licenseId ? +this.licenseId : 0,
        };
        await InsuredStore.addPolicyNotes(dto);
        this.isLoading = false;
        this.setPolicyNoteDialogVisible(false);
        this.sortColumn = "Id";
        this.sortOrder = false;
        setTimeout(() => {
          if (this.policyId) this.loadPolicyNotes(this.policyId);
        }, 2000);
      } catch (error: any) {
        this.isLoading = false;
        error &&
          error.text().then((_responseText: any) => {
            this.response = _responseText;
            this.isException = true;
          });
      }
    }
  };

  @observable selectedTabIndex: number | undefined = 0;
  @observable selectedPanel: string = PolicyTabs.Summary;
  @observable renewalHistoryViewModel = new RenewalHistoryViewModel();

  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }
  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
    if (this.selectedPanel === PolicyTabs.Summary.toString()) {
      this.loadPolicyDetails();
    } else {
      if (this.policyId !== undefined) {
        this.renewalHistoryViewModel.Load(this.policyId);
      }
    }
  };
  @observable PolicyTabItems = [
    { label: PolicyTabs.Summary, value: 0 },
    { label: PolicyTabs.RenewalHistory, value: 1 },
  ];
}
