/* import { routes } from "../../router"; */
import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Utils from "../../infrastructure/Utils";




export class ContractManagementViewModel implements ViewModel {

    @observable gridApi: any;
    @observable gridApiData:any = null;
    @observable metricData:any = {"allCount":0, "pendingCount":0, "inReviewCount":0, "submittedCount":0, "activeCount":0};
    @observable pageIndex: number = 0;
    @observable pageSize: number = 100;
    @observable totalRecords: number = 0;
    @observable gridOptions:any;
    @observable _apikey:any;
    @observable _host:any = Utils.getHost();

    
    Route = async (currentRoute: RouteList): Promise<void> => {};
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    @action Load = async () => {
        window.scrollTo(20,0);
        this.gridApiData = [];
        this._apikey = await Utils.getApiKey();
/*         await this.getRowData(); */
/*         await this.getMetricData(); */
    }

    @action onRowsPerPage = async (value: number) => {
        this.pageSize = value;
    };

    @action onPaginationChanged = async (params: any = null, pageIndex: number) => {
        this.pageIndex = pageIndex;
    };
    @action setGridApi = (api: any) => {
        this.gridApi = api;
    };

    @action getMetricData = async () => {
        let _url = Utils.getServiceUrlByEnvironment()+"/api/contract/v1/contractsManagement/GetAllMetrics";
        const requestOptions = {
        method: 'GET',
        headers: {
            HOSTNAME: this._host,
            authorization: this._apikey,
            "Content-Type": "application/json"
        }
        };
        fetch(_url, requestOptions)
        .then(fetchresponse => fetchresponse.json())
        .then(fetchdata => {
          console.log(fetchdata);
          this.metricData = fetchdata;
        })
    }
    @action getRowData = async (startDate:any="2000-12-01", endDate:any="2050-12-01", pageSize:any="500", sortColumn:any="datecreated") => {
        let _url = Utils.getServiceUrlByEnvironment()+"api/contract/v1/contractsManagement/GetAllContracts";
        const requestOptions = {
          method: 'POST',
          headers: {
            HOSTNAME: this._host,
            authorization: this._apikey,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ 
            "pageIndex": 1,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortAscending": false,
            "fullTextFilter": "string",
            "searchParameter": "datecreated=From:"+startDate+"To:"+endDate
          })
        };
        fetch(_url, requestOptions)
        .then(fetchresponse => fetchresponse.json())
        .then(fetchdata => { 
            if (fetchdata && fetchdata.data) {
                this.gridApiData = fetchdata;
                this.totalRecords = fetchdata.recordCount || 0;
                console.log(fetchdata);
            } else {
                this.gridApiData = [];
                this.totalRecords = 0;
            }
        });
    }

    @action getData = async () => {



    };

};
    