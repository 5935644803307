import { action, observable } from "mobx";
import Collection from "../../../infrastructure/CollectionHelper";
import { AppMenuItems } from "../../../infrastructure/enum/Common";
import { Permission } from "../../../infrastructure/enum/Permission";
import PageContext from "../../../infrastructure/PageContext";
import ReportStore from "../../../infrastructure/ReportModel";
import RouteList from "../../../infrastructure/RouteList";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import UserContext from "../../../infrastructure/UserContext";
import Utils from "../../../infrastructure/Utils";
import ViewModel from "../../../infrastructure/ViewModel";
import { FileType2, ReportFile } from "../../../services/AccountingService";
import { ActionLogModel } from "../../../services/IdentityService";
import {
    InsuredDetailsLookupModel,
    PolicyNumberLookupModel,
} from "../../../services/InsuredService";
import { AgentListModel } from "../../../services/ProducerSearchService";
import AccountStore from "../../../store/AccountingStore";
import AgentStateLicensesStore from "../../../store/AgentStateLicensesStore";
import AutoDialerStore from "../../../store/AutoDialerStore";
import BusinessUnitConfigStore from "../../../store/BusinessUnitConfigStore";
import CommissionStore from "../../../store/CommissionStore";
import ContractsStore from "../../../store/ContractsStore";
import StateLicenseStore from "../../../store/StateLicenseStore";
import {
    default as IdentityStore,
    default as UserStore,
} from "../../../store/IdentityStore";
import InsuredStore from "../../../store/InsuredStore";
import LeadStore from "../../../store/LeadStore";
import { McsreportModel } from "./McsreportModel";
import { TurninreportModel } from "./TurninreportModel";
import ProducerSearchStore from "../../../store/ProducerSearchStore";
import ProducerStore from "../../../store/ProducerStore";
import ProductionStore from "../../../store/ProductionStore";
import ProductStore from "../../../store/ProductStore";
import { routes } from "../../../router";
import { googleTagManager } from "../../../infrastructure/tracking";

export class ReportsViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }

    @action Load = async () => {
        this.isLoading = true;
        let delay = 0;
        if (UserContext.permissions.length <= 0) {
            delay = 2000;
        }
        setTimeout(async () => {
            if (Utils.hasUserPermission(Permission.ViewStandardReports)) {
                this.isReportNotVisible = false;
                this.selectedReportType = "";
                await this.getReportTypes();
                this.getMonthList();
                this.visibleWhenReportType = false;
                this.resetValues();
                this.resetMcsValues();
                this.resetControlVisibility();
                Utils.hasUserPermission(Permission.ChangeMarketerParameters)
                    ? (this.changeMarketerParameter = true)
                    : (this.changeMarketerParameter = false);
            } else if (
                UserContext.permissions.length > 0 &&
                !Utils.hasUserPermission(Permission.ViewStandardReports)
            ) {
                this.isReportNotVisible = true;
            }

            if (RouteParamDetails.reportType) {
                console.log(RouteParamDetails.reportType);
                this.selectedReportType = "" + RouteParamDetails.reportType;
                this.setControlsBasedOnSelection();
                RouteParamDetails.reportType = undefined;
            }
        }, delay);

        this.isLoading = false;
        this.resetMcsValues();
    };

    Route = async (currentRoute: RouteList): Promise<void> => { };
    @observable McsreportModel: McsreportModel = new McsreportModel();
    @observable TurninreportModel: TurninreportModel = new TurninreportModel();
    @observable companies = [{ label: "All", value: "0" }];
    @observable isLoading: boolean = false;
    @observable isExcel: boolean = true;
    @observable hidePrivateNotes: boolean = false;
    @observable hideOverRides: boolean = false;
    @observable title: string = "Report Viewer";
    @observable selectedFormat: string = "Excel";
    @observable startDate: Date | undefined = new Date();
    @observable turnInstartDate: Date = new Date();
    @observable UTCisoDateString: Date = new Date();
    @observable turnInendDate: Date = new Date();
    @observable endDate: Date | undefined = new Date();
    @observable isMcsSelected: boolean = false;

    @observable edpStartDate: Date | undefined = new Date(
        new Date().getFullYear(),
        0,
        1
    );

    @observable edpEndDate: Date | undefined = new Date();
    @observable licenseStartDate: Date | undefined = new Date();
    @observable licenseEndDate: Date | undefined = new Date();
    @observable issueDate: Date | undefined = undefined;
    @observable expirationDate: Date | undefined = undefined;
    @observable selectedStatus: string = "0";

    @observable getYear = new Date().getFullYear();

    @observable getMonth = new Date().getMonth();

    @observable getDay = new Date().getDate();

    @observable greyOutStartDate: Date = new Date(2016, 12, 1);

    @observable greyOutEndDate: Date = new Date(
        this.getYear,
        this.getMonth,
        this.getDay
    );

    @observable selectedMarketers: any = null;
    @observable selectedUser: string = "0";
    @observable selectedAgentMarketers: any = null;
    @observable selectedAddressTypes: any = null;
    @observable selectedContactTypes: any = null;
    @observable selectedPrdSubCategory: any = null;
    @observable selectedPrdCategory: any = null;
    @observable visibleWhenReportType: boolean = false;
    @observable FilteredUplineList = new Collection<AgentListModel>();
    @observable FilteredAgentsList = new Collection<AgentListModel>();
    @observable FilteredPayToAgentsList = new Collection<AgentListModel>();
    @observable FilteredPolicyNumberList =
        new Collection<PolicyNumberLookupModel>();
    @observable PolicyNumberList = new Collection<AgentListModel>();
    @observable FilteredInsuredDetailsList =
        new Collection<InsuredDetailsLookupModel>();
    @observable startDateErr: boolean = false;
    @observable endDateErr: boolean = false;
    @observable yearGapErr: boolean = false;
    @observable formatList = [
        { label: "Excel", value: "0" },
        { label: "PDF", value: "1" },
    ];

    @observable selectedAgentGroups: any = null;
    @observable selectedAgentStatuses: any = null;
    @observable selectedCounties: any = null;
    @observable selectedLeadSources: any = null;
    @observable FilteredAgencyList = new Collection<AgentListModel>();
    @observable showWarning: boolean = false;
    @observable isReportNotVisible: boolean = false;
    @observable changeMarketerParameter: boolean = false;
    @observable changeAgentMarketerParameter: boolean = false;
    @observable isMessageVisible: boolean = false;
    @observable response: any;
    @observable pageEventPrevention: boolean = true;
    @observable isReportAlertVisible: boolean = false;
    @observable reportAlertMessage: string = "";
    //added
    @observable selectedProductsBySubCat: any = null;
    @observable selectedProducts: any = null;
    @observable selectedProductNames = new Collection<string>();
    @observable selectedProductNamesBySubCat = new Collection<string>();
    @observable selectedCompanys: any = null;
    @observable awnsTitle: string | undefined = "";
    @observable agentTitle: string | undefined = "";
    @observable isEDPApiCall: boolean = true;
    @observable startIndex: number = 0;
    @observable rows: number = 10;
    @action stopPagePropagatoin(pageEventPrevention: boolean) {
        this.pageEventPrevention = pageEventPrevention;
    }
    @observable changeAgentStatusParameter: boolean = false;
    @observable changeLeadSourceParameter: boolean = false;
    @observable changeAgentGroupParameter: boolean = false;
    @observable showGenerateToast: boolean = false;
    @observable reportName: string = "";
    private getAdjustedDate(originalDate: Date): Date {
        return new Date(
            originalDate.getTime() - originalDate.getTimezoneOffset() * 60000
        );
    }

    @action getStartEndBasedOnDate = () => {
        // Since we have days ranging from 0-6 in JS, using that logic here to calculate
        // start and end date based ob today's day
        let a = new Date().getDay();
        let start = new Date();
        let end = new Date();
        this.startDate = new Date(start.setDate(start.getDate() - a));
        this.endDate = new Date(end.setDate(end.getDate() + (6 - a)));
        this.turnInstartDate = new Date();
        this.turnInendDate = new Date();
    };

    @action getLicenseStartEndBasedOnDate = () => {
        // Since we have days ranging from 0-6 in JS, using that logic here to calculate
        // start and end date based ob today's day
        let a = new Date().getDay();
        let start = new Date();
        let end = new Date();
        this.licenseStartDate = new Date(start.setDate(start.getDate() - a));
        this.licenseEndDate = new Date(end.setDate(end.getDate() + (6 - a)));
        let endYear = start.getFullYear() + 20;
        this.yearRange = this.licenseStartDate.getFullYear() - 20 + ":" + endYear;
    };

    @action getDatesForTripPoint = () => {
        let currYear = new Date().getFullYear();
        this.startDate = new Date(currYear, 0, 1);
        this.endDate = new Date(currYear, 11, 31);
        this.turnInstartDate = new Date();
        this.turnInendDate = new Date();
    };

    // ========drop-down value bindings==========
    @observable reportTypes = new Collection<{
        label: string;
        value: string;
    }>();
    @observable uplineSuggestions = new Collection<{
        id: number;
        name: string;
    }>();
    @observable locationAgentSuggestions = new Collection<AgentListModel>();
    @observable whichDateEnteredList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable commissionDateList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable agentMarketersList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable agentAddressTypesList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable agentContactTypesList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable addressList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable contactTypeList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable eoTypeList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable divisionList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable productLinesList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable StatusList: any = [{ label: "Select", value: "0" }];
    @observable companyGroupList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable SMSEnabledList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable monthList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable yearList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable companyList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable marketersList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable usersList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable productList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable productListBySubCat = new Collection<{
        label: string;
        value: string;
    }>();
    @observable customerStateList = new Collection<{
        label: string;
        value: string;
        isHeader: boolean;
    }>();
    @observable policyStatusList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable agentStatusList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable countyList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable licenseStatusList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable notesInXDaysList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable leadSourceList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable teamList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable prdtCatList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable prdtSubCatList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable agentHomeStateList = new Collection<{
        label: string;
        value: string;
        isHeader: boolean;
    }>();
    @observable agentLicenseStateList = new Collection<{
        label: string;
        value: string;
        isHeader: boolean;
    }>();
    @observable productSubCategoryList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable productBySubCategoryList = new Collection<{
        label: string;
        value: string;
    }>();

    @observable agentGroupList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable principalAgentStatusList = new Collection<{
        label: string;
        value: string;
    }>();
    @observable immediateReportingUplineList = new Collection<any>();

    // ========drop-down value bindings==========

    //================selected drop-down values====================
    @observable selectedPrincipalAgentStatus: string = "0";
    @observable selectedEOType: string = "0";
    @observable selectedContactType: string = "1";
    @observable selectedAddress: string = "1";
    @observable selectedCompany: string = "0";
    @observable selectedMonth: string = "0";
    @observable selectedYear: string = "0";
    @observable selectedCompanyGroup: string = "0";
    @observable selectedSMSEnabled: string = "0";
    @observable selectedCompanyName: string = "";
    @observable selectedReportType: string = "";
    @observable selectedWhichDateEntered: string = "DateEffective";
    @observable selectedCommissionDate: string = "PayDate";
    @observable selectedDivision: string = "0";
    @observable selectedProduct: string = "0";
    @observable selectedProductLine: any;

    @observable selectedPolicyStatus: any = null;
    @observable selectedCustomerState: string = "0";
    @observable selectedAgentStatus: string = "0";
    @observable selectedLicenseStatus: string = "0";
    @observable selectedNotesInXDays: string = "0";
    @observable selectedLeadSource: string = "0";
    @observable selectedTeam: string = "0";
    @observable selectedPrdtCat: any = null;
    @observable selectedPrdtSubCat: any = null;
    @observable selectedPrdtCatNames = new Collection<string>();
    @observable selectedPrdtSubCatNames = new Collection<string>();
    @observable selectedPrdtBySubCatNames = new Collection<string>();
    @observable selectedPrdtNamesbySubCat = new Collection<string>();
    @observable selectedPrdtNames = new Collection<string>();
    @observable selectedAgentHomeState: any = null;
    @observable selectedAgentLicenseState: any = null;
    @observable minPremium: number = 0;
    @observable selectedPrdtSubCatAll: any = null;
    @observable selectedPrdtBySubCat: any = null;
    @observable selectedImmediateReportingUpline: string = "";
    @observable selectedImmediateReportingUplineId: number = 0;

    //================selected drop-down values====================

    //================autocomplete controls====================
    @observable upline: string = "";
    @observable SelectedReportFromDropDown: string = "";
    @observable uplineId: number = 0;
    @observable selectedAgent: string = "";
    @observable selectedAgentId: number = 0;
    @observable selectedAgentAWNs: string[] | undefined = [];
    @observable locationAgent: string = "";
    @observable locationAgentId: number = 0;
    @observable payToAgent: string = "";
    @observable payToAgentId: number = 0;
    @observable policyNumber: string = "";
    @observable policyNumberId: number = 0;
    @observable insuredDetails: string = "";
    @observable insuredDetailsId: number = 0;
    //================autocomplete controls====================

    //================autocomplete controls====================
    //================autocomplete controls Agency====================
    @observable agency: string = "";
    @observable agencyId: number = 0;
    @observable selectedAgency: string = "";
    @observable selectedAgencyId: number = 0;
    @observable locationAgency: string = "";
    @observable locationAgencyId: number = 0;

    //================autocomplete controls====================

    // ==================controls visibility based on selection=======================
    @observable isWhichDate: boolean = false;
    @observable isCommissionDate: boolean = false;
    @observable isStartDate: boolean = false;
    @observable StartDateTitle: string = "Start Date";
    @observable EndDateTitle: string = "End Date";
    @observable isMonth: boolean = false;
    @observable isYear: boolean = false;
    @observable isEndDate: boolean = false;
    @observable isAgentGroup: boolean = false;
    @observable isProductionAgentGroup: boolean = false;
    @observable isEdpStartDate: boolean = false;
    @observable isEdpEndDate: boolean = false;
    @observable isLicenseStartDate: boolean = false;
    @observable isLicenseEndDate: boolean = false;
    @observable isUpline: boolean = false;
    @observable isProductLine: boolean = false;
    @observable isAgency: boolean = false;

    @observable isDivision: boolean = false;
    @observable isMarketers: boolean = false;
    @observable isAgentMarketers: boolean = false;
    @observable isAgentAddressTypes: boolean = false;
    @observable isAgentContactTypes: boolean = false;
    @observable isCompany: boolean = false;
    @observable isAgent: boolean = false;
    @observable isAgentStatusForDemography: boolean = false;
    @observable isCounty: boolean = false;
    @observable isEdpAgent: boolean = false;
    @observable isStateAgent: boolean = false;
    @observable isLicenseState: boolean = false;
    @observable isIssueDate: boolean = false;
    @observable isExpirationDate: boolean = false;
    @observable isStateLicenseStatus: boolean = false;
    @observable isProduct: boolean = false;
    //added
    @observable isMultiProduct: boolean = false;
    @observable isMultiProductBySubCat: boolean = false;
    @observable isMultiCompany: boolean = false;
    @observable isLocation: boolean = false;
    @observable isMinPremium: boolean = false;
    @observable isPolicyStatus: boolean = false;
    @observable isOrderBy: boolean = false;
    @observable isCustomerState: boolean = false;
    @observable isAgentStatus: boolean = false;
    @observable isAgentStatusMultiSelect: boolean = false;
    @observable isLicenseStatus: boolean = false;
    @observable isNotesInDays: boolean = false;
    @observable isUsers: boolean = false;
    @observable isLeadSource: boolean = false;
    @observable isLeadSourceMultiSelect: boolean = false;
    @observable isTeam: boolean = false;
    @observable isPrdtCat: boolean = false;
    @observable isPrdtSubCat: boolean = false;
    @observable isAgentHomeState: boolean = false;
    @observable isAgentLicenseState: boolean = false;
    @observable isHidePrivateNotes: boolean = false;
    @observable isHideOVerrides: boolean = false;
    @observable isAddress: boolean = false;
    @observable isCompanyGroup: boolean = false;
    @observable isSMSEnabled: boolean = false;
    @observable isPolicyNumber: boolean = false;
    @observable isInsured: boolean = false;
    @observable isAgentByWritingNumber: boolean = false;
    @observable isGetAllSubCategoryByDefault: boolean = false;
    @observable isGetProductBySubCategory: boolean = false;
    @observable isPayToAgent: boolean = false;

    @observable isContactType: boolean = false;
    @observable isEOType: boolean = false;
    @observable isPrincipalAgent: boolean = false;

    // ==================controls visibility based on selection=======================

    // ==================controls enable/disable based on selection=======================
    @observable isWhichDateDisabled: boolean = true;
    @observable isCommisionDateDisabled: boolean = true;

    @observable isAgentAgencyDisabled: boolean = false;
    @observable isMarketerDisabled: boolean = false;
    @observable uplineMandatory: boolean = false;

    @observable isStatusDisabled: boolean = false;
    @observable isLeadSourceDisabled: boolean = false;
    @observable isAgentGroupDisabled: boolean = false;
    @observable isCountyDisabled: boolean = false;
    @observable isEODisabled: boolean = false;
    @observable isPrincipalAgentDisabled: boolean = false;
    @observable yearRange: string = "";
    @observable isImmediateReportingUpline: boolean = false;
    // ==================controls enable/disable based on selection=======================
    @action disableReportFilters = () => {
        this.isMarketerDisabled = false;
        this.isStatusDisabled = false;
        this.isLeadSourceDisabled = false;
        this.isAgentGroupDisabled = false;
        this.isCountyDisabled = false;
        this.isEODisabled = false;
        this.isPrincipalAgentDisabled = false;
        if (this.selectedReportType == "22" && this.selectedAgentId != 0) {
            //Reset Filters & Disable Filters
            this.selectedAgentMarketers = null;
            this.selectedLeadSources = null;
            this.selectedAgentGroups = null;
            this.selectedAgentStatuses = null;
            this.selectedEOType = "1";
            this.selectedPrincipalAgentStatus = "1";
            this.selectedCounties = null;
            this.selectedImmediateReportingUplineId = 0;

            this.isMarketerDisabled = true;
            this.isStatusDisabled = true;
            this.isLeadSourceDisabled = true;
            this.isAgentGroupDisabled = true;
            this.isEODisabled = true;
            this.isPrincipalAgentDisabled = true;
            this.isCountyDisabled = true;
            this.isImmediateReportingUpline = true;
        }
    };

    @action resetControlVisibility = () => {
        this.isWhichDate = false;
        this.isCommissionDate = false;
        this.isStartDate = false;
        this.isEndDate = false;
        this.isUpline = false;
        this.isProductLine = false;
        this.isDivision = false;
        this.isMarketers = false;
        this.isCompany = false;
        this.isAgent = false;
        this.isAgentStatusForDemography = false;
        this.isProduct = false;
        //added
        this.isMultiProduct = false;
        this.isMultiCompany = false;
        this.isLocation = false;
        this.isPolicyStatus = false;
        this.isMinPremium = false;
        this.isOrderBy = false;
        this.isCustomerState = false;
        this.isAgentStatus = false;
        this.isLicenseStatus = false;
        this.isNotesInDays = false;
        this.isUsers = false;
        this.isTeam = false;
        this.isLeadSource = false;
        this.isPrdtCat = false;
        this.isPrdtSubCat = false;
        this.isAgentHomeState = false;
        this.isAgentLicenseState = false;
        this.isHidePrivateNotes = false;
        this.isHideOVerrides = false;
        this.visibleWhenReportType = false;
        this.isAddress = false;
        this.isCompanyGroup = false;
        this.isSMSEnabled = false;
        this.isEdpAgent = false;
        this.isStateAgent = false;
        this.isLicenseState = false;
        this.isIssueDate = false;
        this.isExpirationDate = false;
        this.isEdpStartDate = false;
        this.isEdpEndDate = false;
        this.isPolicyNumber = false;
        this.isInsured = false;
        this.isLicenseStartDate = false;
        this.isLicenseEndDate = false;
        this.isAgentMarketers = false;
        this.isAgentAddressTypes = false;
        this.isAgentContactTypes = false;
        this.isStateLicenseStatus = false;
        this.isCounty = false;
        this.isMonth = false;
        this.isYear = false;
        this.isImmediateReportingUpline = false;
    };
    @action resetMcsValues = () => {
        this.isMcsSelected = false;
    };

    @action resetValues = () => {
        this.selectedFormat = "Excel";
        this.selectedMarketers = null;
        this.selectedUser = "0";
        this.selectedPrdSubCategory = null;
        this.selectedPrdCategory = null;
        this.selectedProduct = "0";
        this.selectedCounties = null;
        this.selectedProductLine = [];
        this.selectedDivision = "0";
        this.selectedCompanyGroup = "0";
        this.selectedSMSEnabled = "0";
        this.selectedCompany = "0";
        this.selectedWhichDateEntered = "DateEffective";
        this.selectedCommissionDate = "PayDate";
        this.uplineId = 0;
        this.upline = "";
        this.locationAgent = "";
        this.locationAgentId = 0;
        this.selectedAgentId = 0;
        this.selectedAgent = "";
        this.selectedPolicyStatus = null;
        this.minPremium = 0;
        this.selectedAgentStatus = "0";
        this.selectedLicenseStatus = "0";
        this.selectedNotesInXDays = "0";
        this.selectedLeadSource = "0";
        this.selectedTeam = "0";
        this.selectedPrdtCat = null;
        this.selectedPrdtSubCat = null;
        this.selectedPrdtCatNames.values = [];
        this.selectedPrdtSubCatNames.values = [];
        this.selectedPrdtBySubCatNames.values = [];
        this.selectedPrdtNames.values = [];
        this.selectedPrdtNamesbySubCat.values = [];
        this.selectedAgentHomeState = null;
        this.selectedAgentLicenseState = null;
        this.hidePrivateNotes = false;
        this.hideOverRides = false;
        this.startDateErr = false;
        this.endDateErr = false;
        this.FilteredAgentsList.values = [{ id: 0 }];
        this.locationAgentSuggestions.values = [{ id: 0 }];
        this.companyList.values = [];
        this.divisionList.values = [];
        this.productLinesList.values = [];
        this.productList.values = [];
        this.whichDateEnteredList.values = [];
        this.commissionDateList.values = [];
        this.marketersList.values = [];
        this.usersList.values = [];
        this.agentStatusList.values = [];
        this.countyList.values = [];
        this.licenseStatusList.values = [];
        this.notesInXDaysList.values = [];
        this.policyStatusList.values = [];
        this.prdtCatList.values = [];
        this.prdtSubCatList.values = [];
        this.agentHomeStateList.values = [];
        this.customerStateList.values = [];
        this.agentLicenseStateList.values = [];
        this.teamList.values = [];
        this.leadSourceList.values = [];
        this.startDate = new Date();
        this.endDate = new Date();
        this.productSubCategoryList.values = [];
        this.productBySubCategoryList.values = [];
        this.selectedPrdtSubCatAll = null;
        this.selectedPrdtBySubCat = null;
        this.turnInstartDate = new Date();
        this.turnInendDate = new Date();
        this.showWarning = false;
        this.isReportNotVisible = false;
        this.changeMarketerParameter = false;
        this.isMessageVisible = false;
        this.response = "";
        this.isWhichDateDisabled = false;
        this.isCommisionDateDisabled = false;
        this.isPolicyNumber = false;
        this.isInsured = false;
        this.isGetAllSubCategoryByDefault = false;
        this.isGetProductBySubCategory = false;
        this.isPayToAgent = false;
        this.isAgentByWritingNumber = false;
        //added
        this.selectedProducts = null;
        this.selectedProductsBySubCat = null;
        this.selectedProductsBySubCat = null;
        this.selectedProductNamesBySubCat.values = [];
        this.selectedProductNames.values = [];
        this.selectedCompanys = null;
        this.edpStartDate = new Date(new Date().getFullYear(), 0, 1);
        this.edpEndDate = new Date();
        this.agentMarketersList.values = [];
        this.isAgentGroup = false;
        this.isProductionAgentGroup = false;
        this.isAgency = false;
        this.isAgentStatusMultiSelect = false;
        this.isEOType = false;
        this.isPrincipalAgent = false;
        this.isContactType = false;
        this.isLeadSourceMultiSelect = false;
        this.isMarketers = false;
        this.selectedLeadSources = null;
        this.selectedAgentGroups = null;
        this.selectedAgentStatuses = null;
        this.selectedEOType = "0";
        this.selectedPrincipalAgentStatus = "0";
        this.selectedAgencyId = 0;
        this.selectedAgency = "0";
        this.isMultiProductBySubCat = false;
        this.StartDateTitle = "Start Date";
        this.EndDateTitle = "End Date";
        this.licenseStartDate = new Date();
        this.licenseEndDate = new Date();
        this.agency = "";
        this.selectedAgentMarketers = "";
        this.selectedContactTypes = "";
        this.selectedAddressTypes = "";
        this.agentContactTypesList.values = [];
        this.agentAddressTypesList.values = [];
        this.issueDate = undefined;
        this.expirationDate = undefined;
        this.isMarketerDisabled = false;
        this.isStatusDisabled = false;
        this.isLeadSourceDisabled = false;
        this.isAgentGroupDisabled = false;
        this.isEODisabled = false;
        this.isPrincipalAgentDisabled = false;
        this.selectedStatus = "0";
        this.selectedImmediateReportingUplineId = 0;
        this.selectedImmediateReportingUpline = "";
        this.isImmediateReportingUpline = false;
        this.showGenerateToast = false;
        this.reportName ="";
    };

    @action updatePrivateNotes = (value: boolean) => {
        this.hidePrivateNotes = value;
    };

    @action updateOverRides = (value: boolean) => {
        this.hideOverRides = value;
    };

    @action bindAllDropdowns = async () => {
        this.isLoading = true;
        this.getReportTypes();
        this.getMonthList();
        this.getWhichDateEnterd();
        this.getCommissionDate();
        this.getDivisionList();
        this.getMarketersList();
        this.getCompanyGroupList();
        this.getCompanyList();
        this.getProductList();
        this.getCustomerStateList();
        this.getPolicyStatusList();
        this.isLoading = false;
        this.getProductSubCategoryList();
    };

    // ===================Drop-down binding methods=======================

    @action getReportTypes = async () => {
        let res = await ReportStore.getReportTypes();        
        if (!Utils.hasUserPermission(Permission.ViewCarrierUpdates)) {
            res = res.filter((item: any) => {
                return item.label !== "Carrier Updates";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewOnHoldAgentCommission)) {
            res = res.filter((item: any) => {
                return item.label !== "On Hold Agent Commissions";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewCommissionBreakDown)) {
            res = res.filter((item: any) => {
                return item.label !== "Commission Break-Down";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewAllOrdersByTeam)) {
            res = res.filter((item: any) => {
                return item.label !== "Orders by Division (Shipping Expenses)";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewMarketingEventReport)) {
            res = res.filter((item: any) => {
                return item.label !== "Marketing Event";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewCommissionReport)) {
            res = res.filter((item: any) => {
                return item.label !== "Commission";
            });
        }

        if (!Utils.hasUserPermission(Permission.ViewAgentDebtReport)) {
            res = res.filter((item: any) => {
                return item.label !== "Agent Debt";
            });
        }

        if (!Utils.hasUserPermission(Permission.ViewContractedAgentList)) {
            res = res.filter((item: any) => {
                return item.label !== "Contracted Agent List";
            });
        }
        if (!Utils.hasUserPermission(Permission.ViewContractingSentReport)) {
            res = res.filter((item: any) => {
                return item.label !== "Contracting Sent";
            });
        }

        if (Utils.isDownlineAgency()) {

        if (!Utils.hasUserPermission(Permission.ViewContractingSentReport)) {
                res = res.filter((item: any) => {
                    return item.label !== "Contracting Sent";
                });
            }

        res = res.filter((item: any) => {
                return (
                    item.label === "Contract Count by Carrier" ||
                    item.label === "Contract Details" ||
                    item.label === "Agent / Agency Details" ||
                    item.label === "Contract Invite" ||
                    item.label === "Production" ||
                    item.label === "Policy Summary" ||
                    item.label === "Production Summary"||
                    item.label === "Contracted Agent List"
                );
            });
        }

        if (res) this.reportTypes.values = res;
        else this.reportTypes.values = [{ label: `No results`, value: "" }];
        console.log(
            "get report types.... ****************** **************** ######################"
        );
    };

    @action getWhichDateEnterd = async () => {
        let response = await ReportStore.getWhichDateEntered();
        if (response) {
            this.whichDateEnteredList.values = response;
            this.setWhichDateEntered("DateEntered");
        } else this.whichDateEnteredList.values = [];
    };

    @action getCommissionDate = async () => {
        let response = await ReportStore.getCommissionDate();
        if (response) {
            this.commissionDateList.values = response;
            this.setCommissionDate("PayDate");
        } else this.commissionDateList.values = [];
    };

    @action getAddressTypes = async () => {
        let response = await ReportStore.getAddressTypes();
        if (response) {
            this.addressList.values = response;
            this.setAddressType("0");
        } else this.addressList.values = [];
    };
    @action getContactTypes = async () => {
        let response = await ReportStore.getContactTypes();
        if (response) {
            this.contactTypeList.values = response;
            this.setContactType("1");
        } else this.contactTypeList.values = [];
    };
    @action getEOTypes = async () => {
        let response = await ReportStore.getEOTypes();
        if (response) {
            this.eoTypeList.values = response;
            this.setEOType("1");
        } else this.eoTypeList.values = [];
    };
    @action getPrincipalAgentStatusTypes = async () => {
        let response = await ReportStore.getPrincipalAgentStatusTypes();
        if (response) {
            this.principalAgentStatusList.values = response;
            this.setPrincipalAgentStatus("1");
        } else this.principalAgentStatusList.values = [];
    };
    @action loadAgentGroupList = async () => {
        var res = await BusinessUnitConfigStore.getAllAgentGroupNameLookup();
        if (res != null) {
            this.agentGroupList.values = [];
            Utils.mapListItemsToDropdown(res, this.agentGroupList, "None", "-1");
        } else {
            this.agentGroupList.values = [];
        }
    };
    @action loadContractAgentGroupList = async () => {
        var res =
            await BusinessUnitConfigStore.getAllContractAgentGroupNameLookup();
        if (res != null) {
            this.agentGroupList.values = [];
            Utils.mapListItemsToDropdown(res, this.agentGroupList, "None", "-1");
        } else {
            this.agentGroupList.values = [];
        }
    };
    @action getMarketersList = async () => {
        try {
            this.marketersList.values = [];
            var result = null;
            if (
                Utils.hasUserPermission(Permission.ChangeMarketerParameters) ||
                Utils.isDownlineAgency()
            ) {
                result = await ContractsStore.getMarketersLokup();
            } else {
                result = await AutoDialerStore.getMarketers();
            }
            if (result) {
                if (
                    this.selectedReportType == "24" ||
                    this.selectedReportType == "22" ||
                    this.selectedReportType == "27"
                ) {
                    if (!result.find((e) => e.text === "None")) {
                        result.splice(0, 0, { value: "-1", text: "None", selected: false });
                    }
                }

                var filterList = result.filter(
                    (v: any, i: any, a: any) =>
                        a.findIndex((t: any) => t.value === v.value) === i
                );
                var sortList = filterList.sort(Utils.compareListItem);

                for (let item of sortList) {
                    this.marketersList.values.push({
                        label: "" + item.text,
                        value: "" + item.value,
                    });
                }
            }
        } catch (e) {
            this.marketersList.values = [];
        }
    };

    @action getAllMarketersList = async () => {
        try {
            this.marketersList.values = [];
            var result = null;
            if (UserContext.agencyId !== "0") {
                this.marketersList.values = [];
                result = undefined;
            } else if (Utils.hasUserPermission(Permission.ChangeMarketerParameters)) {
                result = await ContractsStore.getAllMarketersLookup();
            }

            if (result) {
                var filterList = result.filter(
                    (v: any, i: any, a: any) =>
                        a.findIndex((t: any) => t.value === v.value) === i
                );
                var sortList = filterList.sort(Utils.compareListItem);

                for (let item of sortList) {
                    this.marketersList.values.push({
                        label: "" + item.text,
                        value: "" + item.value,
                    });
                }
            }
        } catch (e) {
            this.marketersList.values = [];
        }
    };

    @action getUsersList = async () => {
        var result = await IdentityStore.getAllUsers("");
        var values = null;
        if (result !== null) {
            values = result;
        }
        if (values !== undefined && values !== null) {
            this.usersList.values = this.mapListItemAndSort(values);
        } else {
            this.usersList.values = [];
        }
    };

    @action getCompanyGroupList = async () => {
        this.companyGroupList.values = [];
        var defaultItem = { label: "All", value: "0" };
        let res = await ContractsStore.getAllCompanyGroupLookup();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.companyGroupList.values.push(data);
            });
            this.companyGroupList.values.splice(0, 0, defaultItem);
            this.selectedCompanyGroup = "0";
        } else {
            this.companyGroupList.values = [];
            this.companyGroupList.values.splice(0, 0, defaultItem);
            this.selectedCompanyGroup = "0";
        }
    };
    @action getMonthList = async () => {
        this.monthList.values = [];
        this.yearList.values = [];
        // let res = await ReportStore.getMonthList()
        // this.monthList.values = res;
        let thisYear = new Date().getFullYear();
        let allYears: { label: string; value: string }[] = [];
        let startYear = 2019;
        let years = [];

        while (startYear <= thisYear) {
            years.push(startYear++);
        }
        years.map((item) => {
            allYears.push({ label: `${item}`, value: `${item}` });
        });
        this.yearList.values = allYears;
    };
    @action getSMSEnabledList = async () => {
        this.SMSEnabledList.values = [];
        let response = await ReportStore.getSMSEnabled();
        if (response) {
            this.SMSEnabledList.values = response;
            this.selectedSMSEnabled = "0";
        } else this.SMSEnabledList.values = [];
    };

    @action getCompanyList = async () => {
        this.companyList.values = [];
        var defaultItem = { label: "All", value: "0" };
        let res = await ContractsStore.getCompanyByCompanyGroupLookup(
            this.selectedCompanyGroup !== "0" ? Number(this.selectedCompanyGroup) : 0
        );
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.companyList.values.push(data);
            });
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
        } else {
            this.companyList.values = [];
            this.companyList.values.splice(0, 0, defaultItem);
            this.selectedCompany = "0";
        }
    };

    @action getDivisionList = async () => {
        var defaultItem = { label: "All", value: "0" };
        let res = await ReportStore.getProductList();
        if (res) {
            this.divisionList.values = res;
            this.divisionList.values.splice(0, 0, defaultItem);
            this.selectedDivision = "0";
        } else {
            this.divisionList.values = [];
            this.divisionList.values.splice(0, 0, defaultItem);
            this.selectedDivision = "0";
        }
    };

    @action getProductLinesList = async () => {
        var defaultItem = { label: "All", value: "0" };
        let res = await AgentStateLicensesStore.getProductLinesLookup();
        if (res) {
            this.productLinesList.values = [];
            this.productLinesList.values = Utils.mapListItemsAsList(
                res || [],
                "",
                ""
            );
            this.selectedProductLine = [];
        } else {
            this.productLinesList.values = [];
            this.productLinesList.values.splice(0, 0, defaultItem);
            this.selectedProductLine = [];
        }
    };

    @action getStateLicenseStatusList = async () => {
        // Load State License status
        var licenseStatuses = await StateLicenseStore.getAllStateLicenseStatus();
        if (licenseStatuses) {
            this.StatusList = [];
            this.StatusList = [{ label: "Select", value: "0" }].concat(
                this.mapListItemAndSort(licenseStatuses)
            );
        }
    };

    @action getProductList = async () => {
        this.selectedProducts = null;
        var defaultItem = { label: "All", value: "0" };
        let res = await ProductStore.getReportProductList(
            this.selectedCompany !== "0" ? Number(this.selectedCompany) : 0
        );
        if (res) {
            this.productList.values = [];
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.productList.values.push(data);
            });
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = "0";
        } else {
            this.productList.values = [];
            this.productList.values.splice(0, 0, defaultItem);
            this.selectedProduct = "0";
        }
    };

    @action getCustomerStateList = async () => {
        var defaultItem = { label: "All", value: "0", isHeader: false };
        let res = await ContractsStore.reportAgentHomeState();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };

                this.customerStateList.values.push(data);
            });
            this.customerStateList.values.splice(0, 0, defaultItem);
            this.selectedCustomerState = "0";
        } else {
            this.customerStateList.values = [];
            this.customerStateList.values.splice(0, 0, defaultItem);
            this.selectedCustomerState = "0";
        }
    };

    @action getAgentStatusList = async () => {
        var defaultItem = { label: "All", value: "0" };
        let res = await ProducerStore.reportAgentStatus();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.agentStatusList.values.push(data);
            });
            if (this.selectedReportType != "22" && this.selectedReportType != "27") {
                this.agentStatusList.values.splice(0, 0, defaultItem);
                this.selectedAgentStatus = this.agentStatusList.values[0].value;
            }
        } else {
            this.agentStatusList.values = [];
            if (this.selectedReportType != "22") {
                this.agentStatusList.values.splice(0, 0, defaultItem);
                this.selectedAgentStatus = "0";
            }
        }
    };

    @action getCountyList = async () => {
        let res = await ProducerStore.reportCountyList();
        if (res) {
            if (!res.find((e) => e.text === "None")) {
                res.splice(0, 0, { value: "-1", text: "None", selected: false });
            }
            Utils.mapListItemsToDropdown(res ? res : [], this.countyList, "");
        } else {
            this.countyList.values = [];
        }
    };

    @action getLicenseStatusList = async () => {
        //var defaultItem = { label: "All", value: "0" };
        let res = await ContractsStore.reportLicenseStatus();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.licenseStatusList.values.push(data);
            });

            //this.licenseStatusList.values.splice(0, 0, defaultItem);
            this.selectedLicenseStatus = this.licenseStatusList.values[0].value;
        } else {
            //this.licenseStatusList.values = [];
            //this.licenseStatusList.values.splice(0, 0, defaultItem);
            this.selectedLicenseStatus = "0";
        }
    };

    @action getLicenseContractStatusList = async () => {
        var defaultItem = { label: "Active & Pending", value: "0" };
        let res = await ContractsStore.reportLicenseStatus();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.licenseStatusList.values.push(data);
            });

            this.licenseStatusList.values.splice(0, 0, defaultItem);
            this.selectedLicenseStatus = this.licenseStatusList.values[0].value;
        } else {
            this.licenseStatusList.values = [];
            this.licenseStatusList.values.splice(0, 0, defaultItem);
            this.selectedLicenseStatus = "0";
        }
    };

    @action getNotesInXDaysList = async () => {
        let res = await ReportStore.getNotesInXDaysList();
        if (res) {
            this.notesInXDaysList.values = res;
            this.selectedNotesInXDays = this.notesInXDaysList.values[0].value;
        } else {
            this.notesInXDaysList.values = [];
            this.selectedNotesInXDays = "0";
        }
    };

    @action getLeadSourceList = async () => {
        var defaultItem = { label: "All", value: "0" };
        let res = await LeadStore.reportLeadSource();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.text ? obj.text : "",
                    value: obj.value ? obj.value.toString() : "",
                };

                this.leadSourceList.values.push(data);
            });

            if (this.selectedReportType != "22") {
                this.leadSourceList.values.splice(0, 0, defaultItem);
                this.selectedLeadSource = this.leadSourceList.values[0].value;
            }
        } else {
            this.leadSourceList.values = [];
            if (this.selectedReportType != "22") {
                this.leadSourceList.values.splice(0, 0, defaultItem);
                this.selectedLeadSource = "0";
            }
        }
    };

    @action getTeamList = async () => {
        var defaultItem = { label: "All", value: "0" };
        let res = await UserStore.getUserTeamList();
        if (res) {
            this.teamList.values = [];
            for (let item of res) {
                this.teamList.values.push({
                    label: "" + item.text,
                    value: "" + item.value,
                });
            }
        } else {
            this.teamList.values = [];
        }
        this.teamList.values.splice(0, 0, defaultItem);
        this.selectedTeam = "0";
    };

    @action getPolicyStatusList = async () => {
        let res = await InsuredStore.getPolicySummary(0, 0);
        if (res && res.statuses) {
            this.policyStatusList.values = [];
            var sortList = res.statuses.sort(Utils.compareListItem);
            for (let item of sortList) {
                this.policyStatusList.values.push({
                    label: "" + item.text,
                    value: "" + item.value,
                });
            }
        } else this.policyStatusList.values = [];
    };

    @action mapListItemAndSort(listObjects: any) {
        if (listObjects) {
            var sortList = listObjects.sort(Utils.compareListItem);
            sortList.forEach((element: any) => {
                element.label = element.text;
            });
            return sortList;
        } else {
            return [];
        }
    }

    @action getPrdtCatList = async () => {
        let res = await ProductStore.getPrdtCatList();
        if (res) {
            this.prdtCatList.values = [];
            this.prdtCatList.values = this.mapListItemAndSort(res);
        } else {
            this.prdtCatList.values = [];
        }
    };

    @action getPrdtSubCatList = async (catIds: number[]) => {
        let res = await ProductStore.getPrdtSubCatList(catIds);

        if (res) {
            this.prdtSubCatList.values = [];
            this.prdtSubCatList.values = this.mapListItemAndSort(res);
        } else {
            this.prdtSubCatList.values = [];
        }
    };

    @action getProductListBySubCat = async (subCatIds: number[]) => {
        let res = await ProductStore.getProductListBySubCat(subCatIds);

        if (res) {
            this.productListBySubCat.values = [];
            this.productListBySubCat.values = this.mapListItemAndSort(res);
        } else {
            this.productListBySubCat.values = [];
        }
        this.selectedProductsBySubCat = null;
    };

    @action getAgentHomeStateList = async () => {
        let res = await ContractsStore.reportAgentHomeState();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };

                this.agentHomeStateList.values.push(data);
            });
        } else {
            this.agentHomeStateList.values = [];
        }
    };
    @action getAllStatesLicenseList = async () => {
        let res = await AgentStateLicensesStore.getAllStatesLookup();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };

                this.agentLicenseStateList.values.push(data);
            });
        } else {
            this.agentLicenseStateList.values = [];
        }
    };
    @action getAllStatesHomeList = async () => {
        let res = await AgentStateLicensesStore.getAllStatesLookup();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };

                this.agentHomeStateList.values.push(data);
            });
        } else {
            this.agentHomeStateList.values = [];
        }
    };
    @action getAllStatesCustomerList = async () => {
        var defaultItem = { label: "All", value: "0", isHeader: false };
        let res = await AgentStateLicensesStore.getAllStatesLookup();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };
                this.customerStateList.values.push(data);
            });

            this.customerStateList.values.splice(0, 0, defaultItem);
            this.selectedCustomerState = "0";
        } else {
            this.customerStateList.values = [];
            this.customerStateList.values.splice(0, 0, defaultItem);
            this.selectedCustomerState = "0";
        }
    };
    @action getAgentLicenseStateList = async () => {
        let res = await ContractsStore.reportAgentHomeState();
        if (res) {
            res.forEach((obj: any) => {
                var data = {
                    label: obj.stateName ? obj.stateName : "",
                    value: obj.stateId ? obj.stateId.toString() : "",
                    isHeader: obj.isHeader,
                };

                this.agentLicenseStateList.values.push(data);
            });
        } else {
            this.agentLicenseStateList.values = [];
        }
    };

    @action getProductSubCategoryList = async () => {
        let res = await ProductStore.getProductSubCategoryDetailsList();
        if (res) {
            this.productSubCategoryList.values = [];
            for (let item of res) {
                this.productSubCategoryList.values.push({
                    label: "" + item.subCategoryName,
                    value: "" + item.id,
                });
            }
        } else {
            this.productSubCategoryList.values = [];
        }
    };

    @action getproductBySubCategoryList = async (subCatIds: number[]) => {
        let res = await ProductStore.getPrdtBySubCatList(subCatIds);
        this.selectedPrdtBySubCat = null;
        this.selectedPrdtNames.values = [];
        if (res) {
            this.productBySubCategoryList.values = [];
            this.productBySubCategoryList.values = this.mapListItemAndSort(res);
        } else {
            this.productBySubCategoryList.values = [];
        }
    };

    @action filterPayToAgents = async (value: string) => {
        try {
            let result = await CommissionStore.getPayToAgentforCommission(1, value);
            var values = null;
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredPayToAgentsList.values = values;
        } else {
            var noAgent: AgentListModel = { id: 0 };
            this.FilteredPayToAgentsList.values = [noAgent];
        }
    };

    @action filterPolicyNumbers = async (value: string) => {
        try {
            let result = await InsuredStore.getPolicyNumber(1, value);
            var values = null;
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredPolicyNumberList.values = values;
        } else {
            var noPolicy: PolicyNumberLookupModel = { policyID: 0 };
            this.FilteredPolicyNumberList.values = [noPolicy];
        }
    };

    @action filterInsuredDetails = async (value: string) => {
        try {
            let result = await InsuredStore.getInsuredDetails(2, value);
            var values = null;
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredInsuredDetailsList.values = values;
        } else {
            var noPolicy: InsuredDetailsLookupModel = { id: 0 };
            this.FilteredInsuredDetailsList.values = [noPolicy];
        }
    };

    // ===================Drop-down binding methods=======================

    //==========upline autocomplete=============
    @action fillterAgencies = async (value: string) => {
        try {
            let result = await ProducerSearchStore.getAgencyLookup(
                1,
                value,
                0,
                100,
                undefined,
                undefined
            );
            var values = null;
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredAgencyList.values = values;
        } else {
            var noAgent: AgentListModel = { id: 0 };
            this.FilteredAgencyList.values = [noAgent];
        }
    };
    @action fillterUsers = async (value: string) => {
        try {
            let result = await ProducerSearchStore.getAgentsLookup(
                1,
                value,
                0,
                100,
                undefined,
                undefined
            );
            var values = null;
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredUplineList.values = values;
        } else {
            var noAgent: AgentListModel = { id: 0 };
            this.FilteredUplineList.values = [noAgent];
        }
    };

    @action getCityState = (agent: AgentListModel) => {
        if (agent.city && agent.state) return agent.city + ", " + agent.state;
        else if (agent.city && !agent.state) return agent.city;
        else if (!agent.city && agent.state) return agent.state;
        else return "";
    };

    @action searchLocationAgent = async (value: string) => {
        var values = null;
        try {
            let result = await ProducerSearchStore.getLocationEnabledAgentsLookup(
               Utils.getAgentLocationSearchType(value),
                value,
                0,
                100,
                undefined,
                undefined
            );
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data.filter((ind: any) => ind.id !== -1);
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.locationAgentSuggestions.values = values;
        } else {
            var noAgent: AgentListModel = { id: 0 };
            this.locationAgentSuggestions.values = [noAgent];
        }
    };
    @action setLocationAgent = (value: string) => {
        this.locationAgent = value;
    };
    @action setLocationAgentId = (value: number) => {
        this.locationAgentId = value;
    };

    @action setUpline = (value: string) => {
        this.uplineMandatory = false;
        this.upline = value;
    };
    @action setUplineId = (value: number) => {
        this.uplineId = value;
    };

    @action setPayToAgent = (value: string) => {
        this.payToAgent = value;
    };
    @action setPayToAgentId = (value: number) => {
        this.payToAgentId = value;
    };

    @action setPolicyNumbers = (value: string) => {
        this.policyNumber = value;
    };
    @action setPolicyNumberIds = (value: number) => {
        this.policyNumberId = value;
    };

    @action setInsuredDetails = (value: string) => {
        this.insuredDetails = value;
    };
    @action setInsuredDetailIds = (value: number) => {
        this.insuredDetailsId = value;
    };
    //==========upline autocomplete=============
    @action setAgency = (value: string) => {
        this.agency = value;
    };
    @action setAgencyId = (value: number) => {
        this.agencyId = value;
    };
    //==========upline autocomplete=============

    //==========agent autocomplete=============

    @action setAgent(value: string) {
        this.selectedAgent = value;
    }

    @action setAgentId(value: number) {
        this.selectedAgentId = value;
    }

    @action setAgentImmediateUpline(name: any, id: number) {
        if (id) {
            this.selectedImmediateReportingUpline = name;
            this.selectedImmediateReportingUplineId = id;
        } else {
            this.selectedImmediateReportingUpline = "";
            this.selectedImmediateReportingUplineId = 0;
        }
    }

    @action fillterAgentImmediateline = async (value: string) => {
        googleTagManager.trackAction("agent_search_conducted", { feature: routes.reports.name, user_id: UserContext.userId, search_text: value });
      
        let results = await ProducerStore.getAgentImmediateUpline(
            Utils.getAgentSearchType(value),
            0,
            0,
            "Id",
            true,
            value
        );
        if (results !== null) {
            if (results.data) {
                if (results.data && results.data.length > 0) {
                    results.data.splice(0, 0, { agentId: -1 });
                    this.immediateReportingUplineList.values = results.data;
                } else {
                    this.immediateReportingUplineList.values = [{ agentId: 0 }];
                }
            } else {
                this.immediateReportingUplineList.values = [{ agentId: 0 }];
            }
        } else {
            this.immediateReportingUplineList.values = [{ agentId: 0 }];
        }
    };

    @action setAgentImmediateUplineId(value: number) {
        this.selectedImmediateReportingUplineId = value;
    }

    @action getAgentAWNs = async (value: number) => {
        var values = null;
        this.selectedAgentAWNs = [];
        try {
            let result = await ProductionStore.getAgentAWNsData(value);
            if (result !== null) {
                if (result.data) {
                    values = result.data;
                    if (values !== undefined && values !== null) {
                        this.selectedAgentAWNs = values.agentOrAgencyAWNs;
                    }
                }
            }
        } catch (e) { }
    };

    @action searchAgents = async (value: string) => {
        var values = null;
        this.selectedAgentAWNs = [];
        try {
            let result = await ProducerSearchStore.getAgentsLookup(
                1,
                value,
                0,
                100,
                undefined,
                undefined
            );
            if (result !== null) {
                if (result.recordCount) {
                    if (result.data) {
                        values = result.data;
                    }
                }
            }
        } catch (e) { }

        if (values !== undefined && values !== null) {
            this.FilteredAgentsList.values = values;
        } else {
            this.noPrincipalAgents();
        }
    };
    @action noPrincipalAgents() {
        var noAgent: AgentListModel = { id: 0 };
        this.FilteredAgentsList.values = [noAgent];
    }

    //==========agent autocomplete=============

    // ===================onChange methods=======================

    @action setSelectedMarketers = (value: any) => {
        this.selectedMarketers = value;
    };
    @action setSelectedUser = (value: string) => {
        this.selectedUser = value;
    };
    @action setSelectedAgentMarketers = (value: any) => {
        this.selectedAgentMarketers = value;
    };
    @action setSelectedAgentContactTypes = (value: any) => {
        this.selectedContactTypes = value;
    };
    @action setSelectedAgentAddressTypes = (value: any) => {
        this.selectedAddressTypes = value;
    };
    @action setselectedAddressTypes = (value: any) => {
        this.selectedAddressTypes = value;
    };
    @action setselectedContactTypes = (value: any) => {
        this.selectedContactTypes = value;
    };

    @action setPrdSubCategory = (value: any) => {
        this.selectedPrdSubCategory = value;
    };

    @action setPrdCategory = (value: any) => {
        this.selectedPrdCategory = value;
    };

    @action setSelectedFormat = (value: any) => {
        this.selectedFormat = value;
    };
    @action setReportType = (value: string) => {
        this.selectedReportType = value;
    };
    @action setSelectedAgentStatuses = (value: any) => {
        this.selectedAgentStatuses = value;
    };
    @action setSelectedImmediateReportingUpline = (value: any) => {
        if (value) {
            this.selectedImmediateReportingUpline = value;
        } else {
            this.selectedImmediateReportingUpline = value;
            this.selectedImmediateReportingUplineId = 0;
        }
    };
    @action setSelectedCounty = (value: any) => {
        this.selectedCounties = value;
    };

    @action setSelectedLeadSources = (value: any) => {
        this.selectedLeadSources = value;
    };
    @action setSelectedAgentGroups = (value: any) => {
        this.selectedAgentGroups = value;
    };

    @action setStartDate = (value: Date | undefined) => {
        this.startDate = value;
    };
    @action setEndDate = (value: Date | undefined) => {
        this.endDate = value;
    };
    @action setEdpStartDate = (value: Date | undefined) => {
        this.edpStartDate = value;
    };
    @action setEdpEndDate = (value: Date | undefined) => {
        this.edpEndDate = value;
    };
    @action setLicenseStartDate = (value: Date | undefined) => {
        this.licenseStartDate = value;
    };
    @action setLicenseEndDate = (value: Date | undefined) => {
        this.licenseEndDate = value;
    };
    @action setIssueDate = (value: Date | undefined) => {
        this.issueDate = value;
    };
    @action setExpirationDate = (value: Date | undefined) => {
        this.expirationDate = value;
    };
    @action setWhichDateEntered(value: string) {
        this.selectedWhichDateEntered = value;
    }
    @action setCommissionDate(value: string) {
        this.selectedCommissionDate = value;
    }
    @action setAddressType(value: string) {
        this.selectedAddress = value;
    }
    @action setDivision(value: string) {
        this.selectedDivision = value;
    }
    @action setCompanyGroup(value: string) {
        this.selectedCompanyGroup = value;
    }
    @action setSMSEnabled(value: string) {
        this.selectedSMSEnabled = value;
    }
    @action setCompany(value: string) {
        this.selectedCompany = value;
        let selectedoption = this.companyList.values.filter((i: any) => {
            return i.value == value;
        });
        if (selectedoption && selectedoption.length > 0) {
            this.selectedCompanyName =
                selectedoption[0].label == "All" ? "" : selectedoption[0].label;
        }
    }

    @action setContactType(value: string) {
        this.selectedContactType = value;
    }
    @action setEOType(value: string) {
        this.selectedEOType = value;
    }
    @action setPrincipalAgentStatus(value: string) {
        this.selectedPrincipalAgentStatus = value;
    }

    @action setMonth(value: string) {
        this.selectedMonth = value;
    }

    @action setYear(value: string) {
        this.selectedYear = value;
        this.validateMonthMCS();
    }
    @action validateMonthMCS() {
        const d = new Date();
        let month = d.getMonth();
        let selectedMonthvalidation = new Date().getMonth() + 2;
        if (this.selectedYear == `${d.getFullYear()}`) {
            let abc = this.monthList.values.filter(
                (item1) => parseInt(item1.value) < selectedMonthvalidation
            );
            this.monthList.values = abc;
            this.selectedMonth = `${month + 1}`;
        } else {
            this.getMonthList();
        }
    }
    @action setProduct(value: string) {
        this.selectedProduct = value;
    }
    @action setProductLine(value: any) {
        this.selectedProductLine = value;
    }

    //added
    @action setMultiProduct(value: string) {
        this.selectedProductNames.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedProductNames.values = [];
                    this.productList.values.length > 0 &&
                        this.productList.values.map((item, i) => {
                            this.selectedProductNames.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.productList.values.filter((i: any) => {
                        return i.value == value[offset];
                    });
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedProductNames.values.push(selectedoption[0].label);
                    }
                }
            }
        }
        this.selectedProducts = value;
    }

    @action setMultiProductBySubCat(value: string) {
        this.selectedProductNamesBySubCat.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedProductNamesBySubCat.values = [];
                    this.productListBySubCat.values.length > 0 &&
                        this.productListBySubCat.values.map((item, i) => {
                            this.selectedProductNamesBySubCat.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.productListBySubCat.values.filter(
                        (i: any) => {
                            return i.value == value[offset];
                        }
                    );
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedProductNamesBySubCat.values.push(
                            selectedoption[0].label
                        );
                    }
                }
            }
        }
        this.selectedProductsBySubCat = value;
    }
    //added
    @action setMultiCarriers(value: string) {
        this.selectedCompanys = value;
    }

    @action setPolicyStatus(value: any) {
        this.selectedPolicyStatus = value;
    }
    @action setMinPremium(value: string) {
        this.minPremium = +value;
    }
    @action setCustomerState(value: string) {
        this.selectedCustomerState = value;
    }
    @action setAgentStatus(value: string) {
        this.selectedAgentStatus = value;
    }
    @action setLicenseStatus(value: string) {
        this.selectedLicenseStatus = value;
    }
    @action setNotesInXDays(value: string) {
        this.selectedNotesInXDays = value;
    }
    @action setLeadSource(value: string) {
        this.selectedLeadSource = value;
    }
    @action setTeam(value: string) {
        this.selectedTeam = value;
    }
    @action setPrdtCat(value: any) {
        this.selectedPrdtCat = value;
        this.selectedPrdtCatNames.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedPrdtCatNames.values = [];
                    this.prdtCatList.values.length > 0 &&
                        this.prdtCatList.values.map((item, i) => {
                            this.selectedPrdtCatNames.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.prdtCatList.values.filter((i: any) => {
                        return i.value == value[offset];
                    });
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedPrdtCatNames.values.push(selectedoption[0].label);
                    }
                }
            }
        }
    }
    @action setPrdtSubCat(value: any) {
        this.selectedPrdtSubCat = value;
        this.selectedPrdtSubCatNames.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedPrdtSubCatNames.values = [];
                    this.prdtSubCatList.values.length > 0 &&
                        this.prdtSubCatList.values.map((item, i) => {
                            this.selectedPrdtSubCatNames.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.prdtSubCatList.values.filter((i: any) => {
                        return i.value == value[offset];
                    });
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedPrdtSubCatNames.values.push(selectedoption[0].label);
                    }
                }
            }
        }
    }
    @action setSelectedPrdtBySubCat(value: any) {
        this.selectedProductsBySubCat = value;
        this.selectedPrdtNamesbySubCat.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedPrdtNamesbySubCat.values = [];
                    this.productListBySubCat.values.length > 0 &&
                        this.productListBySubCat.values.map((item, i) => {
                            this.selectedPrdtNamesbySubCat.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.productListBySubCat.values.filter(
                        (i: any) => {
                            return i.value == value[offset];
                        }
                    );
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedPrdtNamesbySubCat.values.push(selectedoption[0].label);
                    }
                }
            }
        }
    }

    @action setPrdtBySubCat(value: any) {
        this.selectedPrdtSubCatAll = value;
        this.selectedPrdtBySubCatNames.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedPrdtBySubCatNames.values = [];
                    this.productSubCategoryList.values.length > 0 &&
                        this.productSubCategoryList.values.map((item, i) => {
                            this.selectedPrdtBySubCatNames.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.productSubCategoryList.values.filter(
                        (i: any) => {
                            return i.value == value[offset];
                        }
                    );
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedPrdtBySubCatNames.values.push(selectedoption[0].label);
                    }
                }
            }
        }
    }

    @action setPrdt(value: any) {
        this.selectedPrdtBySubCat = value;
        this.selectedPrdtNames.values = [];
        if (value.length > 0) {
            for (var offset = 0; offset < value.length; offset++) {
                if (value[offset] == "0") {
                    this.selectedPrdtNames.values = [];
                    this.productBySubCategoryList.values.length > 0 &&
                        this.productBySubCategoryList.values.map((item, i) => {
                            this.selectedPrdtNames.values.push(item.label);
                        });
                    break;
                } else {
                    let selectedoption = this.productBySubCategoryList.values.filter(
                        (i: any) => {
                            return i.value == value[offset];
                        }
                    );
                    if (selectedoption && selectedoption.length > 0) {
                        this.selectedPrdtNames.values.push(selectedoption[0].label);
                    }
                }
            }
        }
    }

    @action setAgentHomeState(value: any) {
        this.selectedAgentHomeState = value;
    }
    @action setAgentLicenseState(value: any) {
        this.selectedAgentLicenseState = value;
    }
    @action setPolicyNumber(value: any) {
        this.policyNumber = value;
    }
    @action setInsuredDetail(value: any) {
        this.insuredDetails = value;
    }
    @action setPrdtSubCatAll(value: any) {
        this.selectedPrdtSubCatAll = value;
    }

    @action displayMessage(message: string) {
        this.reportAlertMessage = message;
        this.isReportAlertVisible = true;
    }
    @action hideMessage() {
        this.isReportAlertVisible = false;
    }

    @action getAgentMarketers = async (value: string) => {
        try {
            let result = await ProducerStore.getAgentMarketerLookup(
                value,
                0,
                100,
                undefined,
                undefined
            );
            var values = null;

            if (result && result.data) {
                this.agentMarketersList.values = [];
                for (let item of result.data) {
                    this.agentMarketersList.values.push({
                        label: "" + item.text,
                        value: "" + item.value,
                    });
                }
            }
        } catch (e) {
            this.agentMarketersList.values = [];
        }
    };
    @action getAgentAddressTypes = async () => {
        this.agentAddressTypesList.values = [];
        try {
            let response = await ProducerStore.getAddressTypesLookup();
            if (response) {
                Utils.mapListItemsToDropdown(
                    response,
                    this.agentAddressTypesList,
                    "",
                    ""
                );
            }
        } catch (e) {
            this.agentAddressTypesList.values = [];
        }
    };

    @action getAgentContactTypes = async () => {
        try {
            let response = await ReportStore.getAgentContactTypes();
            if (response) {
                this.agentContactTypesList.values = response;
            } else this.agentContactTypesList.values = [];
        } catch (e) {
            this.agentContactTypesList.values = [];
        }
    };
    @action fillterAgentMarketers = async (value: string) => {
        await this.getAgentMarketers(value);
    };

    // ===================onChange methods=======================

    @observable setControlsBasedOnSelection = async () => {
        this.isLoading = true;
        this.resetControlVisibility();
        this.resetValues();
        this.getStartEndBasedOnDate();
        this.getLicenseStartEndBasedOnDate();
        this.hideMessage();

        switch (this.selectedReportType) {
            case "0":
                if (Utils.hasUserPermission(Permission.ViewAgentDebtReport)) {
                    this.showWarning = false;
                    await this.getCompanyList();
                    this.isStartDate = true;
                    this.isEndDate = true;
                    this.isCompany = true;
                    this.isAgent = true;
                } else {
                    this.showWarning = true;
                }
                break;
            case "1":
                await this.getCompanyGroupList();
                await this.getCompanyList();
                await this.getAgentStatusList();
                await this.getLicenseContractStatusList();
                await this.getMarketersList();
                await this.getAgentHomeStateList();
                await this.getAgentLicenseStateList();
                await this.getPrdtCatList();
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgentStatus = true;
                this.isLicenseStatus = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                this.isCompanyGroup = true;
                this.isPrdtCat = false;
                this.isPrdtSubCat = false;
                break;
            case "2":
                await this.getAllMarketersList();
                await this.getAgentStatusList();
                await this.getNotesInXDaysList();
                await this.getUsersList();
                this.isMarketers = true;
                this.isAgentStatus = true;
                this.isNotesInDays = true;
                this.isUsers = true;
                break;
            case "3":
                if (Utils.hasUserPermission(Permission.ViewCarrierUpdates)) {
                    this.showWarning = false;
                    await this.getCompanyList();
                    await this.getAgentLicenseStateList();
                    this.isCompany = true;
                    this.isAgentLicenseState = true;
                } else {
                    this.showWarning = true;
                }
                break;
            case "4":
                if (Utils.hasUserPermission(Permission.ViewCommissionReport)) {
                    this.showWarning = false;
                    await this.getCommissionDate();
                    await this.getCompanyList();
                    await this.getProductList();
                    await this.getProductSubCategoryList();
                    let subCatIds: number[] = [0];
                    await this.getproductBySubCategoryList(subCatIds);
                    this.isStartDate = true;
                    this.isEndDate = true;
                    this.isCompany = true;
                    this.isCommissionDate = true;
                    this.isAgentByWritingNumber = true;
                    this.isGetAllSubCategoryByDefault = true;
                    this.isGetProductBySubCategory = true;
                    this.isPayToAgent = true;
                    this.isInsured = true;
                    this.isCommisionDateDisabled = true;
                } else {
                    this.showWarning = true;
                }
                break;
            case "5":
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getWhichDateEnterd();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgent = true;
                this.isWhichDate = true;
                this.isWhichDateDisabled = true;
                this.isMarketerDisabled = Utils.isDownlineAgency();
                break;
            case "6":
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getTeamList();
                await this.getWhichDateEnterd();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isCompany = true;
                this.isTeam = true;
                this.isMarketers = true;
                this.isWhichDate = true;
                this.isWhichDateDisabled = true;
                break;
            case "7":
                await this.getWhichDateEnterd();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isWhichDate = true;
                this.isWhichDateDisabled = true;
                break;
            case "8":
                await this.getDivisionList();
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getAgentHomeStateList();
                await this.getAgentLicenseStateList();
                await this.getWhichDateEnterd();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                this.isWhichDate = true;
                this.isWhichDateDisabled = true;
                break;
            case "9":
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getAgentLicenseStateList();
                await this.getWhichDateEnterd();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgentLicenseState = true;
                this.isUpline = true;
                this.isWhichDate = true;
                this.isWhichDateDisabled = true;
                break;
            case "10":
                await this.getCompanyList();
                this.isUpline = true;
                this.isCompany = true;
                break;
            case "11":
                await this.getCompanyList();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isCompany = true;
                break;
            case "12":
                await this.getMarketersList();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isMarketers = true;
                break;
            case "13":
                if (Utils.hasUserPermission(Permission.ViewMarketingEventReport)) {
                    this.showWarning = false;
                    this.isStartDate = true;
                    this.isEndDate = true;
                } else {
                    this.showWarning = true;
                }
                break;
            case "14":
                await this.getCompanyList();
                await this.getAgentStatusList();
                await this.getLicenseStatusList();
                await this.getMarketersList();
                await this.getLeadSourceList();
                await this.getAgentHomeStateList();
                await this.getAgentLicenseStateList();
                await this.getWhichDateEnterd();
                await this.getProductList();
                await this.getPolicyStatusList();
                await this.getPrdtCatList();
                await this.getCustomerStateList();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isWhichDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgent = true;
                this.isProduct = true;
                this.isLocation = true;
                this.isPolicyStatus = true;
                this.isCustomerState = true;
                this.isLeadSource = true;
                this.isPrdtCat = true;
                this.isPrdtSubCat = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                this.isHideOVerrides = true;
                break;
            case "15":
            case "16":
                this.isStartDate = true;
                this.isEndDate = true;
                break;
            case "17":
                await this.getCompanyList();
                await this.getAgentStatusList();
                await this.getMarketersList();
                await this.getLeadSourceList();
                await this.getAgentHomeStateList();
                await this.getAgentLicenseStateList();
                await this.getWhichDateEnterd();
                await this.getProductList();
                await this.getPolicyStatusList();
                await this.getPrdtCatList();
                await this.getCustomerStateList();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isWhichDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgent = true;
                this.isProduct = true;
                this.isPolicyStatus = true;
                this.isCustomerState = true;
                this.isLeadSource = true;
                this.isPrdtCat = true;
                this.isPrdtSubCat = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                this.isHidePrivateNotes = true;
                break;
            case "18":
                await this.getCompanyList();
                await this.getAgentStatusList();
                await this.getMarketersList();
                await this.getLeadSourceList();
                await this.getAllStatesLicenseList();
                await this.getAllStatesHomeList();
                await this.getWhichDateEnterd();
                await this.getProductList();
                await this.getPolicyStatusList();
                await this.getPrdtCatList();
                await this.getAllStatesCustomerList();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isWhichDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgent = true;
                this.isProduct = true;
                this.isPolicyStatus = true;
                this.isCustomerState = true;
                this.isLeadSource = true;
                this.isPrdtCat = true;
                this.isPrdtSubCat = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                break;
            case "19":
                await this.getWhichDateEnterd();
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getProductList();
                await this.getPolicyStatusList();
                await this.getAllStatesLicenseList();
                await this.getLeadSourceList();
                await this.getPrdtCatList();
                await this.getAllStatesHomeList();
                await this.getAllStatesCustomerList();
                await this.loadContractAgentGroupList();
                this.isWhichDate = true;
                this.isStartDate = true;
                this.isEndDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isAgent = true;
                this.isProduct = true;
                this.isLocation = true;
                this.isPolicyStatus = true;
                this.isCustomerState = true;
                this.isLeadSource = true;
                this.isPrdtCat = true;
                this.isPrdtSubCat = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                this.isHideOVerrides = true;
                this.isProductionAgentGroup = true;
                break;
            case "20":
                await this.getWhichDateEnterd();
                await this.getMarketersList();
                await this.getCompanyList();
                await this.getProductList();
                await this.getPolicyStatusList();
                await this.getAllStatesLicenseList();
                await this.getPrdtCatList();
                await this.getAllStatesHomeList();
                await this.getAllStatesCustomerList();
                this.isAgent = true;
                this.isWhichDate = true;
                this.isStartDate = true;
                this.isEndDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isCompany = true;
                this.isProduct = true;
                this.isPolicyStatus = true;
                this.isCustomerState = true;
                this.isPrdtCat = true;
                this.isPrdtSubCat = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                break;
            case "21":
                this.getDatesForTripPoint();
                await this.getWhichDateEnterd();
                await this.getMarketersList();
                await this.getAgentHomeStateList();
                await this.getAgentLicenseStateList();
                this.isWhichDate = true;
                this.isStartDate = true;
                this.isEndDate = true;
                this.isUpline = true;
                this.isMarketers = true;
                this.isWhichDateDisabled = true;
                this.isAgentHomeState = true;
                this.isAgentLicenseState = true;
                break;
            case "22":
                await this.getLeadSourceList();
                await this.getAgentStatusList();
                await this.getMarketersList();
                await this.getEOTypes();
                await this.getPrincipalAgentStatusTypes();
                await this.loadAgentGroupList();
                await this.getCountyList();

                this.isLeadSourceMultiSelect = true;
                this.isUpline = false;
                this.isAgent = true;
                this.isAgentStatusMultiSelect = true;
                this.isMarketers = true;
                this.isEOType = true;
                this.isPrincipalAgent = true;
                this.isContactType = false;
                this.isAgentGroup = true;
                this.isCounty = true;
                this.isImmediateReportingUpline = true;
                break;

            case "23":
                if (
                    Utils.hasUserPermission(Permission.ViewProductionViaReconciledRevenue)
                ) {
                    await this.getCompanyList();
                    await this.getPrdtCatList();
                    this.showWarning = false;
                    this.isEdpStartDate = true;
                    this.isEdpEndDate = true;
                    this.isEdpAgent = true;
                    this.isCompany = true;
                    this.isMultiProduct = true;
                    this.isPrdtCat = true;
                    this.isPrdtSubCat = true;
                    this.startDateErr = false;
                    this.endDateErr = false;
                    this.yearGapErr = false;
                    this.agentTitle = "";
                    this.awnsTitle = "";
                } else {
                    this.showWarning = true;
                }
                break;
            case "24":
                await this.getCompanyGroupList();
                await this.getCompanyList();
                await this.getLicenseStatusList();
                var catId: number[] = [];
                await this.getPrdtSubCatList(catId);
                await this.getProductList();
                await this.getMarketersList();
                await this.getAgentLicenseStateList();
                this.StartDateTitle = "Contract Start Date";
                this.EndDateTitle = "Contract End Date";
                this.startDate = new Date();
                this.endDate = undefined;
                this.isAgent = true;
                this.isCompanyGroup = true;
                this.isCompany = true;
                this.isLicenseStatus = true;
                this.isStartDate = true;
                this.isEndDate = true;
                this.isPrdtCat = false;
                this.isPrdtSubCat = true;
                this.isMultiProductBySubCat = true;
                this.isMarketers = true;
                this.isAgentLicenseState = true;
                this.isUpline = true;
                break;
            case "25":
                await this.getAgentHomeStateList();
                await this.getProductLinesList();
                await this.getStateLicenseStatusList();
                this.showWarning = false;
                this.isStateAgent = true;
                this.isLicenseState = true;
                this.isLicenseStartDate = true;
                this.isLicenseEndDate = true;
                this.isProductLine = true;
                this.isIssueDate = true;
                this.isExpirationDate = true;
                this.isStateLicenseStatus = true;
                break;

            case "26":
                this.showWarning = false;
                await this.getCommissionDate();
                this.isStartDate = true;
                this.isEndDate = true;
                this.isCommisionDateDisabled = true;
                break;

            case "27":
                await this.getAgentStatusList();
                await this.getMarketersList();
                await this.getAgentAddressTypes();
                await this.getAgentContactTypes();
                await this.getSMSEnabledList();
                await this.getCountyList();
                this.isAgentStatusForDemography = true;
                this.isAgent = true;
                this.isMarketers = true;
                this.isAgentAddressTypes = true;
                this.isAgentContactTypes = true;
                this.isSMSEnabled = true;
                this.isCounty = true;
                break;
            default:
                this.visibleWhenReportType = false;
                this.resetControlVisibility();
                break;
        }
        this.isLoading = false;
        this.visibleWhenReportType = true;
    };

    @action validateDates = (val: string) => {
        if (this.startDate != undefined && this.endDate != undefined) {
            if (this.endDate !== undefined) {
                if (this.startDate > this.endDate) {
                    if (val === "start") {
                        this.startDateErr = true;
                        this.endDateErr = false;
                    }
                    if (val === "end") {
                        this.endDateErr = true;
                        this.startDateErr = false;
                    }
                } else {
                    this.startDateErr = false;
                    this.endDateErr = false;
                }
            }
        }
    };

    @action validateEdpDates = (val: string) => {
        this.yearGapErr = false;
        this.startDateErr = false;
        this.endDateErr = false;
        this.awnsTitle = "";
        this.agentTitle = "";
        if (this.edpStartDate != undefined && this.edpEndDate != undefined) {
            if (this.edpStartDate > this.edpEndDate) {
                if (val === "start") {
                    this.startDateErr = true;
                    this.endDateErr = false;
                }
                if (val === "end") {
                    this.endDateErr = true;
                    this.startDateErr = false;
                }
            } else {
                this.startDateErr = false;
                this.endDateErr = false;
                if (
                    this.edpEndDate.getTime() - this.edpStartDate.getTime() >
                    31536000000
                ) {
                    this.yearGapErr = true;
                }
            }
        }
    };

    @action validateLicenseDates = (val: string) => {
        if (
            this.licenseStartDate != undefined &&
            this.licenseEndDate != undefined
        ) {
            if (this.licenseStartDate > this.licenseEndDate) {
                if (val === "start") {
                    this.startDateErr = true;
                    this.endDateErr = false;
                }
                if (val === "end") {
                    this.endDateErr = true;
                    this.startDateErr = false;
                }
            } else {
                this.startDateErr = false;
                this.endDateErr = false;
            }
        }
    };

    @action downloadReport = async (result: ReportFile, name: string) => {
        if (result) {
            await fetch(`data:${result.contentType};base64,${result.data}`)
                .then((response) => response.blob())
                .then(function (myBlob) {
                    var link = document.createElement("a");
                    var url = window.URL.createObjectURL(myBlob);
                    let n = result.fileName ? result.fileName.split(".")[0] : "";
                    link.href = url;
                    link.download = n !== "" ? n : name;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    link.remove();
                });
        }
    };

    @action hasAnySelection = (selectedList: any) => {
        return selectedList && selectedList.length > 0;
    };

    @action showCreatingReport() {
        this.hideMessage();
        this.isLoading = true;
        this.isMessageVisible = false;
        this.response = "";
    }
    @action hideCreatingReport() {
        this.isLoading = false;
    }
    @action resetToasters = async() => {
        this.showGenerateToast = false;
      };
    @action createReport = async () => {
        try {
            var reportNumber = this.selectedReportType;
            var report = this.reportTypes.values.find((r) => r.value == reportNumber);
            var reportName = report ? report.label : "unknown";  
            this.reportName =  reportName;          
            googleTagManager.trackAction("report_generated", {
                feature:routes.reports.name,
                report_name: `${reportName}`,
                report_format: `${this.selectedFormat}`,
                level: "Execute",
                report_type:`${reportName}`
            });           
        } catch (ex) {
            console.error("Issue selecting report for tracking");
        }

        var marketerIds: number[] | undefined;
        var uplineId: number | undefined;
        var licenseStatusId: number | undefined;
        var companyGroupId: number | undefined;
        var companyId: number | undefined;
        var leadSource: number | undefined;
        var leadSourceIds: number[] | null | undefined;
        var agentGroupIds: number[] | null | undefined;
        var agentStatusId: number | undefined;
        var agentStatusIds: number[] | null | undefined;
        var licenseStates: number[] | null | undefined;
        var homeStates: string[] | null | undefined;
        var fileType: any;
        var agentId: number | undefined;
        var uplineAgentId: number | undefined;
        var startDateIn: Date | undefined;
        var stopDateIn: Date | undefined;
        var teamId: number | undefined;
        var policyStatuses: number[] | null | undefined;
        var whichDate: string | null | undefined;
        var commissionDate: string | null | undefined;
        var productId: number | undefined;
        var customerState: number | null | undefined;
        var categories: number[] | null | undefined;
        var subCategories: number[] | null | undefined;
        var addressType: number | undefined;
        var products: number[] | null | undefined;
        var prodSubCategoriesId: number[] | null | undefined;
        var prodbySubCategoriesId: number[] | null | undefined;
        var policyNumber: number | undefined;
        var writingNumber: number | undefined;
        var payToAgent: number | undefined;
        var insuredId: number | undefined;
        var productLineId: number | undefined;
        var contactType: number | undefined;
        var eoType: number | undefined;
        var principalAgentType: number | undefined;
        var agentImmediateUplineId: number | undefined;
        startDateIn = this.startDate;
        stopDateIn = this.endDate;
        this.showCreatingReport();
        try {
            switch (this.selectedReportType) {
                case "0": // Agent Debt
                   stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        let res = await AccountStore.debtReport(
                            this.selectedAgentId,
                            startDateIn,
                            stopDateIn,
                            Number(this.selectedCompany),
                            this.selectedFormat === "Excel" ? 1 : 0
                        );
                        this.downloadReport(res, "Agent_Debt");
                    }

                    break;

                case "1": {
                    // Contracted Agent List
                    this.showGenerateToast = false;
                    if (!this.startDateErr && !this.endDateErr) {
                        marketerIds = this.selectedMarketers;
                        uplineId = this.uplineId;
                        licenseStatusId = parseInt(this.selectedLicenseStatus);
                        companyGroupId = parseInt(this.selectedCompanyGroup);
                        companyId = parseInt(this.selectedCompany);
                        leadSource = parseInt(this.selectedLeadSource);
                        agentStatusId = parseInt(this.selectedAgentStatus);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;
                        let res = await ProducerStore.contractedAgentListReportBackground(
                            marketerIds,
                            uplineId,
                            licenseStatusId,
                            companyGroupId,
                            companyId,
                            leadSource,
                            agentStatusId,
                            licenseStates || undefined,
                            homeStates || undefined,
                            categories || undefined,
                            subCategories || undefined,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }
                    break;
                }
                case "2": {
                    // Agent Witout Notes
                    marketerIds = this.selectedMarketers;
                    agentStatusId = parseInt(this.selectedAgentStatus);
                    var notesInXDays: number | undefined = parseInt(
                        this.selectedNotesInXDays
                    );
                    fileType = this.selectedFormat === "Excel" ? 1 : 0;
                    let res = await ProducerStore.agentWithoutNotesReport(
                        marketerIds,
                        parseInt(this.selectedUser),
                        agentStatusId,
                        notesInXDays,
                        fileType
                    );
                    this.downloadReport(res, "AgentWithoutNotes");
                    break;
                }
                case "3": {
                    // Carrier Updates
                    companyId = parseInt(this.selectedCompany);
                    licenseStates = this.selectedAgentLicenseState;
                    fileType = this.selectedFormat === "Excel" ? 1 : 0;

                    let res = await ContractsStore.carrierUpdatesReport(
                        companyId,
                        licenseStates || undefined,
                        fileType
                    );
                    this.downloadReport(res, "CarrierUpdates");
                    break;
                }
                case "4": // Commissions
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        companyId = parseInt(this.selectedCompany);
                        prodSubCategoriesId = [0];
                        prodbySubCategoriesId = [0];
                        if (this.selectedPrdtSubCatAll != null) {
                            var prdtSubCatAll = this.selectedPrdtSubCatAll.filter(function (
                                element: any
                            ) {
                                return element !== undefined;
                            });
                            if (prdtSubCatAll.length > 0) prodSubCategoriesId = prdtSubCatAll;
                        }
                        if (this.selectedPrdtBySubCat != null) {
                            var prdtBySubCat = this.selectedPrdtBySubCat.filter(function (
                                element: any
                            ) {
                                return element !== undefined;
                            });
                            if (prdtBySubCat.length > 0) prodbySubCategoriesId = prdtBySubCat;
                        }
                        insuredId = this.insuredDetailsId;
                        writingNumber = this.uplineId;
                        payToAgent = this.payToAgentId;
                        commissionDate = this.selectedCommissionDate;
                        let res = await AccountStore.getCommissionsReport(
                            startDateIn,
                            stopDateIn,
                            companyId,
                            prodSubCategoriesId || undefined,
                            prodbySubCategoriesId || undefined,
                            insuredId,
                            writingNumber,
                            payToAgent,
                            commissionDate,
                            this.selectedFormat === "Excel" ? 1 : 0
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                case "5": {
                    // Contract Invite
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        uplineAgentId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        if (this.upline === "") uplineAgentId = 0;
                        if (this.selectedAgent === "") agentId = 0;
                        if (this.isMarketerDisabled && this.upline === "") {
                            this.uplineMandatory = true;
                        } else {
                            this.uplineMandatory = false;
                            let res = await ContractsStore.contractInviteReport(
                                agentId,
                                uplineAgentId,
                                marketerIds,
                                companyId,
                                startDateIn,
                                stopDateIn,
                                fileType
                            );

                            this.downloadReport(res, "ContractInvite");

                        }
                    }

                    break;
                }

                case "6": {
                    // Contracting Sent
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        companyId = parseInt(this.selectedCompany);
                        teamId = parseInt(this.selectedTeam);
                        marketerIds = this.selectedMarketers;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.contractingSentReport(
                            companyId,
                            startDateIn,
                            stopDateIn,
                            teamId,
                            marketerIds,
                            fileType
                        );

                        this.downloadReport(res, "ContractingSent");
                    }

                    break;
                }

                case "7": {
                    // Contracting Sent No FUP In 30 Days
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        teamId = 0;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.contractingSentNoFUPIn30DaysReport(
                            startDateIn,
                            stopDateIn,
                            teamId,
                            fileType
                        );

                        this.downloadReport(res, "ContractingSentNoFUPIn30Days");
                    }

                    break;
                }

                case "8": {
                    // Contract By Marketer
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        companyId = parseInt(this.selectedCompany);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        marketerIds = this.selectedMarketers;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.contractsByMarketerReport(
                            companyId,
                            startDateIn,
                            stopDateIn,
                            licenseStates || undefined,
                            homeStates || undefined,
                            marketerIds || undefined,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "9": {
                    // Contracts without Business
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        companyId = parseInt(this.selectedCompany);
                        licenseStates = this.selectedAgentLicenseState;
                        marketerIds = this.selectedMarketers;
                        uplineAgentId = this.uplineId;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.contractsWithNoBusinessReport(
                            startDateIn,
                            stopDateIn,
                            companyId,
                            licenseStates || undefined,
                            marketerIds || undefined,
                            uplineAgentId,
                            fileType
                        );

                        this.downloadReport(res, "ContractsWithNoBusiness");
                    }

                    break;
                }

                case "10": {
                    // Downline Agents
                    companyId = parseInt(this.selectedCompany);
                    fileType = this.selectedFormat === "Excel" ? 1 : 0;

                    let res = await ContractsStore.downlineAgentsReport(
                        this.uplineId,
                        companyId,
                        fileType
                    );

                    this.downloadReport(res, "DownlineAgents");
                    break;
                }

                case "11": {
                    // Fax Confirmation
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    companyId = parseInt(this.selectedCompany);
                    fileType = this.selectedFormat === "Excel" ? 1 : 0;

                    let res = await ContractsStore.faxConfirmationReport(
                        startDateIn,
                        stopDateIn,
                        companyId,
                        fileType
                    );
                    this.downloadReport(res, "FaxConfirmation");
                    break;
                }

                case "12": {
                    // License Count By Company
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        marketerIds = this.selectedMarketers;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.licenseCountByCompanyReport(
                            startDateIn,
                            stopDateIn,
                            marketerIds,
                            fileType
                        );
                        this.downloadReport(res, "ContractCountByCompany");
                    }
                    break;
                }

                case "13": // Marketing Events
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        let res = await BusinessUnitConfigStore.getMarketingEventsReport(
                            startDateIn,
                            stopDateIn,
                            this.selectedFormat === "Excel" ? 1 : 0
                        );
                        this.downloadReport(res, "Marketing Events");
                    }

                    break;

                case "14": {
                    // Marketer Production Report
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        whichDate = this.selectedWhichDateEntered;
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        agentId = this.selectedAgentId;
                        productId = parseInt(this.selectedProduct);
                        policyStatuses = this.selectedPolicyStatus;
                        customerState = parseInt(this.selectedCustomerState);
                        leadSource = parseInt(this.selectedLeadSource);
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        homeStates = this.selectedAgentHomeState;
                        licenseStates = this.selectedAgentLicenseState;
                        var locationAgentId: number | undefined = this.locationAgentId;
                        var isHideOverrides: boolean | undefined = this.hideOverRides;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ProducerStore.marketerProductionReport(
                            whichDate,
                            startDateIn,
                            stopDateIn,
                            uplineId,
                            marketerIds,
                            companyId,
                            agentId,
                            productId,
                            locationAgentId,
                            policyStatuses || undefined,
                            customerState,
                            leadSource,
                            categories || undefined,
                            subCategories || undefined,
                            homeStates || undefined,
                            licenseStates || undefined,
                            isHideOverrides,
                            fileType
                        );
                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "15": // On Hold Agent Commissions
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        var fileType2: FileType2 | undefined =
                            this.selectedFormat === "Excel" ? 1 : 0; // we need to retain this as the service generated a separate FileType2 object

                        let res = await AccountStore.onHoldAgentCommissionsReport(
                            startDateIn,
                            stopDateIn,
                            fileType2
                        );
                        this.downloadReport(res, "OnHoldAgentCommissions");
                    }
                    break;

                case "16": // Orders By Division
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.ordersByDivisionReport(
                            startDateIn,
                            stopDateIn,
                            0,
                            fileType
                        );
                        this.downloadReport(res, "OrdersByDivision");
                    }
                    break;

                case "17": {
                    // Policy Info
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        productId = parseInt(this.selectedProduct);
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        policyStatuses = this.selectedPolicyStatus;
                        whichDate = this.selectedWhichDateEntered;
                        leadSource = parseInt(this.selectedLeadSource);
                        customerState = parseInt(this.selectedCustomerState);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        var isHidePrivateNotes: boolean | undefined = this.hidePrivateNotes;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await InsuredStore.policiesInfoReport(
                            agentId,
                            productId,
                            uplineId,
                            marketerIds,
                            companyId,
                            startDateIn,
                            stopDateIn,
                            policyStatuses || undefined,
                            whichDate,
                            leadSource,
                            customerState,
                            licenseStates || undefined,
                            homeStates || undefined,
                            categories || undefined,
                            subCategories || undefined,
                            isHidePrivateNotes,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "18": {
                    // Policies Summary
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        productId = parseInt(this.selectedProduct);
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        policyStatuses = this.selectedPolicyStatus;
                        whichDate = this.selectedWhichDateEntered;
                        leadSource = parseInt(this.selectedLeadSource);
                        customerState = parseInt(this.selectedCustomerState);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await InsuredStore.policiesSummaryReport(
                            agentId,
                            productId,
                            uplineId,
                            marketerIds,
                            companyId,
                            startDateIn,
                            stopDateIn,
                            policyStatuses || undefined,
                            whichDate,
                            leadSource,
                            customerState,
                            licenseStates || undefined,
                            homeStates || undefined,
                            categories || undefined,
                            subCategories || undefined,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "19": {
                    // Production Report
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        productId = parseInt(this.selectedProduct);
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        policyStatuses = this.selectedPolicyStatus;
                        whichDate = this.selectedWhichDateEntered;
                        leadSource = parseInt(this.selectedLeadSource);
                        customerState = parseInt(this.selectedCustomerState);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;
                        agentGroupIds = this.selectedAgentGroups;
                        var locationAgentId: number | undefined = this.locationAgentId;
                        var isHideOverrides: boolean | undefined = this.hideOverRides;
                        let res = await ProducerStore.productionReport(
                            agentId,
                            productId,
                            uplineId,
                            marketerIds,
                            companyId,
                            startDateIn,
                            stopDateIn,
                            policyStatuses || undefined,
                            whichDate,
                            leadSource,
                            customerState,
                            licenseStates || undefined,
                            homeStates || undefined,
                            categories || undefined,
                            subCategories || undefined,
                            locationAgentId,
                            isHideOverrides,
                            fileType,
                            agentGroupIds
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "20": {
                    // Production Summary
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        productId = parseInt(this.selectedProduct);
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        companyId = parseInt(this.selectedCompany);
                        policyStatuses = this.selectedPolicyStatus;
                        whichDate = this.selectedWhichDateEntered;
                        leadSource = parseInt(this.selectedLeadSource);
                        customerState = parseInt(this.selectedCustomerState);
                        licenseStates = this.selectedAgentLicenseState;
                        homeStates = this.selectedAgentHomeState;
                        categories = this.selectedPrdtCat;
                        subCategories = this.selectedPrdtSubCat;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ProducerStore.productionSummaryReport(
                            agentId,
                            productId,
                            uplineId,
                            marketerIds,
                            companyId,
                            startDateIn,
                            stopDateIn,
                            policyStatuses || undefined,
                            whichDate,
                            leadSource,
                            customerState,
                            licenseStates || undefined,
                            homeStates || undefined,
                            categories || undefined,
                            subCategories || undefined,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "21": {
                    // Trip Standings
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        homeStates = this.selectedAgentHomeState;
                        licenseStates = this.selectedAgentLicenseState;
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await InsuredStore.tripStandingsReport(
                            startDateIn,
                            stopDateIn,
                            homeStates || undefined,
                            licenseStates || undefined,
                            uplineId,
                            marketerIds,
                            fileType
                        );
                        this.downloadReport(res, "PointStandings");
                    }

                    break;
                }

                case "22": {
                    // Agent Details
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        agentStatusIds = this.selectedAgentStatuses;
                        eoType = parseInt(this.selectedEOType);
                        principalAgentType = parseInt(this.selectedPrincipalAgentStatus);
                        marketerIds = this.selectedMarketers;
                        leadSourceIds = this.selectedLeadSources;
                        agentGroupIds = this.selectedAgentGroups;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;
                        agentImmediateUplineId = this.selectedImmediateReportingUplineId;
                        let res = await ProducerStore.agentListReport(
                            agentId,
                            marketerIds,
                            agentStatusIds || undefined,
                            fileType,
                            eoType,
                            leadSourceIds || undefined,
                            agentGroupIds || undefined,
                            principalAgentType,
                            this.selectedCounties,
                            this.selectedImmediateReportingUplineId
                        );
                        this.showGenerateToast = true;
                    }

                    break;
                }

                case "24": {
                    // Contract Details Report
                    stopDateIn =
                        stopDateIn != null || stopDateIn != undefined
                            ? stopDateIn
                            : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        companyGroupId = parseInt(this.selectedCompanyGroup);
                        companyId = parseInt(this.selectedCompany);
                        licenseStatusId = parseInt(this.selectedLicenseStatus);
                        subCategories = this.selectedPrdtSubCat;
                        products = this.selectedProductsBySubCat;
                        uplineId = this.uplineId;
                        marketerIds = this.selectedMarketers;
                        licenseStates = this.selectedAgentLicenseState;
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;

                        let res = await ContractsStore.getContractDetailsReport(
                            agentId,
                            companyGroupId,
                            companyId,
                            licenseStatusId,
                            startDateIn,
                            stopDateIn,
                            subCategories || undefined,
                            products || undefined,
                            marketerIds || undefined,
                            licenseStates || undefined,
                            uplineId,
                            fileType
                        );

                        this.showGenerateToast = true;
                    }

                    break;
                }
                case "25": {
                    if (!this.startDateErr && !this.endDateErr) {
                        agentId = this.selectedAgentId;
                        licenseStates = this.selectedAgentHomeState;
                        productLineId =
                            this.selectedProductLine.length > 0
                                ? this.selectedProductLine.map((i: any) => parseInt(i))
                                : [];
                        fileType = this.selectedFormat === "Excel" ? 1 : 0;
                        let res = await ProducerStore.agentStateLicenseReport(
                            agentId,
                            licenseStates || undefined,
                            this.licenseStartDate,
                            this.licenseEndDate,
                            productLineId,
                            this.issueDate,
                            this.expirationDate,
                            this.selectedStatus,
                            fileType
                        );
                        if (res !== null && res !== "") {
                            this.downloadReport(res, "State Licenses_Report");
                        }
                    }
                    break;
                }

                case "26": {
                    // Commissions break down
                    stopDateIn = stopDateIn != null || stopDateIn != undefined ? stopDateIn : new Date();
                    if (!this.startDateErr && !this.endDateErr) {
                        commissionDate = this.selectedCommissionDate;
                        let res = await AccountStore.getCommissionsBreakdownReport(
                            startDateIn,
                            stopDateIn,
                            this.selectedFormat === "Excel" ? 1 : 0
                        );

                        this.showGenerateToast = true;
                    }
                    break;
                }
                case "27": {
                    // Demography and Contacts
                    agentId = this.selectedAgentId;
                    var agentStatuses = this.selectedAgentStatuses;
                    var contactTypeIds = this.selectedContactTypes;
                    marketerIds = this.selectedMarketers;
                    var addressTypeIds = this.selectedAddressTypes;
                    var smsEnabled = this.selectedSMSEnabled;
                    fileType = this.selectedFormat === "Excel" ? 1 : 0;

                    let res = await ProducerStore.getDemographyAndContactsReport(
                        agentId,
                        agentStatuses,
                        marketerIds,
                        addressTypeIds,
                        contactTypeIds,
                        smsEnabled,
                        fileType,
                        this.selectedCounties
                    );

                    this.showGenerateToast = true;
                    break;
                }

                default:
                    break;
            }

            var keyItems = this.reportTypes.values.filter(
                (x) => x.value == this.selectedReportType
            );
            if (keyItems != null) {
                var key = keyItems[0].label;
                this.actionLog(key);
            }
        } catch (e) {
            // Handling exception locally instead of relying on Interceptor, interceptor messaging not working in Prod
            PageContext.setModuleName(AppMenuItems.SelfServiceReports);
            PageContext.setResponseMessage(
                JSON.stringify(PageContext.getTimeOutResponse()),
                false,
                true
            );
            PageContext.setIsMessageVisible(true);
        }

        this.hideCreatingReport();
        setTimeout(async () => {
            this.resetToasters();
        }, 300);
    };

    @action actionLog = (key: any) => {
        var dto: ActionLogModel = {
            actionId: 42,
            entityId: 0,
            userId: UserContext.getUserId(),
            notes: "Dropdown selected value " + key,
        };
        IdentityStore.createActionLog(dto);
    };
}
