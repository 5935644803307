import { routes } from "../../../router";
import { action, computed, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import RouteList from "../../../infrastructure/RouteList";
import ProducerStore from "../../../store/ProducerStore";
import { AgentAppointmentsViewModel } from "./../../../viewModels/agents/AgentAppointmentsViewModel";
import ContractsStore from "../../../store/ContractsStore";
import Utils from "../../../infrastructure/Utils";
import { RtsCertificationModel } from "../../../services/ContractService";
import axios from "axios";
import PageContext from "../../../infrastructure/PageContext";

export class ManageRTSViewModel implements ViewModel {
    get CanClose(): boolean {
        throw new Error("Method not implemented.");
    }
    get CanRoute(): boolean {
        return true;
    }
    get IsLoaded(): boolean {
        throw new Error("Method not implemented.");
    }
    get IsLoading(): boolean {
        throw new Error("Method not implemented.");
    }
    Close(): void {
        throw new Error("Method not implemented.");
    }
    Route = async (currentRoute: RouteList): Promise<void> => { };

    @observable gridApi: any;
    @observable gridApiData: any;
    @observable rtsData: any = [];
    @observable rtsAttestationData: any = [];
    @observable agentId: number = 0;
    @observable agentNPN: string = "";
    @observable pageIndex: number = 0;
    @observable pageSize: number = 50;
    @observable skip: number = 0;
    @observable take: number = 10;
    @observable sortColumn: string = "npn";
    @observable sortAscending: boolean = true;
    @observable fullTextFilter: string = "";
    @observable filterList: any;
    @observable selectedUser: string = "";
    @observable isEditVisible: boolean = true;
    @observable isCancelVisible: boolean = false;
    @observable AgentAppointmentsViewModel: AgentAppointmentsViewModel =
        new AgentAppointmentsViewModel();
    @observable selectedKey: string | undefined = "";
    @observable selectedTabIndex?: number = 0;
    @observable selectedPanel: string = "Automated";

    @observable isDeleteModalVisible: boolean = false;
    @observable deleteRowData: any;
    @observable rtsToken: string = "";
    @observable rtsUrl: string = "";
    @observable isDeleteClicked: boolean = false;
    @observable paginatorId: string = "paginator" + Math.random();

    @action Load = async (agentId: any, npn: string) => {
        this.isDeleteClicked = false;
        this.agentId = agentId;
        this.agentNPN = npn;
        this.resetData();
        if (this.agentNPN) {
            await this.getRTSToken();
            Utils.listenScrollEvent(PageContext.updateHorizontalScrollBar);
            this.getData();
        }
    };

    @action resetData = () => {
        this.selectedTabIndex = 0;
        this.selectedPanel = "Automated";
        localStorage.setItem("rtsReviewAgent", "");
        this.gridApiData = [];
        this.rtsData = [];
        this.rtsAttestationData = [];
    };

    @action getRTSToken = async () => {
        let rtsData = await ContractsStore.getManageRtsToken();
        this.rtsToken = rtsData.access_token || "";
        this.rtsUrl = rtsData.url || "";
    };

    @computed get getTabIndex(): number {
        let tabIndex = 0;
        switch (this.selectedKey) {
            case "Manual":
                tabIndex = 0;
                break;
            case "Automated":
                tabIndex = 1;
                break;
            default:
                tabIndex = 0;
                break;
        }
        return tabIndex;
    }

    @action setDeleteConfirmModel = (value: boolean) => {
        this.isDeleteModalVisible = value;
    };

    @action deleteRtsDataConfirm = (value: any) => {
        this.deleteRowData = value;
        this.setDeleteConfirmModel(true);
    };

    @action deleteRtsData = async (agentName: string) => {
        this.isDeleteClicked = true;
        const urlAttestation =
            this.rtsUrl +
            "/attestation/" +
            this.agentNPN +
            "/" +
            this.deleteRowData.attestationId;
        await axios.delete(`${urlAttestation}`, { headers: this.getHeaders() });

        this.setDeleteConfirmModel(false);
        Utils.showSuccessToaster(
            "The attestation request for " +
            (agentName.includes("(") ? agentName.split("(")[0] : "") +
            " has been deleted",
            4000
        );
        this.getData();
        this.isDeleteClicked = false;
    };

    @action handleTabClick = (index: number | undefined, key: string): void => {
        this.selectedTabIndex = index;
        this.setSelectedPanel(key);
    };

    @action setSelectedPanel = async (value: string) => {
        this.selectedPanel = value;
        if (value == "Manual") {
            this.callAppointments();
        } else {
        }
    };

    @action callAppointments = (): void => {
        this.AgentAppointmentsViewModel.load(this.agentId);
    };

    @action getHeaders = () => {
        let headers = {
            "Content-Type": "application/json",
            Accept: "text/plain",
            //HOSTNAME: host,
            authorization: this.rtsToken,
        };
        return headers;
    };

    @observable addAttestationLookupModel: any = [];

    @action getData = async () => {
        const url = this.rtsUrl + "/Agents/rts/" + this.agentNPN;
        const urlAttestation = this.rtsUrl + "/attestation/" + this.agentNPN;
        try {
            let res = await axios.get(`${url}`, { headers: this.getHeaders() });
            this.rtsData = res.data;
        } catch (error) { }
        try {
            let attestation = await axios.get(`${urlAttestation}`, {
                headers: this.getHeaders(),
            });
            this.rtsAttestationData = attestation.data;
        } catch (error: any) {
            Utils.showWarningToaster(error.response.data, 4000);
        }

        let result = [];
        if (this.rtsData && this.rtsData.length > 0) {
            this.createAddAttestationLookupModel(this.rtsData);
            this.rtsData = this.rtsData.map((i: any) => {
                i.status = i.status == "Y" ? "Yes" : i.status == "N" ? "No" : i.status;
                return i;
            });

            let helper: any = {};
            result = this.rtsData.reduce(function (r: any, o: any) {
                let key =
                    o.carrier +
                    "-" +
                    o.producerId +
                    "-" +
                    o.planType +
                    "-" +
                    o.planYear +
                    "-" +
                    o.businessUnit +
                    "-" +
                    o.blobPath;
                if (!helper[key]) {
                    helper[key] = Object.assign({}, o);
                    r.push(helper[key]);
                } else {
                    if (helper[key].state && helper[key].state.indexOf(o.state) === -1) {
                        helper[key].state += ", " + o.state;
                    }
                }
                return r;
            }, []);
        }

        if (this.rtsAttestationData && this.rtsAttestationData.length > 0) {
            this.rtsAttestationData = this.rtsAttestationData.map((i: any) => {
                i.producerId = i.awn;
                i.businessUnit = i.bu;
                i.planType = i.product;
                i.status = i.rtsStatus
                    ? i.rtsStatus == "Y"
                        ? "Yes"
                        : i.rtsStatus == "N"
                            ? "No"
                            : i.rtsStatus
                    : "Admin Attested";
                i.isSelfAttested = i.isSelfAttested ? "TRUE" : "FALSE";
                return i;
            });
        }

        this.gridApiData = [...result, ...this.rtsAttestationData];
        PageContext.updateHorizontalScrollBar();
    };

    @action onRowsPerPage = async (value: number) => {
        this.pageSize = value;
    };

    onPaginationChanged = async (params: any = null, pageIndex: number) => {
        this.pageIndex = pageIndex;
        this.gridApi.paginationGoToPage(pageIndex);
    };

    @action rtsReview = async (agentName: string) => {
        await this.setSelectedAgentName();
        localStorage.setItem("rtsReviewAgent", "" + this.agentId + "^" + agentName);
        routes.rtsReview.push();
    };

    @action download = async () => {
        this.gridApi.exportDataAsExcel();
    };

    @action setSelectedAgentName = async () => {
        try {
            if (this.agentId != null && this.agentId != undefined) {
                var agentInfo = await ProducerStore.getAgentProfileInfoById(
                    this.agentId
                );
                if (agentInfo && agentInfo.name) {
                    let nameArray = agentInfo.name.split(" ");
                    if (nameArray.length > 1) {
                        this.selectedUser = nameArray[1] + ", " + nameArray[0];
                    } else {
                        this.selectedUser = nameArray[0];
                    }
                }
            }
        } catch (e) { }
    };

    @observable awn: string = "";
    @observable stateTempList: string[] = [];
    @observable carrierGroupList: string[] = [];

    @action getLookups = async (carrier: string) => {
        let attestationLookups = this.addAttestationLookupModel.filter(
            (i: any) => i.carrier == carrier
        );

        if (attestationLookups && attestationLookups.length > 0) {
            this.awn = attestationLookups[0].awn;
            this.stateTempList = attestationLookups[0].stateList;

            let rowNode = this.gridApi.getPinnedTopRow(0);
            rowNode.data.productList = attestationLookups[0].productList;
            rowNode.data.planYearList = attestationLookups[0].planYearList;
            rowNode.data.stateList = attestationLookups[0].stateList;
            rowNode.data.producerId = this.awn;
            this.refreshPinnedRow(rowNode);
        }
    };

    @action getStateLookup = async (carrier: string, product: string) => {
        let existingStates = [
            ...new Set(
                this.rtsAttestationData
                    .filter((i: any) => {
                        return i.carrier == carrier && i.planType == product;
                    })
                    .map((i: any) => {
                        return i.state;
                    })
                    .sort()
            ),
        ];

        let rowNode = this.gridApi.getPinnedTopRow(0);
        rowNode.data.stateList = this.stateTempList.filter((i) => {
            return !existingStates.includes(i);
        });
        this.refreshPinnedRow(rowNode);
    };

    @action addAttestation = async () => {
        let editRows = this.gridApiData.filter((i: any) => i.edit);
        if (editRows.length == 0) {
            this.awn = "";
            let productList = [];
            let stateList = [];
            let planYearList = [];
            productList.push("Select Product");
            stateList.push("Select State");
            planYearList.push("Select Plan Year");
            planYearList.push("2024");
            planYearList.push("2025");

            let rtsData = {
                agentId: this.agentId,
                npn: this.agentNPN,
                carrier: "",
                awn: "",
                state: "",
                status: "",
                planYear: new Date().getFullYear() + "",
                planType: "",
                certificationDate: "",
                attestedDate: Utils.getDateInFormat(new Date()),
                appointmentDate: "",
                source: "",
                edit: true,
                carrierList: this.carrierGroupList,
                productList: productList,
                stateList: stateList,
                planYearList: planYearList,
            };
            this.gridApi.setPinnedTopRowData([rtsData]);
            let rowNode = this.gridApi.getPinnedTopRow(0);
            this.refreshPinnedRow(rowNode);
        }
    };
    @action refreshPinnedRow = (rowNode: any) => {
        const params = {
            force: true,
            rowNodes: [rowNode],
        };
        setTimeout(() => {
            this.gridApi.refreshCells(params);
        }, 0);
    };

    @action findChangeDetection = (rowData: any) => {
        let isValid = false;
        if (
            rowData.carrier != "" &&
            rowData.carrier != "Select Carrier Group" &&
            rowData.producerId != "" &&
            rowData.planType != "" &&
            rowData.planType != "Select Product" &&
            rowData.state != "" &&
            rowData.state != "Select State" &&
            rowData.planYear != "" &&
            rowData.planYear != "Select Plan Year"
        )
            isValid = true;

        let isChangeDetected = false;
        if (
            (rowData.carrier != "" && rowData.carrier != "Select Carrier Group") ||
            rowData.producerId != "" ||
            (rowData.planType != "" && rowData.planType != "Select Product") ||
            (rowData.state != "" && rowData.state != "Select State") ||
            (rowData.planYear != "" && rowData.planYear != "Select Plan Year")
        )
            isChangeDetected = true;

        let action = rowData.action ? true : false;
        let rowNode = this.gridApi.getPinnedTopRow(0);
        rowNode.data.action = !action;
        rowNode.data.isChangeDetected = isChangeDetected;
        rowNode.data.isValid = isValid;
        this.refreshPinnedRow(rowNode);
    };

    @action saveAttestation = async (rowData: any, agentName: string) => {
        try {
            let rtsData = {
                carrierGroup: rowData.carrier,
                npn: rowData.npn,
                awn: this.awn,
                state: rowData.state,
                product: rowData.planType,
                planYear: rowData.planYear,
            } as RtsCertificationModel;
            ContractsStore.addRtsCertification(rtsData);
        } catch (error: any) {
            console.log(error.response.data);
        }
        try {
            if (rowData.isValid) {
                let rowNode = this.gridApi.getPinnedTopRow(0);
                rowNode.data.isValid = false;
                this.refreshPinnedRow(rowNode);
                let data = this.rtsData.filter((i: any) => {
                    return (
                        i.carrier == rowData.carrier &&
                        i.producerId == this.awn &&
                        i.planType == rowData.planType &&
                        i.planYear == rowData.planYear
                    );
                });
                let blobPath = data && data.length > 0 ? data[0].blobPath : "";

                let rtsDataAttestation = {
                    agentRts: {
                        awn: this.awn,
                        bu: Utils.getBUName(),
                        selfAttestationDate: new Date(),
                        isSelfAttested: true,
                        blobPath: blobPath,
                        rtS_STATUS: "",
                        attestationPlatform: "Agency or Business Unit",
                    },
                    selfAttestationRequests: [
                        {
                            carrier: rowData.carrier,
                            planYear: rowData.planYear,
                            states: [rowData.state],
                            planType: rowData.planType,
                            tin: "",
                            agentProducerID: this.awn,
                            snpTypes: [],
                            stateExpirationDate: undefined,
                        },
                    ],
                };
                const urlAttestation = this.rtsUrl + "/attestation/" + this.agentNPN;
                await axios.post(`${urlAttestation}`, rtsDataAttestation, {
                    headers: this.getHeaders(),
                });

                Utils.showSuccessToaster(
                    (agentName.includes("(") ? agentName.split("(")[0] : "") +
                    " can access quoting tools. This request expires in 7 days.",
                    4000
                );
                this.gridApi.setPinnedTopRowData([]);
                this.getData();
            }
        } catch (error: any) {
            Utils.showWarningToaster(error.response.data, 4000);

            setTimeout(() => {
                let rowNode = this.gridApi.getPinnedTopRow(0);
                rowNode.data.isValid = true;
                this.refreshPinnedRow(rowNode);
            }, 2000);
        }
    };

    @action discardChanges = async () => {
        this.gridApi.setPinnedTopRowData([]);
    };

    @action setGridApi = async (api: any) => {
        this.gridApi = api;
    };

    private createAddAttestationLookupModel(rtsData: any) {
        let carrierList = [
            ...["Select Carrier Group"],
            ...new Set(
                rtsData
                    .map((i: any) => {
                        return i.carrier;
                    })
                    .sort()
            ),
        ] as string[];
        this.carrierGroupList = carrierList;
        this.addAttestationLookupModel = [];
        carrierList.forEach((carrier) => {
            let data = rtsData.filter((i: any) => i.carrier == carrier);
            let attestation = {
                carrier: carrier,
                awn: data && data.length > 0 ? data[0].producerId : "",
                productList: [
                    ...["Select Product"],
                    ...new Set(
                        rtsData
                            .filter((i: any) => i.carrier == carrier)
                            .map((i: any) => {
                                return i.planType;
                            })
                            .sort()
                    ),
                ],
                stateList: [
                    ...["Select State"],
                    ...new Set(
                        rtsData
                            .filter((i: any) => i.carrier == carrier)
                            .map((i: any) => {
                                return i.state;
                            })
                            .sort()
                    ),
                ],
                planYearList: [
                    ...["Select Plan Year"],
                    ...new Set(
                        [
                            ...rtsData
                                .filter((i: any) => i.carrier == carrier)
                                .map((i: any) => {
                                    return i.planYear + "";
                                }),
                            ...[
                                new Date().getFullYear() + "",
                                new Date().getFullYear() + 1 + "",
                            ],
                        ].sort()
                    ),
                ],
            };
            this.addAttestationLookupModel.push(attestation);
        });
    }

    @action onFilterChanged = (params: any) => {
        this.paginatorId = "paginator" + Math.random();
    };
}
