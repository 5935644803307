import React from "react";
import { observer } from "mobx-react";
import "primeflex/primeflex.css";
import { UniversalUploadViewModel } from "./UniversalUploadViewModel";   
import Utils from "../../infrastructure/Utils"; 
import "./UniversalUploadView.css";
import UserContext from "../../infrastructure/UserContext";

export const UniversalUploadView: React.FC<{
  vm: UniversalUploadViewModel;
}> = observer((props) => { 
  
  let env = Utils.getAppHostKey(); 
  let documentUploaderUrl =
    `https://documentuploader-${env}.integrityconnect.com/app?tenantName=${Utils.getBUName()}&connectUserId=${UserContext.getUserId()}`
      .replace("-stage", "-uat")
      .replace("-prod", "");
      
  return (
    <> 
        <div className="bob-container">
          <iframe src={`${documentUploaderUrl}`} id="bob-iframe" frameBorder="0" />
        </div>
    </> 
  );
});
