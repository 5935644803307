import { action } from "mobx";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { getToken } from "../infrastructure/Auth0Authenticator";
import {
  CreateUpdateCreditCheckModel,
  CreateUpdatePaymentPlanModel,
  CreditCardChargeModel,
  CreateUpdateDebtModel,
  UpdateCreditCardChargeModel,
  FileType2,
  CreateCarrierBankDepositModel,
  UpdateCarrierBankDepositModel,
  Client as AccountingServiceClient,
  PayoutLevelModel,
  PayoutLevelDetailModel,
  CreateManageAgentDeptRequest,
  AgentDebtRequest,
  OnHoldAgentCommisionsRequest,
  CommissionsReportRequest,
  CommissionsBreakdownReportRequest,
  BankDepositReportRequest,
  CreateUpdatePaymentPlanRequest,
  PaymentPlanLookupRequest,
} from "../services/AccountingService";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import UserContext from "../infrastructure/UserContext";

class AccountStore {
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };
  getClient = (): AccountingServiceClient => {
    return ServiceClient.getClient(ServiceType.Accounting);
  };
  @action getCreditChecks = async (
    agentId: number,
    startIndex: number,
    pageSize: number
  ) => {
    var result = await this.getClient().getAllCreditCheck(
      this.getApiVersion(),
      agentId,
      startIndex,
      pageSize,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action addCreditCheck = async (
    creditCheckModel: CreateUpdateCreditCheckModel
  ) => {
    var result = await this.getClient().createCreditCheck(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      creditCheckModel
    );
    return result;
  };
  @action editCreditCheck = async (
    creditCheckModel: CreateUpdateCreditCheckModel
  ) => {
    var result = await this.getClient().updateCreditCheck(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      creditCheckModel
    );
    return result;
  };
  @action getCreditCheckDetails = async (creditCheckId: number) => {
    var result = await this.getClient().getCreditCheckById(
      creditCheckId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getCarrierBankDeposits = async (
    companyId?: number | null | undefined,
    fromDate?: Date | null | undefined,
    toDate?: Date | null | undefined,
    paidDivisionId?: number | null | undefined,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortAscending?: boolean,
    fullTextFilter?: string
  ) => {
    var result = await this.getClient().getAllBankDeposit(
      this.getApiVersion(),
      companyId,
      fromDate,
      toDate,
      paidDivisionId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );

    return result;
  };
  @action getCarrierBankDepositsReport = async (
    companyId?: number | undefined,
    fromDate?: Date | undefined,
    toDate?: Date | undefined,
    paidDivisionId?: number | undefined
  ) => {
    var request: BankDepositReportRequest = {
      companyId: companyId,
      fromDate: fromDate,
      toDate: toDate,
      paidDivisionId: paidDivisionId,
    };

    var result = await this.getClient().getBankdepositReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );

    return result;
  };
  @action addBankDeposit = async (
    bankDepositModel: CreateCarrierBankDepositModel
  ) => {
    var result = await this.getClient().createBankDeposit(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      bankDepositModel
    );
    return result;
  };
  @action editBankDeposit = async (
    bankDepositModel: UpdateCarrierBankDepositModel
  ) => {
    var result = await this.getClient().updateBankDeposit(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      bankDepositModel
    );
    return result;
  };
  @action getCarrierBankDepositDetail = async (bankDepositId: number) => {
    var result = await this.getClient().getBankDepositId(
      bankDepositId,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };

  @action getBankAccountLookup = async () => {
    var result = await this.getClient().getAllBankAccountsLookup(
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };

  @action deleteCarrierBankDepositDetail = async (bankDepositId: number) => {
    var result = await this.getClient().deleteBankDeposit(
      bankDepositId,
      this.getApiVersion(),
      UserContext.getUserId()
    );

    return result;
  };
  @action deleteCreditCheck = async (creditCheckId: number) => {
    var result = await this.getClient().deleteCreditCheck(
      creditCheckId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getCreditCheckHistory = async (
    agentId: number,
    startIndex: number,
    pageSize: number
  ) => {
    var result = await this.getClient().getCreditCheckHistory(
      this.getApiVersion(),
      agentId,
      startIndex,
      pageSize,
      undefined,
      undefined,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPaymentPlans = async (
    agentId: number,
    status: string | undefined,
    companyId: number | undefined,
    contractId: number | undefined,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    var result = await this.getClient().getAllPaymentPlan(
      agentId,
      this.getApiVersion(),
      status,
      contractId,
      companyId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action getPaymentPlanUsgage = async (
    profileId: number,
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    var result = await this.getClient().getPaymentPlanUsage(
      this.getApiVersion(),
      profileId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPaymentPlanCompanies = async (agentId: number) => {
    var result = await this.getClient().getCompanySearchLookupByAgentId(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPaymentPlanDetail = async (paymentPlanId: number) => {
    var result = await this.getClient().getPaymentPlanById(
      paymentPlanId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getProductDetailByLevelId = async (levelId: number) => {
    var result = await this.getClient().getProductDetailByLevelId(
      levelId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action deletePaymentPlan = async (paymentPlanId: number) => {
    // var authorization = await this.getApiKey();
    var result = await this.getClient().deletePaymentPlan(
      paymentPlanId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action addPaymentPlan = async (dto: CreateUpdatePaymentPlanModel) => {
    var result = await this.getClient().createPaymentPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action editPaymentPlan = async (dto: CreateUpdatePaymentPlanModel) => {
    var result = await this.getClient().updatePaymentPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action getCompaniesFroAddEdit = async (agentId: number) => {
    var result = await this.getClient().getCompanyByAgentIdForAddEditLookup(
      agentId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getProductLookup = async (agentId: number, contractId: number) => {
    var result = await this.getClient().getProductLookupByCompanyIdAndByAgentId(
      agentId,
      contractId,
      this.getApiVersion()
    );
    return result;
  };
  @action getLevelLookup = async (agentId: number, productId: number) => {
    var result = await this.getClient().getLevelLookupByProductIdAndAgentId(
      agentId,
      productId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getLevelLookupByContract = async (
    contractId: number,
    productId: number
  ) => {
    var result = await this.getClient().getLevelLookupByProductIdAndContractId(
      contractId,
      productId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getMarketerLookup = async (levelId: number) => {
    var result = await this.getClient().getMarketerLookupByLevelId(
      levelId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getCommPlan = async (
    commPlanId: number,
    companyId: number,
    levelId: number,
    status: number,
    isShowAll: boolean
  ) => {
    var result = await this.getClient().getCommPlanLookup(
      commPlanId,
      this.getApiVersion(),
      companyId,
      levelId,
      status,
      isShowAll,
      UserContext.getUserId()
    );
    return result;
  };
  @action getPaymentSource = async (id: number) => {
    var result = await this.getClient().getPaymentSourceLookup(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getPayFirstYearTo = async (
    agentId: number,
    levelId: number,
    levelSelectedValue: number,
    firstYearSelectedValue: number,
    maketerSelectedValue: number
  ) => {
    var result = await this.getClient().getPayFirstYearTo(
      levelId,
      agentId,
      this.getApiVersion(),
      levelSelectedValue,
      firstYearSelectedValue,
      maketerSelectedValue,
      UserContext.getUserId()
    );
    return result;
  };

  @action banksLookup = async (routingNumber: string) => {
    var result = await this.getClient().getAllBankLookup(
      this.getApiVersion(),
      routingNumber,
      UserContext.getUserId()
    );
    return result;
  };
  @action loadCreditCardChargeList = async (
    agentId?: number | undefined,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortAscending?: boolean,
    fullTextFilter?: string
  ) => {
    var result = await this.getClient().getAllCreditCard(
      this.getApiVersion(),
      agentId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };
  @action
  getcreditCardChargeDetailDetail = async (ID: number) => {
    var result = await this.getClient().getCreditCardById(
      ID,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action
  editCreditCardCharge = async (dto: UpdateCreditCardChargeModel) => {
    return await this.getClient().updateCreditCard(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
  };
  @action addCreditCardCharge = async (body: CreditCardChargeModel) => {
    var result = await this.getClient().createCreditCard(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
  @action debtListBasedOnAgent = async (
    agentId: number,
    carrierId?: number,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortAscending?: boolean
  ) => {
    var result = await this.getClient().getAllDebt(
      agentId,
      this.getApiVersion(),
      carrierId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };
  @action addAgentDebt = async (body: CreateUpdateDebtModel) => {
    var result = await this.getClient().createDebt(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
  @action deleteAgentDebt = async (id: number) => {
    var result = await this.getClient().deleteDebt(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action editAgentDebt = async (body: CreateUpdateDebtModel) => {
    var result = await this.getClient().updateDebt(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
  @action getDebtById = async (id: number) => {
    var result = await this.getClient().getDebtById(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action debtReport = async (
    agentId?: number | undefined,
    startDate?: Date,
    endDate?: Date,
    companyId?: number | undefined,
    fileType?: FileType2 | undefined
  ) => {
    var request: AgentDebtRequest = {
      agentId: agentId,
      companyId: companyId,
      startDate: startDate,
      endDate: endDate,
      fileType: fileType,
    };
    var result = await this.getClient().getAgentDebtReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action commissionReport = async () => {
    var result = await this.getClient().getCommissionEmailReport(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action receivingPaperStatementReport = async () => {
    var result = await this.getClient().getPaperStatementReport(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action onHoldAgentCommissionsReport = async (
    startDate?: Date,
    endDate?: Date,
    fileType?: FileType2 | undefined
  ) => {
    var request: OnHoldAgentCommisionsRequest = {
      startDate: startDate,
      stopDate: endDate,
      fileType: fileType,
    };
    var result = await this.getClient().getOnHoldAgentCommisionsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getCommissionsReport = async (
    startDate?: Date,
    endDate?: Date,
    companyId?: number | undefined,
    prodSubCategoriesId?: number[] | undefined,
    prodbySubCategoriesId?: number[] | undefined,
    insuredId?: number | undefined,
    writingNumber?: number | undefined,
    payToAgent?: number | undefined,
    whichDate?: string | undefined,
    fileType?: FileType2 | undefined
  ) => {
    if (prodSubCategoriesId && prodSubCategoriesId.length > 0) {
      prodSubCategoriesId = prodSubCategoriesId.map((i) => Number(i));
    }
    if (prodbySubCategoriesId && prodbySubCategoriesId.length > 0) {
      prodbySubCategoriesId = prodbySubCategoriesId.map((i) => Number(i));
    }
    var request: CommissionsReportRequest = {
      startDate: startDate,
      stopDate: endDate,
      companyId: companyId,
      prodSubCategoriesId: prodSubCategoriesId ? prodSubCategoriesId : [],
      prodbySubCategoriesId: prodbySubCategoriesId ? prodbySubCategoriesId : [],
      insuredId: insuredId,
      writingNumber: writingNumber,
      payToAgent: payToAgent,
      whichDate: whichDate,
      fileType: fileType,
    };
    var result = await this.getClient().getCommissionsReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action getCommissionsBreakdownReport = async (
    startDate?: Date,
    endDate?: Date,
    fileType?: FileType2 | undefined
  ) => {
    var request: CommissionsBreakdownReportRequest = {
      payFromDate: startDate,
      payToDate: endDate,
      fileType: fileType,
    };
    var result = await this.getClient().getCommissionsBreakupReport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action downloadPaymentPlan = async (profileId: number) => {
    var result = await this.getClient().getPaymentPlanReport(
      this.getApiVersion(),
      profileId,
      1,
      UserContext.getUserId()
    );
    return result;
  };

  @action getCarrierBankDepositList = async (
    companyId?: number | null | undefined,
    fromDate?: Date | null | undefined,
    toDate?: Date | null | undefined,
    paidDivisionId?: number | null | undefined,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortAscending?: boolean,
    fullTextFilter?: string
  ) => {
    var result = await this.getClient().getAllBankDeposit(
      this.getApiVersion(),
      companyId,
      fromDate,
      toDate,
      paidDivisionId,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      fullTextFilter,
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteCarrierBankDeposit = async (id: number) => {
    var result = await this.getClient().deleteBankDeposit(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action agentDebtImportFor = async () => {
    var result = await this.getClient().agentDebtImportlDropDownValues(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action agentDebtImportUpload = async (
    reportDate?: Date | undefined,
    agentDebtImportId?: number | undefined,
    file?: any | null | undefined
  ) => {
    var result = await this.getClient().agentDebtImport(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      reportDate,
      agentDebtImportId,
      file
    );
    return result;
  };

  @action getAllPayoutLevels = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean,
    companyId: number,
    typeId: any,
    statusId: boolean,
    fullTextFilter: any
  ) => {
    var result = await this.getClient().getAllPayoutLevels(
      this.getApiVersion(),
      companyId,
      statusId,
      typeId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      fullTextFilter,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action payoutLevelDropDownValues = async () => {
    var result = await this.getClient().payoutLevelDropDownValues(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllPayoutLevelDetails = async (
    payoutLevelId: number,
    pageIndex: number,
    sortColumn: string,
    sortOrder: boolean,
    pageSize: number
  ) => {
    var result = await this.getClient().getAllPayoutLevelDetails(
      this.getApiVersion(),
      payoutLevelId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPayoutLevelById = async (Id: number, companyId: number) => {
    var result = await this.getClient().getPayoutLevelById(
      Id,
      this.getApiVersion(),
      companyId,
      UserContext.getUserId()
    );
    return result;
  };

  @action addPayoutLevel = async (dto: PayoutLevelModel) => {
    var result = await this.getClient().addPayoutLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updatePayoutLevel = async (dto: PayoutLevelModel) => {
    var result = await this.getClient().updatePayoutLevel(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action addPayoutLevelDetail = async (dto: PayoutLevelDetailModel) => {
    var result = await this.getClient().addPayoutLevelDetail(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action updatePayoutLevelDetail = async (dto: PayoutLevelDetailModel) => {
    var result = await this.getClient().updatePayoutLevelDetail(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action deletePayoutLevel = async (id: number) => {
    var result = await this.getClient().deletePayoutLevel(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getPayoutLevelDetailById = async (
    id: number,
    payoutlevelId: number,
    companyId: number
  ) => {
    var result = await this.getClient().getPayoutLevelDetailById(
      id,
      this.getApiVersion(),
      payoutlevelId,
      companyId,
      UserContext.getUserId()
    );
    return result;
  };

  @action journalReportDropDownValues = async () => {
    let result = await this.getClient().journalReportDropDownValues(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action dropDownValuesChanged = async (
    divId: number | undefined,
    year: number | undefined,
    agentValueTypeId: number | undefined,
    date: Date | undefined,
    startDate?: Date | undefined,
    endDate?: Date | undefined
  ) => {
    let result = await this.getClient().dropDownValuesChanged(
      this.getApiVersion(),
      divId,
      year,
      agentValueTypeId,
      date,
      startDate,
      endDate,
      UserContext.getUserId()
    );
    return result;
  };
  @action downloadAllJournalReport = async (
    divId: number | undefined,
    year: number | undefined,
    agentValueTypeId: number | undefined,
    date: Date | undefined,
    agentTypeText?: string | undefined,
    startDate?: Date | undefined,
    endDate?: Date | undefined,
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    sortColumn?: string | undefined,
    sortAscending?: boolean | undefined
  ) => {
    let result = await this.getClient().downloadAllJournalReport(
      this.getApiVersion(),
      divId,
      year,
      agentValueTypeId,
      date,
      agentTypeText,
      startDate,
      endDate,
      pageIndex,
      pageSize,
      sortColumn,
      sortAscending,
      undefined,
      UserContext.getUserId()
    );
    return result;
  };

  @action deleteProductPayout = async (id: number) => {
    var result = await this.getClient().deletePayoutLevelDetail(
      id,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getAllAgentDeptImports = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) => {
    var result = await this.getClient().getAllAgentDeptImports(
      this.getApiVersion(),
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      undefined,
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAgentDeptImportById = async (id: number) => {
    var result = await this.getClient().getAgentDeptImportsById(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };
  @action addAgentDeptImport = async (dto: CreateManageAgentDeptRequest) => {
    var result = await this.getClient().createAgentDeptImports(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };
  @action updateAgentDeptImport = async (dto: CreateManageAgentDeptRequest) => {
    var result = await this.getClient().updateAgentDeptImports(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      dto
    );
    return result;
  };

  @action deleteAgentDeptImport = async (id: number) => {
    var result = await this.getClient().deleteAgentDeptImport(
      id,
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getCreditCheckVendor = async () => {
    var result = await this.getClient().getCreditCheckVendor(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined
    );
    return result;
  };

  @action getAllPayoutLevelsByCarrierId = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean,
    companyId: number,
    statusId: boolean | undefined,
    level: string
  ) => {
    var result = await this.getClient().getAllPayoutLevelsByCarrierId(
      this.getApiVersion(),
      companyId,
      statusId,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      level,
      UserContext.getUserId()
    );
    return result;
  };

  @action getSpecialInstructionsByLevelId = async (levelId: number) => {
    var result = await this.getClient().getSpecialInstructionsByLevelId(
      levelId,
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };

  @action getValidatePayoutLevelByCarrierId = async (
    levelName: string,
    carrierId: number,
    id: number
  ) => {
    var result = await this.getClient().getValidatePayoutLevelByCarrierId(
      this.getApiVersion(),
      levelName,
      carrierId,
      id,
      UserContext.getUserId()
    );
    return result;
  };

  @action getPaymentPlanLookupDetails = async (
    agentId: number,
    contractId: number,
    companyId: number,
    ProductLevelIds?: number[],
    marketerId?: number,
    levelId?: number,
    profileCommPlanId?: number
  ) => {
    var request: PaymentPlanLookupRequest = {
      agentId: agentId,
      contractId: contractId,
      companyId: companyId,
      productLevelIds: ProductLevelIds,
      marketerId: marketerId,
      levelId: levelId,
      commPlanId: profileCommPlanId,
    };
    var result = await this.getClient().getPaymentPlanLookupdetails(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action createUpdatePaymentPlan = async (
    request: CreateUpdatePaymentPlanRequest
  ) => {
    var result = await this.getClient().addPaymentPlan(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };
}

export default new AccountStore();
